import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function DataGridHeader(props) {
  const { columnId, heading } = props;

  return (
    <Typography
      id={`DataGrid-header-${columnId}`}
      variant="body2"
      sx={{
        fontWeight: 'bold',
      }}
    >
      {heading}
    </Typography>
  );
}

DataGridHeader.propTypes = {
  columnId: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};

export default DataGridHeader;
