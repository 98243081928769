import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { convertToDateMMddyyyy } from 'utilities/format';

export default function FinancialDetailFormDialog(props) {
  const { financial, parentId } = props;

  const handleClose = () => {
    props.setOpen();
  };
  return (
    <div>
      <Dialog fullWidth open={props.open} onClose={handleClose} id={`${parentId}-dialog`}>
        <DialogTitle>Transaction Details</DialogTitle>
        <div>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  disabled
                  fullWidth
                  label="First Name"
                  size="small"
                  variant="outlined"
                  id={`${parentId}-firstName`}
                  value={financial.firstName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  disabled
                  fullWidth
                  label="Last Name"
                  size="small"
                  variant="outlined"
                  id={`${parentId}-lastName`}
                  value={financial.lastName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  disabled
                  fullWidth
                  label="Financial FJ #"
                  size="small"
                  variant="outlined"
                  id={`${parentId}-fjNo`}
                  value={financial.fjNo}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  disabled
                  fullWidth
                  label="Financial FJ Date"
                  size="small"
                  variant="outlined"
                  id={`${parentId}-finDate`}
                  value={financial.finDate ? convertToDateMMddyyyy(financial.finDate) : '-'}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  disabled
                  fullWidth
                  label="Financial Transaction Type"
                  size="small"
                  variant="outlined"
                  id={`${parentId}-finTransTypeDesc`}
                  value={financial.finTransTypeDesc}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  disabled
                  fullWidth
                  label="Message"
                  size="small"
                  variant="outlined"
                  id={`${parentId}-message`}
                  value={financial.message ?? ''}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  disabled
                  fullWidth
                  label="Amount"
                  size="small"
                  variant="outlined"
                  id={`${parentId}-finAmount`}
                  value={`$${financial.finAmount}`}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  disabled
                  fullWidth
                  label="Account Balance After Posting"
                  size="small"
                  variant="outlined"
                  id={`${parentId}-balanceAfterPosting`}
                  value={`$${financial.accountBalance}`}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

FinancialDetailFormDialog.propTypes = {
  financial: PropTypes.shape({
    fjNo: PropTypes.number.isRequired,
    finDate: PropTypes.string.isRequired,
    description: PropTypes.string,
    accountId: PropTypes.number.isRequired,
    accountNumber: PropTypes.string,
    finAmount: PropTypes.number.isRequired,
    accountStatus: PropTypes.number,
    accountEffect: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    pnRef: PropTypes.string,
    message: PropTypes.string,
    finTransTypeDesc: PropTypes.string,
    accountBalance: PropTypes.number,
  }).isRequired,
  parentId: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
