import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import CustomTextDataGridCell from 'components/Custom/CustomTextDataGridCell';

const DataGridCell = ({ id, width, children, center, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} center={center} rowHeight={rowHeight}>
    {id.includes('accountBalance') ? `$${children}` : children}
  </CustomTextDataGridCell>
);

// eslint-disable-next-line react/prop-types
const ActionCell = ({ id, width, account, rowHeight }) => (
  <DataGridCell id={id} width={width} center rowHeight={rowHeight}>
    <Link
      to={{
        // eslint-disable-next-line react/prop-types
        pathname: `/account-management/${account.accountId}`,
      }}
      style={{ textDecoration: 'none', color: 'black' }}
    >
      <IconButton
        color="inherit"
        aria-label="person"
        size="small"
        id="AccountSearch-btn-openAccountMgmt"
      >
        <PersonIcon />
      </IconButton>
    </Link>
  </DataGridCell>
);

const AccountStatusDescsCell = ({ id, width, children, rowHeight, accountStatusDescs }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? accountStatusDescs[children.toString()] : '-'}
  </CustomTextDataGridCell>
);

const CELL_TYPE = {
  accountStatusId: AccountStatusDescsCell,
  accountMgmtLink: ActionCell,
};

export default function AccountSearchCard(props) {
  const { account, index } = props;

  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, [props.renderedColumns]);

  return (
    <TableRow
      id={`AccountSearch-tableRow-searchResult-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <Link
            to={{
              // eslint-disable-next-line react/prop-types
              pathname: `/account-management/${account.accountId}`,
            }}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <CellComponent
              id={`AccountSearch-tableCell-searchResult-${index}-${col.field}`}
              key={col.field}
              width={widthMap[col.field]}
              account={account}
              index={index}
              rowHeight={props.rowHeight}
              accountStatusDescs={props.accountStatusDescs}
            >
              {account[col.field]}
            </CellComponent>
          </Link>
        );
      })}
    </TableRow>
  );
}

AccountSearchCard.propTypes = {
  account: PropTypes.shape({
    accountId: PropTypes.number.isRequired,
    accountNumber: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    plateNumber: PropTypes.string.isRequired,
    vin: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    accountBalance: PropTypes.number.isRequired,
    country: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    accountStatusId: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  accountStatusDescs: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  renderedColumns: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

DataGridCell.defaultProps = {
  center: false,
};

AccountStatusDescsCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  accountStatusDescs: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

ActionCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
};
