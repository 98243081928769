import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import { convertToDateMMddyyyy } from 'utilities/format';
import { useRecoilValue } from 'recoil';
import { accountStatusDescsAtom } from 'utilities/State';
import FinancialDetailFormDialog from 'components/Account/FinancialProcessing/FinancialDetailFormDialog';
import CustomTextDataGridCell from 'components/Custom/CustomTextDataGridCell';

const DataGridCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {id.includes('finAmount') ? `$${children?.toFixed(2)}` : children}
  </CustomTextDataGridCell>
);

const DateCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? convertToDateMMddyyyy(children) : '-'}
  </CustomTextDataGridCell>
);

// eslint-disable-next-line react/prop-types
const AccountStatusCell = ({ id, width, children, rowHeight, open, setOpen, financial }) => (
  <DataGridCell id={id} width={width} rowHeight={rowHeight}>
    {useRecoilValue(accountStatusDescsAtom)[children ? children.toString() : '-'] ?? children}
    <FinancialDetailFormDialog
      financial={financial}
      parentId="FinancialDetailsForm-financials"
      open={open}
      setOpen={setOpen}
    />
  </DataGridCell>
);

const CELL_TYPE = {
  finDate: DateCell,
  accountStatus: AccountStatusCell,
};

export default function FinancialSearchCard(props) {
  const [open, setOpen] = useState(false);

  const { financial, index } = props;
  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, [props.renderedColumns]);

  const handleDoubleClick = () => {
    setOpen(!open);
  };
  return (
    <TableRow
      onDoubleClick={handleDoubleClick}
      id={`FinancialSearch-tableRow-financial-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`FinancialSearch-tableCell-financial-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            financial={financial}
            index={index}
            rowHeight={props.rowHeight}
            open={open}
            setOpen={setOpen}
          >
            {financial[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

FinancialSearchCard.propTypes = {
  financial: PropTypes.shape({
    fjNo: PropTypes.number.isRequired,
    finDate: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    accountId: PropTypes.number.isRequired,
    accountNumber: PropTypes.string.isRequired,
    finAmount: PropTypes.number.isRequired,
    accountStatus: PropTypes.number.isRequired,
    accountEffect: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    pnRef: PropTypes.string.isRequired,
    message: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  renderedColumns: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

AccountStatusCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
