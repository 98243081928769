export function convertToDate(datetime) {
  return datetime.split('T')[0];
}

export function convertToDateMMddyyyy(datetime, convertFromUTC = true) {
  if (datetime == null) return '-';
  let inputDate = new Date();
  if (convertFromUTC && !datetime.endsWith('Z')) {
    inputDate = new Date(`${datetime}Z`);
  } else {
    inputDate = new Date(datetime);
  }
  return new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'America/New_York',
  })
    .format(inputDate)
    .replaceAll('/', '-');
}

export function convertToDateTime(datetime, convertFromUTC = true) {
  if (datetime == null) return '-';
  let inputDate = new Date();
  if (convertFromUTC && !datetime.endsWith('Z')) {
    inputDate = new Date(`${datetime}Z`);
  } else {
    inputDate = new Date(datetime);
  }
  return new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h12',
    timeZone: 'America/New_York',
  })
    .format(inputDate)
    .replaceAll('/', '-')
    .replaceAll(',', ' ');
}
