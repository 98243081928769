import { useState } from 'react';
import PropTypes from 'prop-types';
import HomeIcon from '@mui/icons-material/Home';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function UpdateAddressDialogForm(props) {
  const [state, setState] = useState({
    addressId: props.address.addressId,
    addressType: props.address.addressType,
    addressLine1: props.address.addressLine1,
    addressLine2: props.address.addressLine2,
    postalCode: props.address.postalCode,
    city: props.address.city,
    stateProvinceCode: props.address.stateProvinceCode,
    countryCode: props.address.countryCode,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedAddress = {
      ...state,
      addressLine1: state.addressLine1.trim(),
      addressLine2: state.addressLine2.trim(),
      postalCode: state.postalCode.trim(),
      city: state.city.trim(),
      addressType: parseInt(e.target.addressType.value, 10),
    };
    props.updateAddress(updatedAddress);
    props.handleClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const addressTypesMenuItems = Object.entries(props?.addressTypes).map((entry) => {
    return (
      <MenuItem
        key={entry[0]}
        value={entry[0]}
        id={`UpdateAddress-select-addressTypeValue-${entry[1]}`}
      >
        {entry[1]}
      </MenuItem>
    );
  });

  const statesMenuItems = props?.provinces.map((province) => {
    return (
      <MenuItem
        key={province.stateProvinceCode}
        value={province.stateProvinceCode}
        id={`UpdateAddress-select-stateValue-${province.stateName}`}
      >
        {province.stateName}
      </MenuItem>
    );
  });

  const countries = [
    { countryCode: 'US', countryName: 'United States' },
    { countryCode: 'CA', countryName: 'Canada' },
  ];

  const countriesMenuItems = countries.map((country) => {
    return (
      <MenuItem
        key={country.countryCode}
        value={country.countryCode}
        id={`UpdateAddress-select-countryCodeValue-${country.countryCode}`}
      >
        {country.countryName}
      </MenuItem>
    );
  });

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                select
                required
                fullWidth
                size="small"
                label="Address Type"
                id="UpdateAddress-select-addressType"
                value={state.addressType}
                name="addressType"
                onChange={handleChange}
              >
                {addressTypesMenuItems}
              </TextField>
            </Grid>
            <Box width="100%" />
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="Address Line 1"
                id="UpdateAddress-textField-addressLine1"
                name="addressLine1"
                value={state.addressLine1}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HomeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="Address Line 2"
                id="UpdateAddress-textField-addressLine2"
                name="addressLine2"
                value={state.addressLine2}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HomeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="Zip/Postal Code"
                id="UpdateAddress-textField-postalCode"
                name="postalCode"
                value={state.postalCode}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HomeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="City"
                id="UpdateAddress-textField-city"
                name="city"
                value={state.city}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCityIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                required
                fullWidth
                size="small"
                label="State"
                id="UpdateAddress-select-state"
                value={state.stateProvinceCode.trim()}
                name="stateProvinceCode"
                onChange={handleChange}
              >
                {statesMenuItems}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                required
                fullWidth
                size="small"
                label="Country"
                id="UpdateAddress-select-countryCode"
                value={state.countryCode}
                name="countryCode"
                onChange={handleChange}
              >
                {countriesMenuItems}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="UpdateAddress-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            id="UpdateAddress-btn-updateAddress"
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

UpdateAddressDialogForm.propTypes = {
  address: PropTypes.shape({
    addressId: PropTypes.number.isRequired,
    addressType: PropTypes.number.isRequired,
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    stateProvinceCode: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
  }).isRequired,
  updateAddress: PropTypes.func.isRequired,
  addressTypes: PropTypes.objectOf(PropTypes.string).isRequired,
  provinces: PropTypes.arrayOf(
    PropTypes.shape({
      stateProvinceCode: PropTypes.string.isRequired,
      stateName: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
};
