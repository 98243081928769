import DataGridHeader from 'components/Custom/DataGridHeader';

const COLUMNS = [
  {
    field: 'tagSerialNumber',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="tagSerialNumber" heading="Tag Serial Number" />,
    sortable: false,
  },
  {
    field: 'preTagStatusId',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="preTagStatusId" heading="Prev. Tag Status" />,
    sortable: false,
  },
  {
    field: 'preTagStatusDate',
    flex: 0.5,
    renderHeader: () => (
      <DataGridHeader columnId="preTagStatusDate" heading="Prev. Tag Status Date" />
    ),
    sortable: false,
  },
  {
    field: 'newTagStatusId',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="newTagStatusId" heading="New Tag Status" />,
    sortable: false,
  },
  {
    field: 'newTagStatusDate',
    flex: 0.5,
    renderHeader: () => (
      <DataGridHeader columnId="newTagStatusDate" heading="New Tag Status Date" />
    ),
    sortable: false,
  },
];

export default COLUMNS;
