import { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import { useRecoilValue } from 'recoil';
import { accountSearchRight } from 'utilities/userRole';
import DateRangePicker from './DateRangePicker';

export default function SearchBar(props) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [state, setState] = useState({
    searchType: '',
    searchValue: '',
    helperText: '',
    textFieldErrorState: false,
  });
  const hasAccountSearchRight = useRecoilValue(accountSearchRight);

  const numbersOnlyRegex = /^\d+$/;
  const numbersAndDotRegex = /^\d+(\.\d+)*$/;

  const validate = (e) => {
    e.preventDefault();

    const { searchType, searchValue } = state;

    if (searchType === '') {
      return;
    }

    if (searchType === 'AccountNumber' && searchValue.length !== 10) {
      setState({
        ...state,
        helperText: 'Account Number should be 10 digits',
        textFieldErrorState: true,
      });
      return;
    }

    if (!hasAccountSearchRight) {
      setState({
        ...state,
        helperText: "User doesn't have access to search account",
        textFieldErrorState: true,
      });
      return;
    }

    if (searchType === 'AccountNumber' && numbersOnlyRegex.test(searchValue) !== true) {
      setState({
        ...state,
        helperText: 'Account Number should only contain numbers',
        textFieldErrorState: true,
      });
      return;
    }

    if (searchType === 'Amount' && numbersAndDotRegex.test(searchValue) !== true) {
      setState({
        ...state,
        helperText: 'Amount should only contain numbers',
        textFieldErrorState: true,
      });
      return;
    }

    if (searchType === 'FjNo' && numbersOnlyRegex.test(searchValue) !== true) {
      setState({
        ...state,
        helperText: 'FjNo should only contain numbers',
        textFieldErrorState: true,
      });
      return;
    }

    if (searchType.includes('Date')) {
      if (startDate > endDate) {
        setState({
          ...state,
          helperText: 'Start date must be before end date',
          textFieldErrorState: true,
        });
        return;
      }

      try {
        const startDateStr = format(startDate, 'yyyy-MM-dd');
        const endDateStr = format(endDate, 'yyyy-MM-dd');
        props.handleSearchSubmit(searchType, '', startDateStr, endDateStr);
        return;
      } catch {
        setState({
          ...state,
          helperText: 'Start or end date are not valid',
          textFieldErrorState: true,
        });
      }
    } else if (props.customValidator !== null) {
      const [valid, text] = props.customValidator(searchType, searchValue);
      if (!valid) {
        setState({
          ...state,
          helperText: text,
          textFieldErrorState: true,
        });
        return;
      }
    }
    props.handleSearchSubmit(searchType, searchValue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
      helperText: '',
      textFieldErrorState: false,
    });
  };

  const handleDateChange = (type, newValue) => {
    switch (type) {
      case 'start':
        setStartDate(newValue);
        setState({
          ...state,
          helperText: '',
          textFieldErrorState: false,
        });
        break;
      case 'end':
        setEndDate(newValue);
        setState({
          ...state,
          helperText: '',
          textFieldErrorState: false,
        });
        break;
      default:
        break;
    }
  };

  const clearSearch = () => {
    setStartDate(null);
    setEndDate(null);
    setState({
      searchType: '',
      searchValue: '',
      helperText: '',
      textFieldErrorState: false,
    });
    props.clearSearch();
  };

  const renderSearchTypeSelect = () => {
    const selects = props.searchTypes.map((value) => {
      const valueStripped = value.replaceAll(' ', '');
      return (
        <MenuItem
          key={value}
          value={valueStripped}
          id={`${props.parentId}Search-select-searchTypeValue-${valueStripped}`}
        >
          {value}
        </MenuItem>
      );
    });
    return selects;
  };

  return (
    <div>
      <form onSubmit={validate}>
        <Grid container spacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} sx={{ mb: 1 }}>
          <Grid item>
            <TextField
              select
              required
              name="searchType"
              id={`${props.parentId}Search-select-searchType`}
              value={state.searchType}
              label="Select"
              size="small"
              sx={{ minWidth: 180 }}
              onChange={handleChange}
            >
              {renderSearchTypeSelect()}
            </TextField>
          </Grid>
          <Grid item>
            {state.searchType.includes('Date') ? (
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                handleDateChange={handleDateChange}
                helperText={state.helperText}
                textFieldErrorState={state.textFieldErrorState}
                location={`${props.parentId}Search`}
              />
            ) : (
              <TextField
                required
                name="searchValue"
                id={`${props.parentId}Search-textField-inputSearchValue`}
                value={state.searchValue}
                label="Search"
                type="search"
                size="small"
                fullWidth
                sx={{ minWidth: 240 }}
                onChange={handleChange}
                helperText={state.helperText}
                error={state.textFieldErrorState}
              />
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              id={`${props.parentId}Search-btn-submitSearch`}
              size="medium"
              type="submit"
            >
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              id={`${props.parentId}Search-btn-clearSearch`}
              size="medium"
              onClick={clearSearch}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

SearchBar.propTypes = {
  parentId: PropTypes.string.isRequired,
  handleSearchSubmit: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  searchTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  customValidator: PropTypes.func,
};

SearchBar.defaultProps = {
  customValidator: null,
};
