import { useCallback, useEffect, useState, useMemo } from 'react';

const EMPTY_REMOTE_DATA = { loading: true, pageToData: {}, totalCount: -1 };

const EMPTY_ARRAY = [];

function padArray(pageNumber, pageSize) {
  return [...Array(pageNumber * pageSize)].map((a, i) => ({ dummyId: i }));
}

const useTableState = (initialState, queryFn) => {
  const [remoteData, setRemoteData] = useState(EMPTY_REMOTE_DATA);
  const [state, setState] = useState({
    filter: initialState.filter,
    sort: initialState.sort,
    pageNumber: initialState.pageNumber ?? 0,
    pageSize: initialState.pageSize ?? 25,
    refreshCount: 0,
  });

  const handleSortChange = useCallback((nextSort) => {
    setState((prev) => ({ ...prev, sort: nextSort, pageNumber: 0 }));
    setRemoteData(EMPTY_REMOTE_DATA);
  }, []);
  const handleFilterChange = useCallback((nextFilter) => {
    setState((prev) => ({ ...prev, filter: nextFilter, pageNumber: 0 }));
    setRemoteData(EMPTY_REMOTE_DATA);
  }, []);
  const handlePageChange = useCallback((nextPageNumber) => {
    setState((prev) => ({ ...prev, pageNumber: nextPageNumber }));
  }, []);
  const handlePageSizeChange = useCallback((nextPageSize) => {
    setState((prev) => ({ ...prev, pageSize: nextPageSize, pageNumber: 0 }));
    setRemoteData(EMPTY_REMOTE_DATA);
  }, []);
  const handleRefresh = useCallback(() => {
    setState((prev) => ({ ...prev, refreshCount: prev.refreshCount + 1 }));
  }, []);

  useEffect(() => {
    setRemoteData((prev) => ({ ...prev, loading: true }));
    queryFn(state)
      .then(({ pageNumber, data, totalCount }) => {
        setRemoteData((prev) => ({
          loading: false,
          pageToData: {
            ...prev.pageToData,
            [pageNumber]: data,
          },
          totalCount,
        }));
      })
      .catch(() => {
        setRemoteData((prev) => ({ ...prev, loading: false }));
      });
  }, [state, queryFn]);

  const currentPageData = remoteData.pageToData[state.pageNumber];

  return {
    ...state,
    data: useMemo(
      () => [...padArray(state.pageNumber, state.pageSize), ...(currentPageData ?? EMPTY_ARRAY)],
      [currentPageData, state.pageNumber]
    ),
    totalCount: remoteData.totalCount,
    loading: remoteData.loading,
    onSortChange: handleSortChange,
    onFilterChange: handleFilterChange,
    onPageChange: handlePageChange,
    onPageSizeChange: handlePageSizeChange,
    onRefresh: handleRefresh,
  };
};

export default useTableState;
