import DataGridHeader from 'components/Custom/DataGridHeader';

const COLUMNS = [
  {
    field: 'accountNumber',
    flex: 1.2,
    renderHeader: () => <DataGridHeader columnId="accountNumber" heading="Account #" />,
  },
  {
    field: 'firstName',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="firstName" heading="First Name" />,
    sortable: false,
  },
  {
    field: 'lastName',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="lastName" heading="Last Name" />,
    sortable: false,
  },
  {
    field: 'city',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="city" heading="City" />,
    sortable: false,
  },
  {
    field: 'state',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="state" heading="State" />,
    sortable: false,
  },
  {
    field: 'plateNumber',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="plateNumber" heading="Plate #" />,
    sortable: false,
  },
  {
    field: 'vin',
    flex: 2,
    renderHeader: () => <DataGridHeader columnId="vin" heading="VIN" />,
    sortable: false,
  },
  {
    field: 'email',
    flex: 2,
    renderHeader: () => <DataGridHeader columnId="email" heading="Email" />,
    sortable: false,
  },
  {
    field: 'accountBalance',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="accountBalance" heading="Balance" />,
    sortable: false,
  },
  {
    field: 'addressLine1',
    flex: 1.5,
    renderHeader: () => <DataGridHeader columnId="addressLine1" heading="Address 1" />,
    sortable: false,
  },
  {
    field: 'addressLine2',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="addressLine2" heading="Address 2" />,
    sortable: false,
  },
  {
    field: 'postalCode',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="postalCode" heading="Zip" />,
    sortable: false,
  },
  {
    field: 'accountStatusId',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="accountStatusId" heading="Acct Status" />,
    sortable: false,
  },
  {
    field: 'accountMgmtLink',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="accountMgmtLink" heading="Action" />,
    sortable: false,
  },
];

export default COLUMNS;
