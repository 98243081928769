import { useState, useEffect } from 'react';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import axios from 'utilities/authAxios';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { refreshAccountInfo } from 'utilities/State';
import { useRecoilState } from 'recoil';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export default function PaymentTransferDialogForm(props) {
  const { payment, setIsPaymentTransferDialogOpen } = props;
  const [refresh, toggleRefresh] = useRecoilState(refreshAccountInfo);
  const [reasonCodes, setReasonCodes] = useState([]);
  const [targetAccount, setTargetAccount] = useState({
    accountId: null,
    accountNumber: '',
    fullName: '',
    accountBalance: '',
  });
  const [state, setState] = useState({
    reasonCodeId: null,
    targetAccountNumber: '',
    comments: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  useEffect(() => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Financial/GetReasonCodes`)
      .then((res) => {
        setReasonCodes(res.data);
        setIsPaymentTransferDialogOpen(true);
        props.setLoading(false);
      })
      .catch(() => {
        props.setLoading(false);
        props.presentAlert('error', 'Server could not be reached. Please refresh the page.');
      });
  }, []);

  const findTargetAccount = () => {
    if (state.targetAccountNumber.length !== 10) {
      props.presentAlert('error', 'Account number should be 10 digits');
    } else {
      props.setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Financial/GetAccountInfo/${state.targetAccountNumber}`
        )
        .then((res) => {
          props.setLoading(false);
          setTargetAccount({
            accountId: res.data.accountId,
            accountNumber: res.data.accountNumber,
            fullName: `${res.data.firstName} ${res.data.lastName}`,
            accountBalance: res.data.accountBalance,
          });
        })
        .catch(() => {
          props.setLoading(false);
          props.presentAlert('error', 'Could not find account');
        });
    }
  };

  const transferPayment = (e) => {
    e.preventDefault();
    props.setLoading(true);
    const paymentObj = {
      fromAccountId: payment.accountId,
      toAccountId: targetAccount.accountId,
      comments: state.comments,
      amount: payment.finAmount,
      fjNo: payment.fjNo,
      csrId: 1,
      reasonCodeId: state.reasonCodeId,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/Financial/ProcessPaymentTransfer`, paymentObj)
      .then((res) => {
        if (res.data > 0) {
          props.setLoading(false);
          toggleRefresh(!refresh);
          props.handleClose();
          props.presentParentAlert('success', 'Payment transfer was processed successfully');
        } else {
          // present alert in dialog
          props.setLoading(false);
          props.presentAlert('error', 'Payment transfer was not processed');
        }
      })
      .catch(() => {
        props.setLoading(false);
        props.presentParentAlert('error', 'Payment transfer was not processed');
      });
  };
  const transferTypesMenuItems = reasonCodes
    ?.filter((reasonTransType) => reasonTransType.finTransTypeId === 8)
    .map((reasonCode) => {
      return (
        <MenuItem
          key={reasonCode.reasonCodeId}
          value={reasonCode.reasonCodeId}
          id={`PaymentTransfer-select-reasonCodeValue-${reasonCode.reasonCodeDesc.replaceAll(
            ' ',
            ''
          )}`}
        >
          {reasonCode.reasonCodeDesc}
        </MenuItem>
      );
    });
  return (
    <div>
      <form onSubmit={transferPayment} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="div">Source Account</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                disabled
                fullWidth
                label="From Account Number"
                size="small"
                id="PaymentTransfer-textField-fromAccountNumber"
                value={payment.accountNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                disabled
                fullWidth
                label="From Account Name"
                size="small"
                id="PaymentTransfer-textField-fromAccountName"
                value={`${payment.firstName} ${payment.lastName}`}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                disabled
                fullWidth
                label="Transfer Amount"
                size="small"
                variant="outlined"
                value={`$${payment.finAmount}`}
                id="PaymentTransfer-textField-transferAmount"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography component="div">Target Account</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Account Number"
                size="small"
                id="PaymentTransfer-textField-input-targetAccountNumber"
                name="targetAccountNumber"
                onChange={handleChange}
                value={state.targetAccountNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                id="PaymentTransfer-btn-findTargetAccount"
                onClick={findTargetAccount}
              >
                Find Account
              </Button>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                label="Account Name"
                size="small"
                id="PaymentTransfer-textField-targetAccountName"
                value={targetAccount.fullName}
              />
            </Grid>
            <Box width="100%" />
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                label="Account Number"
                size="small"
                id="PaymentTransfer-textField-readOnly-targetAccountNumber"
                value={targetAccount.accountNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                label="Account Balance"
                size="small"
                id="PaymentTransfer-textField-targetAccountBalance"
                value={`$${targetAccount.accountBalance}`}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component="div">Transfer Details</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                required
                fullWidth
                label="Transfer Reason"
                size="small"
                id="PaymentTransfer-selec-reasonCodes"
                name="reasonCodeId"
                onChange={handleChange}
                value={state.reasonCodeId}
              >
                {transferTypesMenuItems}
              </TextField>
            </Grid>
            <Box width="100%" />
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                multiline
                label="Payment transfer description"
                variant="outlined"
                minRows={5}
                placeholder="Enter note description..."
                id="PaymentTransfer-textField-comment"
                name="comments"
                value={state.comments}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="PaymentTransfer-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={targetAccount.accountNumber === ''}
            variant="contained"
            size="small"
            id="PaymentTransfer-btn-submit"
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

PaymentTransferDialogForm.propTypes = {
  payment: PropTypes.shape({
    accountId: PropTypes.number.isRequired,
    accountNumber: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    finAmount: PropTypes.number.isRequired,
    fjNo: PropTypes.number.isRequired,
  }).isRequired,
  setIsPaymentTransferDialogOpen: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
  handleClose: PropTypes.func,
  presentAlert: PropTypes.func,
  presentParentAlert: PropTypes.func.isRequired,
};

PaymentTransferDialogForm.defaultProps = {
  setLoading: null,
  handleClose: null,
  presentAlert: null,
};
