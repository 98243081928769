import { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { silentRequest, tokenRequest } from 'components/AzureAD/authConfig';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import PropTypes from 'prop-types';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  getUserRoles,
  accountSearchRight,
  creditAdjustmentRight,
  demographicRight,
  deviceRight,
  isRolesReady,
  processPaymentRight,
  refundRight,
  vehicleRight,
} from 'utilities/userRole';
import CCP from 'components/AmazonConnect/CCP';
import AccountSearchPage from './components/Account/AccountSearch/AccountSearchPage';
import AccountMgmtPage from './components/Account/AccountMgmt/AccountMgmtPage';
import DeviceSearchPage from './components/Account/DeviceMgmt/DeviceSearch/DeviceSearchPage';
import MroAssignmentForm from './components/Account/DeviceMgmt/MroAssignment/MroAssignment';
import ReversalSearchPage from './components/Account/FinancialProcessing/Reversals/ReversalSearchPage';
import FinancialSearchPage from './components/Account/FinancialProcessing/Financials/FinancialSearchPage';
import PaymentSearchPage from './components/Account/FinancialProcessing/Payments/PaymentSearchPage';
import RefundSearchPage from './components/Account/FinancialProcessing/Refunds/RefundSearchPage';
import ChargebackSearchPage from './components/Account/FinancialProcessing/Chargebacks/ChargebackSearchPage';
import CaseSearchPage from './components/Account/CaseManagement/CaseSearchPage';
import CorrespondenceView from './components/Account/AccountMgmt/Correspondences/CorrespondenceView';
import Header from './components/Layout/Header';
import Signout from './components/Signout/Signout';
import MainTheme from './Theme';
import './App.css';

function App() {
  const { accounts } = useMsal();
  const account = accounts[0] || {};
  silentRequest.account = account;
  const { instance } = useMsal();
  const { login, error } = useMsalAuthentication(InteractionType.Silent, silentRequest);
  const isAuthenticated = useIsAuthenticated();

  const setAccountSearchRight = useSetRecoilState(accountSearchRight);
  const setRefundRight = useSetRecoilState(refundRight);
  const setProcessPaymentRight = useSetRecoilState(processPaymentRight);
  const setCreditAdjustmentRight = useSetRecoilState(creditAdjustmentRight);
  const setDemographicRight = useSetRecoilState(demographicRight);
  const setDeviceRight = useSetRecoilState(deviceRight);
  const setVehicleRight = useSetRecoilState(vehicleRight);
  const [hasRoles, setIsRolesReady] = useRecoilState(isRolesReady);

  const userRoles = (roles) => {
    setAccountSearchRight(roles.filter((r) => r.feature === 'Account Search').length > 0);
    setRefundRight(roles.filter((r) => r.feature === 'Refund').length > 0);
    setProcessPaymentRight(roles.filter((r) => r.feature === 'Payments').length > 0);
    setCreditAdjustmentRight(roles.filter((r) => r.feature === 'Credit Adjustment').length > 0);
    setDemographicRight(roles.filter((r) => r.feature === 'Update Demographics').length > 0);
    setDeviceRight(roles.filter((r) => r.feature === 'Update Devices').length > 0);
    setVehicleRight(roles.filter((r) => r.feature === 'Update Vehicles').length > 0);
    if (roles.filter((r) => r.feature === 'AMS Login').length === 0) {
      instance.logoutRedirect(account);
    }
    setIsRolesReady(true);
  };

  useEffect(() => {
    if (window.location.href === process.env.REACT_APP_SIGNOUT_URL) {
      return;
    }
    if (error instanceof InteractionRequiredAuthError) {
      tokenRequest.account = account;
      login(InteractionType.Redirect, tokenRequest);
    }
    if (isAuthenticated) {
      getUserRoles(userRoles);
    }
  }, [error, isAuthenticated]);

  return (
    <div>
      {isAuthenticated && hasRoles ? (
        <ThemeProvider theme={MainTheme}>
          <Header account={account} />
          <Switch>
            <Route exact path="/" component={AccountSearchPage} />
            <Route exact path="/account-management/:accountId" component={AccountMgmtPage} />
            <Route exact path="/device-management/device-search" component={DeviceSearchPage} />
            <Route exact path="/device-management/mro-assignment" component={MroAssignmentForm} />
            <Route exact path="/financial-processing/reversals" component={ReversalSearchPage} />
            <Route exact path="/financial-processing/payments" component={PaymentSearchPage} />
            <Route exact path="/financial-processing/refunds" component={RefundSearchPage} />
            <Route exact path="/case-management" component={CaseSearchPage} />
            <Route
              exact
              path="/financial-processing/chargebacks"
              component={ChargebackSearchPage}
            />
            <Route exact path="/financial-processing/financials" component={FinancialSearchPage} />
            <Route
              exact
              path="/account-management/:accountId/correspondence-view"
              component={CorrespondenceView}
            />
          </Switch>
          <CCP />
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={MainTheme}>
          <Switch>
            <Route exact path="/signout" component={Signout} />
          </Switch>
        </ThemeProvider>
      )}
    </div>
  );
}

export default withRouter(App);

App.propTypes = {
  account: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }),
};

App.defaultProps = {
  account: null,
};
