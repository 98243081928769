import Alert from 'components/Custom/Alert';
import { useRecoilValue } from 'recoil';
import { redirectTo, refundRight } from 'utilities/userRole';
import RefundSearchGrid from './RefundSearchGrid';

function RefundSearchPage() {
  const hasRefundRight = useRecoilValue(refundRight);
  if (!hasRefundRight) redirectTo('/');
  return (
    <Alert childrenId="Refunds" customStyle={{ width: '40%', mb: '16px' }}>
      <RefundSearchGrid />
    </Alert>
  );
}

export default RefundSearchPage;
