import axios from 'utilities/authAxios';
import Alert from 'components/Custom/Alert';
import { useEffect, useState } from 'react';
import AccountSearchGrid from './AccountSearchGrid';

export default function AccountSearchPage() {
  const [accountStatusDescs, setAccountStatusDescs] = useState({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/AccountStatus`)
      .then((res) => {
        setAccountStatusDescs(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Alert childrenId="AccountSearch" customStyle={{ width: '40%', mb: '16px' }}>
      <AccountSearchGrid accountStatusDescs={accountStatusDescs} />
    </Alert>
  );
}
