import Alert from 'components/Custom/Alert';
import { useRecoilValue } from 'recoil';
import { redirectTo, refundRight } from 'utilities/userRole';
import ChargebackSearchGrid from './ChargebackSearchGrid';

function ChargebackSearchPage() {
  const hasRefundRight = useRecoilValue(refundRight);
  if (!hasRefundRight) redirectTo('/');
  return (
    <Alert childrenId="Chargebacks" customStyle={{ width: '40%', mb: '16px' }}>
      <ChargebackSearchGrid />
    </Alert>
  );
}

export default ChargebackSearchPage;
