import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormDialog from 'components/Custom/FormDialog';
import { convertToDateMMddyyyy } from 'utilities/format';
import { useRecoilValue } from 'recoil';
import { accountStatusDescsAtom } from 'utilities/State';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FinancialDetailFormDialog from 'components/Account/FinancialProcessing/FinancialDetailFormDialog';
import PaymentTransferDialogForm from './PaymentTransferDialogForm';

const DataGridCell = ({ id, width, children, center = false, rowHeight }) => (
  <TableCell
    id={id}
    style={{
      width: `${width}px`,
      justifyContent: !center ? 'left' : 'center',
      display: 'flex',
      alignItems: 'center',
      paddingTop: '5px',
      paddingBottom: '5px',
      overflow: 'hidden',
      height: `${rowHeight}px`,
    }}
  >
    {id.includes('finAmount') ? `$${children?.toFixed(2)}` : children}
  </TableCell>
);

// eslint-disable-next-line react/prop-types
const ActionCell = ({
  id,
  width,
  // eslint-disable-next-line react/prop-types
  payment,
  // eslint-disable-next-line react/prop-types
  presentParentAlert,
  rowHeight,
  // eslint-disable-next-line react/prop-types
  open,
  // eslint-disable-next-line react/prop-types
  setOpen,
  // eslint-disable-next-line react/prop-types
  IsPaymentTransferDialogOpen,
  // eslint-disable-next-line react/prop-types
  setIsPaymentTransferDialogOpen,
}) => (
  <DataGridCell id={id} width={width} center rowHeight={rowHeight}>
    {
      // eslint-disable-next-line react/prop-types
      payment.reversalFjNo === 0 ? (
        <FormDialog
          formId="PaymentTransfer"
          buttonIcon={<CompareArrowsIcon />}
          dialogTitle="Payment Transfer"
        >
          <PaymentTransferDialogForm
            payment={payment}
            presentParentAlert={presentParentAlert}
            setIsPaymentTransferDialogOpen={setIsPaymentTransferDialogOpen}
          />
        </FormDialog>
      ) : (
        ''
      )
    }
    {IsPaymentTransferDialogOpen === false && (
      <FinancialDetailFormDialog
        financial={payment}
        parentId="FinancialDetailsForm-payments"
        open={open}
        setOpen={setOpen}
      />
    )}
  </DataGridCell>
);

const DateCell = ({ id, width, children, rowHeight }) => (
  <DataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? convertToDateMMddyyyy(children) : '-'}
  </DataGridCell>
);

const AccountStatusCell = ({ id, width, children, rowHeight }) => (
  <DataGridCell id={id} width={width} rowHeight={rowHeight}>
    {useRecoilValue(accountStatusDescsAtom)[children ? children.toString() : '-'] ?? children}
  </DataGridCell>
);

const CELL_TYPE = {
  action: ActionCell,
  finDate: DateCell,
  accountStatus: AccountStatusCell,
};

export default function PaymentSearchCard(props) {
  const { payment, index } = props;
  const [open, setOpen] = useState(false);
  const [IsPaymentTransferDialogOpen, setIsPaymentTransferDialogOpen] = useState(false);

  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, [props.renderedColumns]);

  const handleDoubleClick = () => {
    setOpen(!open);
  };

  return (
    <TableRow
      onDoubleClick={handleDoubleClick}
      id={`PaymentSearch-tableRow-payment-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`PaymentSearch-tableCell-payment-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            payment={payment}
            index={index}
            presentParentAlert={props.presentParentAlert}
            rowHeight={props.rowHeight}
            open={open}
            setOpen={setOpen}
            IsPaymentTransferDialogOpen={IsPaymentTransferDialogOpen}
            setIsPaymentTransferDialogOpen={setIsPaymentTransferDialogOpen}
          >
            {payment[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

PaymentSearchCard.propTypes = {
  payment: PropTypes.shape({
    fjNo: PropTypes.number.isRequired,
    finDate: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    accountStatus: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    finAmount: PropTypes.number.isRequired,
    accountEffect: PropTypes.string.isRequired,
    pnRef: PropTypes.string.isRequired,
    reversalFjNo: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  renderedColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowHeight: PropTypes.number.isRequired,
  presentParentAlert: PropTypes.func.isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

DataGridCell.defaultProps = {
  center: false,
};

DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

ActionCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
};

AccountStatusCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
