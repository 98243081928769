import React, { useState, useEffect, useMemo } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import WithLoader from 'components/Custom/WithLoader';
import Stack from '@mui/material/Stack';
import axios from 'utilities/authAxios';
import PropTypes from 'prop-types';
import VehicleDetailsCard from './VehicleDetailsCard';

function VehicleDetailsGrid(props) {
  const { accountId } = useParams();
  const [enrollmentStatusDescs, setEnrollmentStatusDescs] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [maxVehicleDetailIdsArray, setMaxVehicleDetailIdsArray] = useState([]);
  const [vehicleDetailIdToSkipSpacing, setVehicleDetailIdToSkipSpacing] = useState(0);

  const enrollmentStatusLookup = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/EnrollmentStatus`)
      .then((res) => {
        setEnrollmentStatusDescs(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Vehicle/GetVehicleDetails/${accountId}`)
      .then((res) => {
        props.setLoading(false);

        setMaxVehicleDetailIdsArray(
          Object.values(
            res.data.reduce((acc, obj) => {
              acc[obj.visKey] = Math.max(obj.vehicleDetailId, acc[obj.visKey] || 0);
              return acc;
            }, {})
          )
        );

        const maxCreatedDate = new Date(Math.max(...res.data.map((obj) => new Date(obj.created))));
        const latestGroup = res.data.filter(
          (obj) => new Date(obj.created).getTime() === maxCreatedDate.getTime()
        );

        setVehicleDetailIdToSkipSpacing(Math.max(...latestGroup.map((obj) => obj.vehicleDetailId)));
        setVehicleDetails(res.data);
        enrollmentStatusLookup();
      })
      .catch((err) => {
        console.log(err);
        props.setLoading(false);
        props.presentError('Error while fetching vehicleDetals. Please refresh the page.');
      });
  }, [accountId, props.vehiclesRefresh]);

  const getEnrollmentStatusDesc = (enrollmentStatusId) => {
    return enrollmentStatusDescs[enrollmentStatusId.toString()];
  };

  const vehicleDetailsMapper = useMemo(
    () =>
      vehicleDetails.map((vehDetails, index) => {
        return vehicleDetails[0] !== null ? (
          <React.Fragment key={vehDetails.plateNumber}>
            <TableBody>
              <VehicleDetailsCard
                index={index}
                key={vehDetails.plateNumber}
                vehicleDetails={vehDetails}
                accountId={accountId}
                getEnrollmentStatusDesc={getEnrollmentStatusDesc}
              />
            </TableBody>
            {maxVehicleDetailIdsArray.includes(vehDetails.vehicleDetailId) &&
            vehicleDetailIdToSkipSpacing !== vehDetails.vehicleDetailId ? (
              <div style={{ marginBottom: '10px' }} />
            ) : null}
          </React.Fragment>
        ) : null;
      }),
    [accountId, enrollmentStatusDescs]
  );

  return (
    <div>
      {!props.loading && (
        <Stack direction="column" spacing={2}>
          <TableContainer component={Paper}>
            <Table size="small" id="Vehicles-table-vehicleDetails">
              <TableHead>
                <TableRow>
                  <TableCell>Plate #</TableCell>
                  <TableCell>VIS Status</TableCell>
                  <TableCell>Start DMV Reg Date</TableCell>
                  <TableCell>End DMV Exp Date</TableCell>
                  <TableCell>Reg. Date</TableCell>
                  <TableCell>Exp. Date</TableCell>
                  <TableCell>Is Active</TableCell>
                  <TableCell>Fee Per Mile</TableCell>
                  <TableCell>Cap</TableCell>
                  <TableCell>Accrued</TableCell>
                  <TableCell>Balance</TableCell>
                  <TableCell>Start Odometer Date</TableCell>
                  <TableCell>Start Odometer</TableCell>
                  <TableCell>End Odometer Date</TableCell>
                  <TableCell>End Odometer</TableCell>
                  <TableCell>Is DMV Renewed</TableCell>
                  <TableCell>Is Early Enrollee</TableCell>
                  <TableCell>MPG</TableCell>
                  <TableCell>Fuel Type</TableCell>
                  <TableCell>Last Updated</TableCell>
                </TableRow>
              </TableHead>
              {vehicleDetailsMapper}
            </Table>
          </TableContainer>
        </Stack>
      )}
    </div>
  );
}

VehicleDetailsGrid.propTypes = {
  accountData: PropTypes.shape({
    accountId: PropTypes.number.isRequired,
    accountNumber: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }).isRequired,
  vehiclesRefresh: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  presentError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default WithLoader(VehicleDetailsGrid);
