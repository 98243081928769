import DataGridHeader from 'components/Custom/DataGridHeader';

const COLUMNS = [
  {
    field: 'fjNo',
    flex: 0.8,
    renderHeader: () => <DataGridHeader columnId="fjNo" heading="FJ #" />,
  },
  {
    field: 'finDate',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="finDate" heading="Date" />,
  },
  {
    field: 'accountNumber',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="accountNumber" heading="Account #" />,
    sortable: false,
  },
  {
    field: 'description',
    flex: 1.5,
    renderHeader: () => <DataGridHeader columnId="description" heading="Description" />,
    sortable: false,
  },
  {
    field: 'reasonCodeDesc',
    flex: 1.5,
    renderHeader: () => <DataGridHeader columnId="reasonCodeDescId" heading="Reason" />,
    sortable: false,
  },
  {
    field: 'finAmount',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="finAmount" heading="Amount" />,
  },
  {
    field: 'pnRef',
    flex: 1.5,
    renderHeader: () => <DataGridHeader columnId="pnRef" heading="PNREF" />,
    sortable: false,
  },
];

export default COLUMNS;
