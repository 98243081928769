import { useCallback, useMemo, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import WithLoader from 'components/Custom/WithLoader';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import DataGridTable, { createQuery } from 'components/Custom/DataGridTable';
import DataGridPagination from 'components/Custom/DataGridPagination';
import useTableState from 'hooks/useTableState';
import { searchTermAtom, isGlobalSearchAtom } from 'utilities/State';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SearchBar from 'components/Custom/SearchBar';
import { useHistory } from 'react-router-dom';
import COLUMNS from './AccountSearchColumns';
import AccountSearchCard from './AccountSearchCard';

const INITIAL_STATE = {
  pageNumber: 0,
  pageSize: 25,
  sort: { field: 'accountNumber', order: 'desc' },
  filter: undefined,
};

function AccountSearchGrid(props) {
  const searchTerm = useRecoilValue(searchTermAtom);
  const isGlobalSearch = useRecoilValue(isGlobalSearchAtom);
  const setGlobalSearchAtom = useSetRecoilState(isGlobalSearchAtom);
  const [isNormalSearch, setNormalSearch] = useState(false);

  const history = useHistory();
  const queryAccounts = useMemo(
    () =>
      createQuery({
        presentAlert: props.presentAlert,
        dismissAlert: props.dismissAlert,
        setLoading: props.setLoading,
        url: `${process.env.REACT_APP_API_URL}/Account/SearchAccounts`,
        retrieveOnPageLoad: false,
        loading: !isNormalSearch,
      }),
    [isNormalSearch]
  );

  const {
    data,
    totalCount,
    loading,
    onSortChange,
    onFilterChange,
    onPageChange,
    onPageSizeChange,
    onRefresh,
    sort,
    pageNumber,
    pageSize,
  } = useTableState(INITIAL_STATE, queryAccounts);

  const clearSearch = useCallback(() => {
    onFilterChange(undefined);
  }, [onFilterChange]);

  const handleSearchSubmit = useCallback(
    (searchBy, searchString = '', startDate = '', endDate = '') => {
      onFilterChange({
        type: searchBy,
        keyword: searchString,
        startDate,
        endDate,
      });
      onSortChange({ field: searchBy, order: 'asc' });
    },
    []
  );

  const searchAll = useCallback(() => {
    onFilterChange({
      type: 'All',
      keyword: searchTerm,
    });
    onSortChange({ field: 'lastName', order: 'asc' });
  }, [isGlobalSearch]);

  useEffect(() => {
    if (isGlobalSearch) {
      searchAll();
      setGlobalSearchAtom(false);
    } else {
      setNormalSearch(true);
    }
  }, [isGlobalSearch]);

  const components = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      Row: (rowProps) => (
        <AccountSearchCard
          presentAlert={props.presentAlert}
          key={rowProps.row.accountId}
          account={rowProps.row}
          index={rowProps.index}
          accountStatusDescs={props.accountStatusDescs}
          renderedColumns={rowProps.renderedColumns}
          rowHeight={rowProps.rowHeight}
        />
      ),
      // eslint-disable-next-line react/no-unstable-nested-components
      NoRowsOverlay: () => <Stack height="100%" alignItems="center" justifyContent="center" />,
      // eslint-disable-next-line
      Pagination: (props) => <DataGridPagination {...props} onRefresh={onRefresh} />,
    }),
    [props.presentAlert, isGlobalSearch]
  );
  if (data.length === 1) {
    history.push(`/account-management/${data[0].accountId}`);
  }
  return (
    <div>
      <Typography sx={{ mb: 2 }} variant="h5" id="AccountSearch-text-header">
        Account Search
      </Typography>
      {!props.loading && (
        <Stack direction="column" spacing={2}>
          <SearchBar
            parentId="Account"
            handleSearchSubmit={handleSearchSubmit}
            clearSearch={clearSearch}
            searchTypes={['Account Number', 'First Name', 'Last Name', 'Plate', 'VIN', 'Email']}
          />
          <DataGridTable
            columns={COLUMNS}
            loading={loading}
            data={data}
            overridingComponent={components}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRefresh={onRefresh}
            totalCount={totalCount}
            sort={sort}
            onSortModelChange={onSortChange}
            hideFooter={!Object.keys(data).length}
          />
        </Stack>
      )}
    </div>
  );
}

AccountSearchGrid.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  accountStatusDescs: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  setLoading: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
};

export default WithLoader(AccountSearchGrid);
