import { useCallback, useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import WithLoader from 'components/Custom/WithLoader';
import axios from 'utilities/authAxios';
import DataGridTable, { createQuery } from 'components/Custom/DataGridTable';
import DataGridPagination from 'components/Custom/DataGridPagination';
import useTableState from 'hooks/useTableState';
import FormDialog from 'components/Custom/FormDialog';
import SearchBar from 'components/Custom/SearchBar';
import COLUMNS from './NotesColumns';
import NotesCard from './NotesCard';
import AddNoteDialogForm from './AddNoteDialogForm';

const INITIAL_STATE = {
  pageNumber: 0,
  pageSize: 25,
  sort: { field: 'created', order: 'desc' },
  filter: undefined,
};

function NotesPage(props) {
  const { accountId } = useParams();
  const [notes, setNotes] = useState([]);
  const [noteTypeDescs, setNoteTypeDescs] = useState({});
  const [noteSourceTypeDescs, setNoteSourceTypeDesc] = useState({});

  const getNoteTypeDesc = (noteTypeId) => {
    return noteTypeDescs[noteTypeId.toString()];
  };

  const getNoteSourceTypeDesc = (noteSourceTypeId) => {
    return noteSourceTypeDescs[noteSourceTypeId.toString()];
  };

  const getNoteSourceType = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/NoteSourceType`)
      .then((res) => {
        setNoteSourceTypeDesc(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/NoteType`)
      .then((res) => {
        setNoteTypeDescs(res.data);
      })
      .catch((err) => console.log(err));

    getNoteSourceType();
  }, []);

  const queryNotes = useMemo(
    () =>
      createQuery({
        extraParams: { accountId },
        presentAlert: props.presentAlert,
        dismissAlert: props.dismissAlert,
        setLoading: props.setLoading,
        url: `${process.env.REACT_APP_API_URL}/Note/GetNotesByAccount/`,
      }),
    [accountId, notes]
  );

  const {
    data,
    totalCount,
    loading,
    onFilterChange,
    onSortChange,
    onPageChange,
    onPageSizeChange,
    onRefresh,
    sort,
    pageNumber,
    pageSize,
  } = useTableState(INITIAL_STATE, queryNotes);

  const addNote = (formData) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Note/NewNote`, formData)
      .then((res) => {
        if (res.data) {
          const newNotes = [...data, res.data];
          setNotes(newNotes);
          props.presentAlert('success', 'New note added successfully');
        } else {
          props.presentAlert('error', 'New note was not added');
        }
      })
      .catch((err) => {
        console.log(err);
        props.presentAlert('error', 'New note was not added');
      });
  };

  const components = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      Row: (rowProps) => (
        <NotesCard
          key={rowProps.row.noteId}
          note={rowProps.row}
          index={rowProps.index}
          getNoteTypeDesc={getNoteTypeDesc}
          getNoteSourceTypeDesc={getNoteSourceTypeDesc}
          presentAlert={props.presentAlert}
          dismissAlert={props.dismissAlert}
          renderedColumns={rowProps.renderedColumns}
          rowHeight={rowProps.rowHeight}
        />
      ),
      // eslint-disable-next-line
      Pagination: (props) => <DataGridPagination {...props} onRefresh={onRefresh} />,
    }),
    [accountId, props.presentAlert, getNoteTypeDesc, getNoteSourceTypeDesc]
  );

  const clearSearch = useCallback(() => {
    onFilterChange(undefined);
  }, [onFilterChange]);

  const handleSearchSubmit = useCallback((searchBy, searchString = '') => {
    onFilterChange({
      type: searchBy,
      keyword: searchString,
    });
  }, []);

  return (
    <div>
      {!props.loading && (
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={90}>
            <SearchBar
              parentId="Note"
              handleSearchSubmit={handleSearchSubmit}
              clearSearch={clearSearch}
              searchTypes={['CallReason', 'UserName']}
            />
            <Stack style={{ position: 'absolute', right: '32px' }}>
              <FormDialog formId="AddNote" buttonText="Add Note" dialogTitle="Add Note">
                <AddNoteDialogForm
                  addNote={addNote}
                  accountId={accountId}
                  presentAlert={props.presentAlert}
                  setLoading={props.setLoading}
                />
              </FormDialog>
            </Stack>
          </Stack>
          <DataGridTable
            columns={COLUMNS}
            loading={loading}
            data={data}
            overridingComponent={components}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRefresh={onRefresh}
            totalCount={totalCount}
            sort={sort}
            onSortModelChange={onSortChange}
          />
        </Stack>
      )}
    </div>
  );
}

NotesPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
};

export default WithLoader(NotesPage);
