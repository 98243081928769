export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    postLogoutRedirectUri: process.env.REACT_APP_SIGNOUT_URL,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false,
  },
};

// Coordinates and required scopes for your web API
export const apiConfig = {
  accountApiUri: `${process.env.REACT_APP_API_URL}/Account`,
  resourceScopes: [process.env.REACT_APP_RESOURCESCOPES],
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'offline_access', ...apiConfig.resourceScopes],
};

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
  scopes: [...apiConfig.resourceScopes],
};

// Add here scopes for silent token request
export const silentRequest = {
  scopes: ['openid', 'profile', ...apiConfig.resourceScopes],
};
