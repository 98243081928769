import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import axios from 'utilities/authAxios';
import { accountStatusDescsAtom } from 'utilities/State';
import { useRecoilValue } from 'recoil';
import WithLoader from 'components/Custom/WithLoader';
import FormDialog from 'components/Custom/FormDialog';
import OrderNewDeviceDialogForm from './OrderNewDeviceDialogForm';
import AccountDeviceCard from './AccountDeviceCard';
import DevicesHistoryPage from './DevicesHistoryPage';

function AccountDevicesPage(props) {
  const [eligibleVehicles, setEligibleVehicles] = useState([]);
  const [refresh, toggleRefresh] = useState(false);
  const [devices, setDevices] = useState([]);
  const [plateStatusDescs, setPlateStatusDescs] = useState({});
  const [salesOrderStatusDescs, setSalesOrderStatusDescs] = useState({});
  const [tagStatusDescs, setTagStatusDescs] = useState({});
  const [productDescs, setProductDescs] = useState({});
  const accountStatusDescs = useRecoilValue(accountStatusDescsAtom);
  const { accountId } = useParams();

  const lookupStatus = () => {
    Promise.all([
      axios.get(`${process.env.REACT_APP_API_URL}/Lookup/SalesOrderStatus`),
      axios.get(`${process.env.REACT_APP_API_URL}/Lookup/TagStatus`),
      axios.get(`${process.env.REACT_APP_API_URL}/Lookup/ProductDesc`),
      axios.get(`${process.env.REACT_APP_API_URL}/Lookup/PlateStatus`),
    ])
      .then(([{ data: salesOrder }, { data: tag }, { data: product }, { data: plate }]) => {
        Promise.all([
          setTagStatusDescs(tag),
          setSalesOrderStatusDescs(salesOrder),
          setProductDescs(product),
          setPlateStatusDescs(plate),
        ]).then(props.setLoading(false));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const eligibleVehiclesCheck = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Device/OrderDeviceEligibilityCheck/${accountId}`)
      .then((res) => {
        setEligibleVehicles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Device/GetAccountDevices/${accountId}`)
      .then((res) => {
        const sortedDevices = res.data.sort(({ accountTagId: a }, { accountTagId: b }) => a - b);
        setDevices(sortedDevices);
        eligibleVehiclesCheck();
        lookupStatus();
      })
      .catch((err) => {
        console.log(err);
        props.presentError('Server could not be reached. Please refresh the page.');
      });
  }, [refresh]);

  const getTagStatusDesc = (tagStatusId) => {
    return tagStatusDescs[tagStatusId.toString()];
  };
  const getProductDesc = (productId) => {
    return productDescs[productId?.toString()];
  };

  const getPlateStatusDesc = (plateStatusId) => {
    return plateStatusDescs[plateStatusId.toString()];
  };

  const getSalesOrderStatusDesc = (salesOrderStatusId) => {
    return salesOrderStatusDescs[salesOrderStatusId.toString()];
  };

  const getAccountStatusDesc = (accountStatusId) => {
    return accountStatusDescs[accountStatusId.toString()];
  };

  const orderDevice = (orderDeviceParams) => {
    const { plateId } = orderDeviceParams;
    axios
      .get(`${process.env.REACT_APP_API_URL}/Device/OrderNewDevice/${accountId}/1/${plateId}`)
      .then((res) => {
        if (res.data > 0) {
          props.presentAlert('success', 'New device has been ordered');
          toggleRefresh(!refresh);
        } else {
          props.presentAlert('error', 'New device was not ordered');
        }
      })
      .catch((err) => {
        console.log(err);
        props.presentAlert('error', 'New device was not ordered');
      });
  };

  const updateDevice = (updatedDeviceObj) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Device/UpdateDevice`, updatedDeviceObj)
      .then((res) => {
        if (res.data) {
          toggleRefresh(!refresh);
          props.presentAlert('success', 'Device information was updated successfully');
        } else {
          props.presentAlert('error', `Device information was not updated`);
        }
      })
      .catch((err) => {
        if (err.response?.status === 500) {
          props.presentAlert('error', `Device information was not updated`);
        } else {
          props.presentAlert(
            'error',
            `Device information was not updated. ${err.response.data.message}`
          );
        }
      });
  };

  const changeDeviceType = (changeDeviceRequest, vin) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Device/ChangeMROTypeCSR`, changeDeviceRequest)
      .then((res) => {
        if (res) {
          props.presentAlert(
            'success',
            `Change device type request has been processed successfully for vehicle with VIN ${vin}`
          );
          toggleRefresh(!refresh);
        } else {
          props.presentAlert(
            'error',
            `Fail to process change device type request for vehicle with VIN ${vin}`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        props.presentAlert(
          'error',
          `Fail to process change device type request for vehicle with VIN ${vin}`
        );
      });
  };

  const devicesMapper = devices.map((device, index) => {
    const deviceWithDescriptions = {
      ...device,
      tagStatusDesc: getTagStatusDesc(device.tagStatusId),
      plateStatusDesc: getPlateStatusDesc(device.plateStatusId),
      productDesc: getProductDesc(device.productId),
      salesOrderStatusDesc: getSalesOrderStatusDesc(device.salesOrderStatusId),
      accountStatusDesc: getAccountStatusDesc(device.accountStatusId),
    };

    return (
      <AccountDeviceCard
        changeDeviceType={changeDeviceType}
        updateDevice={updateDevice}
        getTagStatusDesc={getTagStatusDesc}
        tagStatusDescs={tagStatusDescs}
        productDescs={productDescs}
        getProductDesc={getProductDesc}
        device={deviceWithDescriptions}
        index={index}
        key={device.accountTagId}
      />
    );
  });

  return (
    <div>
      {!props.loading && (
        <>
          <FormDialog
            formId="OrderNewDevice"
            buttonText="Order Device"
            dialogTitle="Order Device"
            disableButton={eligibleVehicles.length === 0}
          >
            <OrderNewDeviceDialogForm
              eligibleVehicles={eligibleVehicles}
              orderDevice={orderDevice}
              getProductDesc={getProductDesc}
            />
          </FormDialog>

          <Stack direction="column" spacing={2}>
            <TableContainer component={Paper}>
              <Table size="small" id="AccountDevices-table-accountDevices">
                <TableHead>
                  <TableRow>
                    <TableCell>MRO #</TableCell>
                    <TableCell>VIN</TableCell>
                    <TableCell>Plate #</TableCell>
                    <TableCell>Plate Status</TableCell>
                    <TableCell>Product Type</TableCell>
                    <TableCell>Device Status</TableCell>
                    <TableCell>Heartbeat</TableCell>
                    <TableCell>Status Date</TableCell>
                    <TableCell>GPS Enabled</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell align="center" colSpan={2}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{devicesMapper}</TableBody>
              </Table>
            </TableContainer>
          </Stack>
          <div style={{ marginTop: '50px' }}>
            <DevicesHistoryPage />
          </div>
        </>
      )}
    </div>
  );
}

AccountDevicesPage.propTypes = {
  setLoading: PropTypes.func.isRequired,
  presentError: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  presentAlert: PropTypes.func.isRequired,
};

export default WithLoader(AccountDevicesPage);
