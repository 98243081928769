import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme();

const MainTheme = createTheme({
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '.MuiTableBody-root > &:nth-of-type(odd)': {
            backgroundColor: defaultTheme.palette.action.hover,
          },
        },
      },
    },
  },
});

export default MainTheme;
