import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import AccountToolMenu from './AccountTool/AccountToolMenu';

export default function AccountInfoHeader(props) {
  return (
    <>
      <Typography sx={{ m: 1 }} variant="h5" id="AccountInfoHeader-text-nameAndAccountNo">
        {props?.data
          ? `${props.data.accountNumber} - ${props.data.firstName} ${props.data.lastName}`
          : ''}
      </Typography>
      <AccountToolMenu presentAlert={props.presentAlert} />
    </>
  );
}
AccountInfoHeader.propTypes = {
  data: PropTypes.shape({
    accountNumber: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  presentAlert: PropTypes.func,
};
AccountInfoHeader.defaultProps = {
  data: undefined,
  presentAlert: undefined,
};
