import DataGridHeader from 'components/Custom/DataGridHeader';

const COLUMNS = [
  {
    field: 'isUnreadEmail',
    flex: 0.15,
    renderHeader: () => <DataGridHeader columnId="isUnreadEmail" heading="IsRead" />,
  },
  {
    field: 'created',
    flex: 0.25,
    renderHeader: () => <DataGridHeader columnId="created" heading="Created" />,
  },
  {
    field: 'accountNumber',
    flex: 0.25,
    renderHeader: () => <DataGridHeader columnId="accountNumber" heading="Account #" />,
  },
  {
    field: 'caseNumber',
    flex: 0.25,
    renderHeader: () => <DataGridHeader columnId="caseNumber" heading="Case #" />,
  },
  {
    field: 'caseType',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="caseType" heading="Case Type" />,
  },
  {
    field: 'description',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="description" heading="Description" />,
    sortable: false,
  },
  {
    field: 'caseStatusId',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="caseStatusId" heading="Status" />,
  },
  {
    field: 'casePriorityId',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="casePriorityId" heading="Priority" />,
  },
  {
    field: 'action',
    flex: 0.15,
    renderHeader: () => <DataGridHeader columnId="action" heading="Action" />,
    sortable: false,
  },
];

export default COLUMNS;
