import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Alert from 'components/Custom/Alert';
import CaseDetails from './CaseDetails';
import CaseCommunicationPage from './CaseCommunicationPage';

function tabSwitch(index, caseObj) {
  switch (index) {
    case 0:
      return <CaseDetails caseObj={caseObj} />;
    case 1:
      return <CaseCommunicationPage caseObj={caseObj} />;
    default:
      return <div />;
  }
}
function WrapPage(childrenId, index, caseObj) {
  return (
    <Alert isIndependent childrenId={childrenId}>
      {tabSwitch(index, caseObj)}
    </Alert>
  );
}
export default function CaseManagementTabPanel(props) {
  const { childrenId, value, index, caseObj } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{WrapPage(childrenId, index, caseObj)}</Box>}
    </div>
  );
}

CaseManagementTabPanel.propTypes = {
  childrenId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  caseObj: PropTypes.shape({
    created: PropTypes.string.isRequired,
    caseNumber: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    caseType: PropTypes.string.isRequired,
    caseStatusId: PropTypes.number.isRequired,
    dueDate: PropTypes.string,
    emailAddress: PropTypes.string,
  }).isRequired,
};
