import { atom } from 'recoil';

export const caseStatusDescsAtom = atom({
  key: 'caseStatusDescs',
  default: {},
});

export const caseSourceDescsAtom = atom({
  key: 'caseSourceDescs',
  default: {},
});

export const casePriorityDescsAtom = atom({
  key: 'casePriorityDescs',
  default: {},
});
