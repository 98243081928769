import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { convertToDateTime } from 'utilities/format';
import { Tooltip } from '@mui/material';
import DeleteConfirmationDialog from 'components/Custom/DeleteConfirmationDialog';
import CustomTextDataGridCell from 'components/Custom/CustomTextDataGridCell';
import DescriptionDialog from 'components/Custom/DescriptionDialog';

const DataGridCell = ({ id, width, children, center = false, rowHeight }) => (
  <TableCell
    id={id}
    style={{
      width: `${width}px`,
      justifyContent: !center ? 'left' : 'center',
      display: 'flex',
      alignItems: 'center',
      paddingTop: '0px',
      paddingBottom: '0px',
      overflow: 'hidden',
      height: `${rowHeight}px`,
    }}
  >
    {children}
  </TableCell>
);

// eslint-disable-next-line react/prop-types
const ActionCell = ({ id, width, handleClick, handleResend, rowHeight }) => (
  <DataGridCell id={id} width={width} center rowHeight={rowHeight}>
    <Tooltip title="View Correspondence">
      <IconButton
        color="inherit"
        aria-label="correspondence"
        size="small"
        id="Correspondences-btn-viewCorrespondence"
        onClick={handleClick}
      >
        <VisibilityIcon />
      </IconButton>
    </Tooltip>
    <DeleteConfirmationDialog
      customButton={
        <Tooltip title="Resend Correspondence">
          <IconButton
            color="inherit"
            aria-label="correspondence"
            size="small"
            id="Correspondences-btn-resendCorrespondence"
          >
            <SendIcon />
          </IconButton>
        </Tooltip>
      }
      buttonId="Resend-Correspondence-dialog-box"
      dialogTitle="Resend Correspondence"
      title="Resend Correspondence"
      description="Are you sure you want to resend this correspondence?"
      handleConfirmation={(success) => {
        if (success) {
          handleResend();
        }
      }}
    />
  </DataGridCell>
);

const DateCell = ({ id, width, children, rowHeight }) => (
  <DataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? convertToDateTime(children) : ''}
  </DataGridCell>
);

const StatusCell = ({ id, width, children, rowHeight, open, setOpen, correspondence }) => (
  <CustomTextDataGridCell
    id={id}
    width={width}
    rowHeight={rowHeight}
    large
    customButton={
      <DescriptionDialog
        open={open}
        setOpen={setOpen}
        dialogTitle="Resent Information"
        resentCreatedBy={children}
        resentNotificationDate={children}
        correspondence={correspondence}
      />
    }
  >
    {children}
  </CustomTextDataGridCell>
);

const CELL_TYPE = {
  action: ActionCell,
  notificationDt: DateCell,
  notificationStatusDesc: StatusCell,
};

export default function CorrespondencesCard(props) {
  const { correspondence, index } = props;

  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, []);

  const handleClick = () => {
    props.viewCorrespondence(correspondence.correspondenceLogId);
  };

  const handleResend = () => {
    props.resendCorrespondence(correspondence.correspondenceLogId);
  };

  const [open, setOpen] = useState(false);
  const handleDoubleClick = () => {
    if (props.correspondence.notificationStatusDesc === 'Resent') {
      setOpen(!open);
    }
  };

  return (
    <TableRow
      onDoubleClick={handleDoubleClick}
      id={`Correspondences-tableRow-correspondence-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`Correspondences-tableCell-correspondence-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            correspondence={correspondence}
            index={index}
            handleClick={handleClick}
            handleResend={handleResend}
            rowHeight={props.rowHeight}
            open={open}
            setOpen={setOpen}
          >
            {correspondence[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

CorrespondencesCard.propTypes = {
  correspondence: PropTypes.shape({
    correspondenceLogId: PropTypes.number.isRequired,
    eventDesc: PropTypes.string.isRequired,
    notificationDt: PropTypes.string.isRequired,
    notificationStatusDesc: PropTypes.string.isRequired,
    lastUpdBy: PropTypes.number.isRequired,
    eventCode: PropTypes.string.isRequired,
    functionality: PropTypes.string.isRequired,
    lastResentNotificationDt: PropTypes.string.isRequired,
    resentCreatedBy: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  renderedColumns: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
  viewCorrespondence: PropTypes.func.isRequired,
  resendCorrespondence: PropTypes.func.isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

DataGridCell.defaultProps = {
  center: false,
};

DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

ActionCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
};

StatusCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  correspondence: PropTypes.shape({
    correspondenceLogId: PropTypes.number.isRequired,
    eventDesc: PropTypes.string.isRequired,
    notificationDt: PropTypes.string.isRequired,
    notificationStatusDesc: PropTypes.string.isRequired,
    lastUpdBy: PropTypes.number.isRequired,
    eventCode: PropTypes.string.isRequired,
    functionality: PropTypes.string.isRequired,
    lastResentNotificationDt: PropTypes.string.isRequired,
    resentCreatedBy: PropTypes.string.isRequired,
  }).isRequired,
};
