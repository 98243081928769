import { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { currentActiveAlert } from 'utilities/State';
import { useRecoilState } from 'recoil';

export default function Alert(props) {
  const [activeAlert, setActiveAlert] = useRecoilState(currentActiveAlert);

  const [alertState, setAlertState] = useState({
    isOpen: false,
    title: '',
    message: '',
    severity: 'error',
  });

  const baseId = `${props.childrenId}-alert-${alertState.severity}`;

  const dismissAlert = () => {
    setAlertState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  const presentAlert = (severity, message, title = '') => {
    setAlertState({
      isOpen: true,
      title,
      message,
      severity,
    });
    if (!props.isIndependent) {
      if (activeAlert.dismiss && activeAlert.alertId !== props.childrenId) activeAlert.dismiss();
      setActiveAlert({ dismiss: dismissAlert, alertId: props.childrenId });
    }
  };

  return (
    <Box id={`Alert-box-${alertState.isOpen}`}>
      <Collapse in={alertState.isOpen}>
        <MuiAlert
          sx={props.customStyle !== null ? props.customStyle : { width: 'fit-content', mb: '16px' }}
          id={baseId}
          action={
            <IconButton
              id={`${baseId}-btn-close`}
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                dismissAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={alertState.severity}
          elevation={6}
          variant="filled"
        >
          {alertState.title && <AlertTitle id={`${baseId}-title`}>{alertState.title}</AlertTitle>}
          <div id={`${baseId}-message`}>{alertState.message}</div>
        </MuiAlert>
      </Collapse>
      {props.children !== undefined
        ? cloneElement(props.children, { presentAlert, dismissAlert })
        : ''}
    </Box>
  );
}

Alert.propTypes = {
  childrenId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  customStyle: PropTypes.objectOf(PropTypes.string),
  isIndependent: PropTypes.bool,
};

Alert.defaultProps = {
  customStyle: null,
  isIndependent: false,
};
