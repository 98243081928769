import { useState, useEffect } from 'react';
import axios from 'utilities/authAxios';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { amountValidation } from 'utilities/PaymentValidation';

export default function PostRefundDialogForm(props) {
  const [refundTypes, setRefundTypes] = useState([]);
  const [cardToUse, setCardToUse] = useState({
    cardId: '',
    cardNumber: '',
    expiryYear: '',
    expiryMonth: '',
    nameOnCard: '',
    postalCode: '',
    cardTypeId: '',
  });
  const [cardTypes, setCardTypes] = useState({});
  const [accountBalance, setAccountBalance] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const [amountError, setAmountError] = useState({ helperText: '', hasError: false });
  const { accountId } = useParams();

  const [state, setState] = useState({
    finTransCodeId: null,
    reasonCodeId: null,
    finTransTypeId: null,
    comment: '',
    amount: null,
    accountId,
  });

  const getAccountBalance = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Financial/GetAccountBalance/${accountId}`)
      .then((res) => {
        setAccountBalance(res.data);
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.setLoading(false);
        props.presentAlert('error', 'Server could not be reached. Please refresh the page.');
      });
  };

  const getTransCodeId = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Financial/GetFinTransCodes/${'Refund'}`)
      .then((res) => {
        setState({
          ...state,
          finTransTypeId: res.data[0].finTransTypeId,
          finTransCodeId: res.data[0].finTransCodeId,
        });
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.setLoading(false);
        props.presentAlert('error', 'Server could not be reached. Please refresh the page.');
      });
  };

  const getRefundTypes = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Financial/GetReasonCodes`)
      .then((res) => {
        setRefundTypes(res.data);
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.setLoading(false);
        props.presentAlert('error', 'Server could not be reached. Please refresh the page.');
      });
  };

  const getCardTypes = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/CreditCardType`)
      .then((res) => {
        setCardTypes(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getCardsForAccount = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Payment/GetCardsForAccount/${accountId}`)
      .then((res) => {
        // eslint-disable-next-line func-names
        const filteredResult = res.data.filter(function (elem) {
          return elem.priority === 1 && elem.cardStatusId === 1;
        });
        if (filteredResult.length !== 0) {
          setCardToUse({
            cardId: filteredResult[0].cardId,
            cardNumber: filteredResult[0].cardNum,
            expiryYear: filteredResult[0].expYear,
            expiryMonth: filteredResult[0].expMonth,
            nameOnCard: filteredResult[0].nameOnCard,
            postalCode: filteredResult[0].postalCode,
            cardTypeId: filteredResult[0].cardTypeId,
          });
          props.setLoading(false);
        } else {
          props.setLoading(false);
          props.presentAlert('error', 'No credit cards on file');
        }
        getCardTypes();
      })
      .catch(() => {
        props.setLoading(false);
        props.presentAlert('error', 'Server could not be reached. Please refresh the page.');
      });
  };

  useEffect(() => {
    getRefundTypes();
    getTransCodeId();
    getAccountBalance();
    getCardsForAccount();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'amount') {
      let amountValidationResult = '';
      amountValidationResult = amountValidation(value);
      if (amountValidationResult !== '') {
        setAmountError({ helperText: amountValidationResult, hasError: true });
        setDisableBtn(true);
      } else {
        setAmountError({ helperText: amountValidationResult, hasError: false });
        setDisableBtn(false);
      }
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.amount > accountBalance) {
      props.presentAlert('error', 'Refund amount cannot be greater than account balance');
      return;
    }
    props.setLoading(true);
    const postFeeObj = {
      accountId: state.accountId,
      finTransCodeId: state.finTransCodeId,
      finTransTypeId: state.finTransTypeId,
      reasonCodeId: state.reasonCodeId,
      amount: state.amount,
      comments: state.comment,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/Financial/ProcessCreditCardRefund/`, postFeeObj)
      .then((res) => {
        if (res.data > 0) {
          props.setLoading(false);
          props.handleClose();
          props.reloadAccountInfo();
          // present alert in accountInfoHeader
          props.presentParentAlert('success', 'Refund was posted successfully');
        } else {
          props.setLoading(false);
          // present alert in dialog
          props.presentAlert('error', 'Refund was not posted');
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err);
        props.presentAlert('error', 'Refund was not posted');
      });
  };

  const refundTypesMenuItems = refundTypes
    ?.filter((reasonTransType) => reasonTransType.finTransTypeId === 6)
    .map((refundType) => {
      return (
        <MenuItem
          key={refundType.reasonCodeId}
          value={refundType.reasonCodeId}
          id={`PostRefund-select-refundTypeValue-${refundType.reasonCodeDesc.replaceAll(' ', '')}`}
        >
          {refundType.reasonCodeDesc}
        </MenuItem>
      );
    });

  const cardTypesMenuItems = Object.entries(cardTypes).map((entry) => {
    return (
      <MenuItem
        key={entry[0]}
        value={entry[0]}
        id={`PostRefund-select-cardTypeValue-${entry[1].replaceAll(' ', '')}`}
      >
        {entry[1]}
      </MenuItem>
    );
  });

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                required
                fullWidth
                label="Refund Reason"
                size="small"
                id="PostRefund-select-refundType"
                value={state.reasonCodeId}
                name="reasonCodeId"
                onChange={handleChange}
              >
                {refundTypesMenuItems}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                type="number"
                fullWidth
                label="Amount"
                size="small"
                id="PostRefund-textField-amount"
                value={state.amount}
                name="amount"
                helperText={amountError.helperText}
                error={amountError.hasError}
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                disabled
                label="Account Balance"
                size="small"
                id="PostRefund-textField-accountBalance"
                name="ownerLastName"
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                value={accountBalance}
                onChange={handleChange}
              />
            </Grid>
            <Box sx={{ width: '100%' }} />
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                disabled
                label="Card Holder's Name"
                size="small"
                variant="outlined"
                id="PostRefund-textField-nameOnCard"
                name="nameOnCard"
                value={cardToUse.nameOnCard}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                disabled
                fullWidth
                label="Card Type"
                size="small"
                id="PostRefund-select-cardType"
                name="cardType"
                onChange={handleChange}
                value={cardToUse.cardTypeId}
              >
                {cardTypesMenuItems}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                disabled
                label="Card Number"
                size="small"
                id="PostRefund-textField-cardNumber"
                name="cardNumber"
                value={cardToUse.cardNumber.replace(/\d(?=\d{4})/g, '*')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                disabled
                label="Expiration Month"
                size="small"
                id="PostRefund-textField-expiryMonth"
                name="expiryMonth"
                value={cardToUse.expiryMonth}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                disabled
                label="Expiration Year"
                size="small"
                variant="outlined"
                id="PostRefund-textField-expiryYear"
                name="expiryYear"
                value={cardToUse.expiryYear}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                disabled
                label="Postal Code"
                size="small"
                variant="outlined"
                id="PostRefund-textField-postalCode"
                name="postalCode"
                value={cardToUse.postalCode}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  label="Refund Comment"
                  minRows={7}
                  placeholder="Enter Comment..."
                  id="PostRefund-textField-comment"
                  value={state.comment}
                  name="comment"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="PostRefund-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={accountBalance === 0 || accountBalance < 0 || disableBtn}
            variant="contained"
            size="small"
            id="PostRefund-btn-post"
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

PostRefundDialogForm.propTypes = {
  presentAlert: PropTypes.func.isRequired,
  presentParentAlert: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  reloadAccountInfo: PropTypes.func.isRequired,
};
