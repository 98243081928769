import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import SettingsCellIcon from '@mui/icons-material/SettingsCell';
import AccountCircle from '@mui/icons-material/AccountCircle';
import TextField from '@mui/material/TextField';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Grid from '@mui/material/Grid';

export default function AddContactDialogForm(props) {
  const [state, setState] = useState({
    accountId: props.accountId,
    contactMethod: 1,
    title: '',
    firstName: '',
    lastName: '',
    primaryPhone: '',
    mobilePhone: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newContact = {
      ...state,
      firstName: state.firstName
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' '),
      lastName: state.lastName
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' '),
    };
    props.addContact(newContact);
    props.handleClose();
  };

  const salutations = ['Mr.', 'Mrs.', 'Miss.', 'Ms.', 'Prof.', 'Dr.'];
  const salutationMenuItems = salutations.map((value) => {
    return (
      <MenuItem
        key={value.replace('.', '')}
        value={value}
        id={`AddContact-select-salutationTypeValue-${value.replace('.', '')}`}
      >
        {value}
      </MenuItem>
    );
  });

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                size="small"
                label="Title"
                id="AddContact-select-salutationType"
                value={state.title}
                name="title"
                onChange={handleChange}
              >
                {salutationMenuItems}
              </TextField>
            </Grid>
            <Box width="100%" />
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                label="First Name"
                id="AddContact-textField-firstName"
                name="firstName"
                value={state.firstName}
                onChange={handleChange}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="Last Name"
                id="AddContact-textField-lastName"
                name="lastName"
                value={state.lastName}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="Primary Phone"
                id="AddContact-textField-primaryPhone"
                name="primaryPhone"
                value={state.primaryPhone}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="Mobile Phone"
                id="AddContact-textField-mobilePhone"
                name="mobilePhone"
                value={state.mobilePhone}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SettingsCellIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="Email"
                type="email"
                id="AddContact-textField-email"
                name="email"
                value={state.email}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="AddContact-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button variant="contained" size="small" id="AddContact-btn-createContact" type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

AddContactDialogForm.propTypes = {
  addContact: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};
