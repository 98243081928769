import { useMemo, useState } from 'react';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import { convertToDateMMddyyyy } from 'utilities/format';
import CustomTextDataGridCell from 'components/Custom/CustomTextDataGridCell';
import DescriptionDialog from 'components/Custom/DescriptionDialog';
import UpdateCaseDialog from './UpdateCaseDialog';

const DataGridCell = ({ id, width, center, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} center={center} rowHeight={rowHeight}>
    {children}
  </CustomTextDataGridCell>
);
const DateCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? convertToDateMMddyyyy(children) : '-'}
  </CustomTextDataGridCell>
);

const CaseStatusCell = ({ id, width, rowHeight, children, getCaseStatusDesc }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? getCaseStatusDesc(children) : '-'}
  </CustomTextDataGridCell>
);

const CaseTypeCell = ({ id, width, rowHeight, children }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children}
  </CustomTextDataGridCell>
);
const CasePriorityCell = ({ id, width, rowHeight, children, getCasePriorityDesc }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? getCasePriorityDesc(children) : '-'}
  </CustomTextDataGridCell>
);

const AccountNumberCell = ({ id, width, rowHeight, children }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children || '-'}
  </CustomTextDataGridCell>
);

const IsReadCell = ({ id, width, rowHeight, children }) => (
  <DataGridCell id={id} width={width} center rowHeight={rowHeight}>
    {children ? '-' : <MarkEmailReadIcon />}
  </DataGridCell>
);

const DescriptionCell = ({ id, width, rowHeight, children, open, setOpen }) => (
  <CustomTextDataGridCell
    id={id}
    width={width}
    rowHeight={rowHeight}
    large
    customButton={
      <DescriptionDialog
        open={open}
        dialogTitle="Message"
        setOpen={setOpen}
        description={children}
      />
    }
  >
    {children}
  </CustomTextDataGridCell>
);

const ActionCell = ({ id, width, rowHeight, open, accountId, caseObj, isAccountLevel }) => (
  <DataGridCell id={id} width={width} center rowHeight={rowHeight}>
    {accountId === null || isAccountLevel === true ? (
      <UpdateCaseDialog id="UpdateCaseDialog" open={open} caseObj={caseObj} />
    ) : (
      <Link
        to={{
          // eslint-disable-next-line react/prop-types
          pathname: `/account-management/${accountId}`,
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <IconButton
          color="inherit"
          aria-label="person"
          size="small"
          id="CaseSearch-btn-openAccountMgmt"
        >
          <PersonIcon />
        </IconButton>
      </Link>
    )}
  </DataGridCell>
);
const CELL_TYPE = {
  created: DateCell,
  action: ActionCell,
  caseStatusId: CaseStatusCell,
  dueDate: DateCell,
  caseType: CaseTypeCell,
  casePriorityId: CasePriorityCell,
  accountNumber: AccountNumberCell,
  isUnreadEmail: IsReadCell,
  description: DescriptionCell,
};

export default function CaseSearchCard(props) {
  const { caseObj, index, getCaseStatusDesc, getCasePriorityDesc, isAccountLevel } = props;
  const [open, setOpen] = useState(false);
  const handleDoubleClick = () => {
    setOpen(!open);
  };

  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, [props.renderedColumns]);

  return (
    <TableRow
      onDoubleClick={handleDoubleClick}
      id={`CaseSearch-tableRow-case-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`CaseSearch-tableCell-case-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            isAccountLevel={isAccountLevel}
            accountId={caseObj?.accountId}
            caseObj={caseObj}
            index={index}
            presentParentAlert={props.presentParentAlert}
            rowHeight={props.rowHeight}
            getCaseStatusDesc={getCaseStatusDesc}
            getCasePriorityDesc={getCasePriorityDesc}
            open={open}
            setOpen={setOpen}
          >
            {caseObj[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

CaseSearchCard.propTypes = {
  caseObj: PropTypes.shape({
    caseId: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
    caseNumber: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    caseType: PropTypes.string.isRequired,
    caseStatusId: PropTypes.number.isRequired,
    dueDate: PropTypes.string,
    accountId: PropTypes.number,
    isUnreadEmail: PropTypes.bool.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  renderedColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowHeight: PropTypes.number.isRequired,
  presentParentAlert: PropTypes.func.isRequired,
  getCaseStatusDesc: PropTypes.func.isRequired,
  getCasePriorityDesc: PropTypes.func.isRequired,
  isAccountLevel: PropTypes.bool.isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node,
  center: PropTypes.bool,
};

DataGridCell.defaultProps = {
  center: false,
};

DataGridCell.defaultProps = {
  children: null,
};

DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node,
};

DateCell.defaultProps = {
  children: null,
};

IsReadCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

DescriptionCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

ActionCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  isAccountLevel: PropTypes.bool.isRequired,
  accountId: PropTypes.number,
  caseObj: PropTypes.shape({
    created: PropTypes.string.isRequired,
    caseNumber: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    caseType: PropTypes.string.isRequired,
    caseStatusId: PropTypes.number.isRequired,
    dueDate: PropTypes.string,
    accountId: PropTypes.number,
  }).isRequired,
};

ActionCell.defaultProps = {
  accountId: null,
};

CaseStatusCell.propTypes = {
  getCaseStatusDesc: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

CasePriorityCell.propTypes = {
  getCasePriorityDesc: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

CaseTypeCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

AccountNumberCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node,
};

AccountNumberCell.defaultProps = {
  children: null,
};
