/* eslint-disable no-nested-ternary */
import {
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
} from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IconButton, Typography } from '@mui/material';

export default function CustomGroupingCell(props) {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );

  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
  const handleClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  const rowCountComponent = () => {
    return (
      <span id={`DataGrid-groupCell-${rowNode.groupingKey}-rowCount`}>
        {rowNode.children.length}
      </span>
    );
  };

  return (
    <Box>
      {filteredDescendantCount > 0 ? (
        rowNode.childrenExpanded ? (
          <div>
            <IconButton
              id={`DataGrid-groupCell-${
                rowNode.groupingKey.charAt(0).toLowerCase() +
                rowNode.groupingKey.slice(1).replace(' ', '')
              }`}
              size="small"
              onClick={handleClick}
              disableRipple
            >
              <ArrowDropDownIcon />
            </IconButton>
            <Typography textAlign="center" variant="body2" component="span">
              {rowNode.groupingKey} ({rowCountComponent()})
            </Typography>
          </div>
        ) : (
          <div>
            <IconButton
              id={`DataGrid-groupCell-${
                rowNode.groupingKey.charAt(0).toLowerCase() +
                rowNode.groupingKey.slice(1).replace(' ', '')
              }`}
              size="small"
              onClick={handleClick}
              disableRipple
            >
              <ArrowRightIcon />
            </IconButton>
            <Typography textAlign="center" variant="body2" component="span">
              {rowNode.groupingKey} ({rowCountComponent()})
            </Typography>
          </div>
        )
      ) : (
        <span />
      )}
    </Box>
  );
}

CustomGroupingCell.propTypes = {
  rowNode: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  field: PropTypes.func.isRequired,
};
