import { useCallback } from 'react';
import Pagination from '@mui/material/Pagination';
import CachedIcon from '@mui/icons-material/Cached';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import {
  gridPageCountSelector,
  gridPaginationSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
  gridPageSelector,
} from '@mui/x-data-grid';
import { PaginationItem } from '@mui/material';

const MIN_ROW_COUNT_FOR_PAGINATION_FOOTER = 25;

const TablePaginationSelectLabel = styled('p', {
  name: 'MuiTablePagination',
  slot: 'SelectLabel',
  overridesResolver: (props, styles) => styles.selectLabel,
})(({ theme }) => ({
  ...theme.typography.body2,
  flexShrink: 0,
}));

function defaultLabelDisplayedRows({ from, to, count }) {
  return `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

const CustomRowsPerPage = ({
  rowsPerPageOptions,
  rowsPerPage,
  pageNumber,
  totalCount,
  onRowsPerPageChange,
}) => {
  const getLabelDisplayedRowsTo = () => {
    if (totalCount === -1) {
      return (pageNumber + 1) * rowsPerPage;
    }
    return rowsPerPage === -1 ? totalCount : Math.min(totalCount, (pageNumber + 1) * rowsPerPage);
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '12px', flex: '0 0 auto' }}>
      <TablePaginationSelectLabel id="DataGrid-text-rowsPerPage" sx={{ padding: '5px' }}>
        Rows per page:
      </TablePaginationSelectLabel>
      <Select
        id="DataGrid-select-rowsPerPage"
        sx={{ marginRight: 1, height: '30px' }}
        value={rowsPerPage}
        onChange={(e) => {
          onRowsPerPageChange(e.target.value);
        }}
        label="Rows per page:"
      >
        {rowsPerPageOptions.map((option) => (
          <MenuItem id={`DataGrid-select-rowsPerPage-${option}`} value={option} key={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <Typography id="DataGrid-text-displayedRows" variant="body2" sx={{ flexShrink: 0 }}>
        {defaultLabelDisplayedRows({
          from: totalCount === 0 ? 0 : pageNumber * rowsPerPage + 1,
          to: getLabelDisplayedRowsTo(),
          count: totalCount === -1 ? -1 : totalCount,
        })}
      </Typography>
    </Box>
  );
};

function DataGridPagination({ onRefresh }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const page = useGridSelector(apiRef, gridPageSelector);
  const rootProps = useGridRootProps();
  const paginationState = useGridSelector(apiRef, gridPaginationSelector);

  const handlePageSizeChange = useCallback(
    (newPageSize) => {
      apiRef.current.setPageSize(newPageSize);
    },
    [apiRef]
  );

  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      {paginationState.rowCount > MIN_ROW_COUNT_FOR_PAGINATION_FOOTER && (
        <Box sx={{ display: 'flex', flex: 1 }}>
          <Box sx={{ margin: 'auto', display: 'flex', alignItems: 'center' }}>
            <Pagination
              sx={{ flex: '0 0 auto' }}
              color="primary"
              count={pageCount}
              page={page + 1}
              onChange={(event, value) => apiRef.current.setPage(value - 1)}
              renderItem={(item) => (
                <PaginationItem
                  id={
                    item.type === 'page'
                      ? `DataGrid-btn-pageNumber-${item.page}`
                      : `DataGrid-btn-${item.type}`
                  }
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...item}
                />
              )}
            />
            <Tooltip title="Refresh">
              <IconButton
                id="DataGrid-btn-refresh"
                color="inherit"
                onClick={onRefresh}
                sx={{ color: 'black', flex: '0 0 auto' }}
                size="small"
              >
                <CachedIcon color="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
          <CustomRowsPerPage
            pageNumber={page}
            totalCount={paginationState.rowCount}
            rowsPerPageOptions={
              rootProps.rowsPerPageOptions?.includes(paginationState.pageSize)
                ? rootProps.rowsPerPageOptions
                : []
            }
            rowsPerPage={paginationState.pageSize}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </Box>
      )}
    </Box>
  );
}

CustomRowsPerPage.propTypes = {
  totalCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
};

DataGridPagination.propTypes = {
  onRefresh: PropTypes.func.isRequired,
};

export default DataGridPagination;
