/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import axios from 'utilities/authAxios';
import Box from '@mui/material/Box';
import WithLoader from 'components/Custom/WithLoader';
import PropTypes from 'prop-types';
import Alert from 'components/Custom/Alert';
import AssignMroForm from './AssignMroForm';
import AssignTrackingForm from './AssignTrackingForm';

function MroAssignment(props) {
  const [disabledInput, setDisabledInput] = useState(false);
  const [state, setState] = useState({
    step: 1,
    accountId: null,
    accountTagId: null,
    fullName: '',
    tagSerialNumber: '',
    licPlateNo: '',
    vin: '',
    addressLine1: '',
    city: '',
    stateProvinceCode: '',
    trackingNumber: '',
    postalCode: '',
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Device/GetMroUnassignedDevice`)
      .then((res) => {
        if (res.data !== '') {
          setState({
            ...state,
            accountId: res.data.accountId,
            accountTagId: res.data.accountTagId,
            fullName: res.data.fullName,
            licPlateNo: res.data.licPlateNo,
            vin: res.data.vin,
            addressLine1: res.data.addressLine1,
            city: res.data.city,
            postalCode: res.data.postalCode,
            stateProvinceCode: res.data.stateProvinceCode,
          });
          props.setLoading(false);
        } else {
          props.setLoading(false);
          props.presentAlert('error', 'No device is available for assignment');
        }
      })
      .catch(() => props.presentError('Server could not be reached. Please refresh the page.'));
  }, []);

  const continues = (e) => {
    e.preventDefault();
    const { step } = state;
    setState({
      ...state,
      step: step + 1,
    });
  };

  const back = (e) => {
    e.preventDefault();
    const { step } = state;
    setState({
      ...state,
      step: step - 1,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleAssignmentFormSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_URL}/Device/AssignMroDevice`, {
        accountTagId: state.accountTagId,
        mroId: state.tagSerialNumber,
        vin: state.vin,
        trackingNumber: state.trackingNumber,
      })
      .then(() => {
        props.presentAlert('success', 'Assigned successfully');
        setDisabledInput(true);
      })
      .catch((err) => {
        if (err.response?.status === 500 || err.response?.status === 400)
          props.presentAlert('error', err.response?.data || 'MRO could not be assigned');
        else {
          props.presentAlert('error', 'Could not connect to server');
        }
      });
  };

  const renderPages = () => {
    switch (state.step) {
      case 1:
        return (
          <AssignMroForm
            disabledInput={disabledInput}
            handleChange={handleChange}
            state={state}
            continues={continues}
          />
        );
      case 2:
        return (
          <AssignTrackingForm
            disabledInput={disabledInput}
            handleChange={handleChange}
            state={state}
            back={back}
            handleAssignmentFormSubmit={handleAssignmentFormSubmit}
          />
        );
      default:
        break;
    }
  };

  return (
    <div>
      {!props.loading && (
        <Box sx={{ width: 700, margin: 'auto' }}>
          <Box sx={{ textAlign: 'center' }}>{renderPages()}</Box>
        </Box>
      )}
    </div>
  );
}

MroAssignment.propTypes = {
  setLoading: PropTypes.func.isRequired,
  presentError: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  presentAlert: PropTypes.func.isRequired,
};

const MroAssignmentWithLoader = WithLoader(MroAssignment);

const MroAssignmentLoaderWithAlert = () => {
  return (
    <Alert childrenId="AssignMROTracking">
      <MroAssignmentWithLoader />
    </Alert>
  );
};

export default MroAssignmentLoaderWithAlert;
