import DataGridHeader from 'components/Custom/DataGridHeader';

const COLUMNS = [
  {
    field: 'fjNo',
    flex: 0.8,
    renderHeader: () => <DataGridHeader columnId="fjNoId" heading="FJ #" />,
  },
  {
    field: 'finDate',
    flex: 1.2,
    renderHeader: () => <DataGridHeader columnId="finDateId" heading="Date" />,
  },
  {
    field: 'accountNumber',
    flex: 1.5,
    renderHeader: () => <DataGridHeader columnId="accountNoId" heading="Account #" />,
    sortable: false,
  },
  {
    field: 'description',
    flex: 2,
    renderHeader: () => <DataGridHeader columnId="descId" heading="Description" />,
    sortable: false,
  },
  {
    field: 'reasonCodeDesc',
    flex: 1.5,
    renderHeader: () => <DataGridHeader columnId="reasonCodeDescId" heading="Reason" />,
    sortable: false,
  },
  {
    field: 'finAmount',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="amountId" heading="Amount" />,
  },
  {
    field: 'pnRef',
    flex: 2.5,
    renderHeader: () => <DataGridHeader columnId="pnRefId" heading="PNREF" />,
    sortable: false,
  },
  {
    field: 'message',
    flex: 2,
    renderHeader: () => <DataGridHeader columnId="messageId" heading="Message" />,
    sortable: false,
  },
  {
    field: 'reversalFjNo',
    flex: 1.2,
    renderHeader: () => <DataGridHeader columnId="reversalFjId" heading="Reversal FJ#" />,
    sortable: false,
  },
];

export default COLUMNS;
