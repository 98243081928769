import axios from 'utilities/authAxios';
import { useHistory } from 'react-router-dom';
import { atom } from 'recoil';

export function getUserRoles(cb) {
  axios
    .get(`${process.env.REACT_APP_API_URL}/User/GetRoles`)
    .then((res) => {
      cb(res.data);
    })
    .catch(() => {
      cb([]);
    });
}

export function redirectTo(path) {
  const history = useHistory();
  history.push({ pathname: path });
}

export const accountSearchRight = atom({
  key: 'accountSearchRight',
  default: false,
});

export const refundRight = atom({
  key: 'refundRight',
  default: false,
});

export const processPaymentRight = atom({
  key: 'processPaymentRight',
  default: false,
});

export const creditAdjustmentRight = atom({
  key: 'creditAdjustmentRight',
  default: false,
});

export const demographicRight = atom({
  key: 'demographicRight',
  default: false,
});

export const deviceRight = atom({
  key: 'deviceRight',
  default: false,
});

export const vehicleRight = atom({
  key: 'vehicleRight',
  default: false,
});

export const isRolesReady = atom({
  key: 'isRolesReady',
  default: false,
});
