/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import axios from 'utilities/authAxios';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { accountRebillMethodAtom } from 'utilities/State';
import { amountValidation } from 'utilities/PaymentValidation';

export default function OneTimePaymentDialogForm(props) {
  const [existingCards, setExistingCards] = useState([]);
  const rebillMethodId = useRecoilValue(accountRebillMethodAtom);
  const [cardToUse, setCardToUse] = useState({
    cardId: null,
    cardNumber: '',
    expiryYear: '',
    expiryMonth: '',
    nameOnCard: '',
    postalCode: '',
    cardTypeId: null,
  });

  const [achToUse, setAchToUse] = useState({
    achId: null,
    accountId: null,
    accountNumber: '',
    routingNumber: '',
    holderName: '',
    achTypeId: null,
  });
  const { accountId } = useParams();
  const [paymentTypes, setPaymentTypes] = useState({});
  const [cardTypes, setCardTypes] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);
  const [amountError, setAmountError] = useState({ helperText: '', hasError: false });

  const [state, setState] = useState({
    amount: null,
    paymentType: rebillMethodId.toString(),
  });

  const renderSelectedCard = (cardId) => {
    existingCards.forEach((card) => {
      if (card.cardId === cardId) {
        setCardToUse({
          cardId,
          cardNumber: card.cardNum,
          expiryYear: card.expYear,
          expiryMonth: card.expMonth,
          nameOnCard: card.nameOnCard,
          postalCode: card.postalCode,
          cardTypeId: card.cardTypeId,
        });
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'amount') {
      let amountValidationResult = '';
      amountValidationResult = amountValidation(value);
      if (amountValidationResult !== '') {
        setAmountError({ helperText: amountValidationResult, hasError: true });
        setDisableBtn(true);
      } else {
        setAmountError({ helperText: amountValidationResult, hasError: false });
        setDisableBtn(false);
      }
    }

    if (name === 'cardId') {
      renderSelectedCard(value);
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  const getCardTypes = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/CreditCardType`)
      .then((res) => {
        setCardTypes(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getPaymentTypes = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/PaymentType`)
      .then((res) => {
        setPaymentTypes(res.data);
      })
      .catch((err) => console.log(err));
  };

  const setAccountACHs = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Payment/GetAchForAccount/${accountId}`)
      .then((res) => {
        if (res.data.length !== 0) {
          setAchToUse({
            achId: res.data[0].achId,
            accountId: res.data[0].accountId,
            accountNumber: res.data[0].displayACHInfo,
            routingNumber: res.data[0].aba,
            holderName: res.data[0].holderName,
            achTypeId: res.data[0].achTypeId,
          });
        } else if (state.paymentType === '4') {
          props.presentAlert('error', 'Cannot retrieve ACH accounts');
        }
      })
      .catch((err) => {
        console.log(err);
        if (state.paymentType === '4') {
          props.presentAlert('error', 'Cannot retrieve ACH accounts');
        }
      });
  };

  const setAccountCards = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Payment/GetCardsForAccount/${accountId}`)
      .then((res) => {
        props.setLoading(false);
        const sortedResults = res.data.sort(({ priority: a }, { priority: b }) => a - b);
        setExistingCards(sortedResults);
        if (sortedResults.length !== 0) {
          setCardToUse({
            cardId: sortedResults[0].cardId,
            cardNumber: sortedResults[0].cardNum,
            expiryYear: sortedResults[0].expYear,
            expiryMonth: sortedResults[0].expMonth,
            nameOnCard: sortedResults[0].nameOnCard,
            postalCode: sortedResults[0].postalCode,
            cardTypeId: sortedResults[0].cardTypeId,
          });
        } else if (state.paymentType === '3') {
          props.setLoading(false);
          props.presentAlert('error', 'No credit cards on file');
        }
        getCardTypes();
      })
      .catch(() => {
        props.setLoading(false);
        props.presentAlert('error', 'Server could not be reached. Please refresh the page.');
      });
  };

  useEffect(() => {
    props.setLoading(true);
    setAccountCards();
    getPaymentTypes();
    setAccountACHs();
  }, []);

  const existingCardsMenuItems = existingCards.map((card) => {
    return (
      <MenuItem
        key={card.cardId}
        value={card.cardId}
        id={`OneTimePayment-select-existingCardValue-${card.cardId}`}
      >
        {card.cardNum}
      </MenuItem>
    );
  });
  const cardTypesMenuItems = Object.entries(cardTypes).map((entry) => {
    return (
      <MenuItem
        key={entry[0]}
        value={entry[0]}
        id={`OneTimePayment-select-cardTypeValue-${entry[1].replaceAll(' ', '')}`}
      >
        {entry[1]}
      </MenuItem>
    );
  });
  const paymentTypesMenuItems =
    cardToUse.cardId > 0 && achToUse.achId === null
      ? Object.entries(
          Object.fromEntries(Object.entries(paymentTypes).filter(([key]) => key.includes('3')))
        ).map((entry) => {
          return (
            <MenuItem
              key={entry[0]}
              value={entry[0]}
              id={`OneTimePayment-select-paymentTypeValue-${entry[1].replaceAll(' ', '')}`}
            >
              {entry[1]}
            </MenuItem>
          );
        })
      : achToUse.achId > 0 && cardToUse.cardId === null
      ? Object.entries(
          Object.fromEntries(Object.entries(paymentTypes).filter(([key]) => key.includes('4')))
        ).map((entry) => {
          return (
            <MenuItem
              key={entry[0]}
              value={entry[0]}
              id={`OneTimePayment-select-paymentTypeValue-${entry[1].replaceAll(' ', '')}`}
            >
              {entry[1]}
            </MenuItem>
          );
        })
      : Object.entries(paymentTypes).map((entry) => {
          return (
            <MenuItem
              key={entry[0]}
              value={entry[0]}
              id={`OneTimePayment-select-paymentTypeValue-${entry[1].replaceAll(' ', '')}`}
            >
              {entry[1]}
            </MenuItem>
          );
        });

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setLoading(true);
    const postPaymentObj = {
      payRequestId: 0,
      accountId,
      payRequestTypeId: 2,
      cardId: cardToUse.cardId,
      cardTypeId: cardToUse.cardTypeId,
      cardNumber: cardToUse.cardNumber,
      nameOnCard: cardToUse.nameOnCard,
      expiryMonth: cardToUse.expiryMonth.toString(),
      expiryYear: cardToUse.expiryYear.toString(),
      amount: state.amount,
      postalCode: cardToUse.postalCode,
      pnRef: '',
      voidPayRequestId: 0,
      accountNumber: achToUse.accountNumber,
      routingNumber: achToUse.routingNumber,
      achId: achToUse.achId,
      achAccountTypeId: achToUse.achTypeId,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Financial/MakeOneTimePayment?paymentType=${state.paymentType}`,
        postPaymentObj
      )
      .then(() => {
        props.setLoading(false);
        props.reloadAccountInfo();
        // present alert in AccountInfoHeader
        props.presentParentAlert('success', 'Payment was processed successfully');
        props.handleClose();
      })
      .catch(() => {
        props.setLoading(false);
        props.presentAlert('error', 'Failed to process payment');
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                type="number"
                fullWidth
                label="Amount"
                size="small"
                id="OneTimePayment-textField-amount"
                value={state.amount}
                name="amount"
                helperText={amountError.helperText}
                error={amountError.hasError}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                required
                fullWidth
                size="small"
                label="Payment Type"
                id="OneTimePayment-select-paymentTypeValue"
                value={state.paymentType}
                name="paymentType"
                onChange={handleChange}
              >
                {paymentTypesMenuItems}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {state.paymentType === '3' ? (
              <>
                <Grid item xs={6}>
                  <TextField
                    select
                    required
                    fullWidth
                    label="Card"
                    size="small"
                    id="OneTimePayment-select-existingCard"
                    name="cardId"
                    onChange={handleChange}
                    value={cardToUse.cardId ?? ''}
                  >
                    {existingCardsMenuItems}
                  </TextField>
                </Grid>

                <Box sx={{ width: '100%' }} />
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    disabled
                    label="Card Holder's Name"
                    size="small"
                    variant="outlined"
                    id="OneTimePayment-textField-nameOnCard"
                    name="nameOnCard"
                    value={cardToUse.nameOnCard}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    disabled
                    fullWidth
                    label="Card Type"
                    size="small"
                    id="OneTimePayment-select-cardType"
                    name="cardType"
                    onChange={handleChange}
                    value={cardToUse.cardTypeId ?? ''}
                  >
                    {cardTypesMenuItems}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    disabled
                    label="Card Number"
                    size="small"
                    id="OneTimePayment-textField-cardNumber"
                    name="cardNumber"
                    value={cardToUse.cardNumber}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    disabled
                    label="Expiration Month"
                    size="small"
                    id="OneTimePayment-textField-expiryMonth"
                    name="expiryMonth"
                    value={cardToUse.expiryMonth}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    disabled
                    label="Expiration Year"
                    size="small"
                    variant="outlined"
                    id="OneTimePayment-textField-expiryYear"
                    name="expiryYear"
                    value={cardToUse.expiryYear}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    disabled
                    label="Postal Code"
                    size="small"
                    variant="outlined"
                    id="OneTimePayment-textField-postalCode"
                    name="postalCode"
                    value={cardToUse.postalCode}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <TextField
                    required
                    disabled
                    fullWidth
                    label="ACH Holder Name"
                    size="small"
                    variant="outlined"
                    id="OneTimePayment-textField-achHolderName"
                    name="achHolderName"
                    value={achToUse.holderName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    disabled
                    fullWidth
                    label="ACH Account Number"
                    size="small"
                    variant="outlined"
                    id="OneTimePayment-textField-achNumber"
                    name="achNumber"
                    value={achToUse.accountNumber}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="OneTimePayment-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={disableBtn}
            variant="contained"
            size="small"
            id="OneTimePayment-btn-post"
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

OneTimePaymentDialogForm.propTypes = {
  presentAlert: PropTypes.func.isRequired,
  presentParentAlert: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  reloadAccountInfo: PropTypes.func.isRequired,
};
