import { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { convertToDateMMddyyyy } from 'utilities/format';

const DataGridCell = ({ id, width, children, center = false, rowHeight }) => (
  <TableCell
    id={id}
    style={{
      width: `${width}px`,
      justifyContent: !center ? 'left' : 'center',
      display: 'flex',
      alignItems: 'center',
      paddingTop: '0px',
      paddingBottom: '0px',
      overflow: 'hidden',
      height: `${rowHeight}px`,
    }}
  >
    {children}
  </TableCell>
);

// eslint-disable-next-line react/prop-types
const DeviceStatusCell = ({ id, width, children, getdeviceStatusDesc, rowHeight }) => (
  <DataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children + 1 ? getdeviceStatusDesc(children) : '-'}
  </DataGridCell>
);

const DateCell = ({ id, width, children, rowHeight }) => (
  <DataGridCell id={id} width={width} rowHeight={rowHeight}>
    {convertToDateMMddyyyy(children)}
  </DataGridCell>
);

const CELL_TYPE = {
  preTagStatusDate: DateCell,
  newTagStatusDate: DateCell,
  preTagStatusId: DeviceStatusCell,
  newTagStatusId: DeviceStatusCell,
};

export default function DevicesHistoryCard(props) {
  const { devicesHistory, index, getdeviceStatusDesc } = props;
  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, []);

  return (
    <TableRow
      id={`DeviceHistory-tableRow-devicesHistory-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`DeviceHistory-tableCell-deviceHistory-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            devicesHistory={devicesHistory}
            index={index}
            rowHeight={props.rowHeight}
            getdeviceStatusDesc={getdeviceStatusDesc}
          >
            {devicesHistory[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

DevicesHistoryCard.propTypes = {
  devicesHistory: PropTypes.shape({
    accountTagHistoryId: PropTypes.number.isRequired,
    preTagStatusId: PropTypes.string.isRequired,
    preTagStatusDate: PropTypes.string.isRequired,
    newTagStatusId: PropTypes.string.isRequired,
    newTagStatusDate: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  renderedColumns: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
  getdeviceStatusDesc: PropTypes.func.isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

DataGridCell.defaultProps = {
  center: false,
};

DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
