import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import WithLoader from 'components/Custom/WithLoader';
import SearchBar from 'components/Custom/SearchBar';
import DataGridTable, { createQuery } from 'components/Custom/DataGridTable';
import DataGridPagination from 'components/Custom/DataGridPagination';
import useTableState from 'hooks/useTableState';
import COLUMNS from './DeviceSearchColumns';
import DeviceSearchCard from './DeviceSearchCard';

const INITIAL_STATE = {
  pageNumber: 0,
  pageSize: 25,
  sort: { field: 'shipDt', order: 'desc' },
  filter: undefined,
};

function DeviceSearchGrid(props) {
  const { accountId } = useParams();
  const queryDevices = useMemo(
    () =>
      createQuery({
        extraParams: { accountId },
        presentAlert: props.presentAlert,
        dismissAlert: props.dismissAlert,
        setLoading: props.setLoading,
        url: `${process.env.REACT_APP_API_URL}/Device/SearchDevices`,
        retrieveOnPageLoad: false,
      }),
    [accountId]
  );

  const {
    data,
    totalCount,
    loading,
    onSortChange,
    onFilterChange,
    onPageChange,
    onPageSizeChange,
    onRefresh,
    sort,
    pageNumber,
    pageSize,
  } = useTableState(INITIAL_STATE, queryDevices);

  const clearSearch = useCallback(() => {
    onFilterChange(undefined);
  }, [onFilterChange]);

  const handleSearchSubmit = useCallback(
    (searchBy, searchString = '', startDate = '', endDate = '') => {
      onFilterChange({
        type: searchBy,
        keyword: searchString,
        startDate,
        endDate,
      });
    },
    []
  );

  const components = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      Row: (rowProps) => (
        <DeviceSearchCard
          accountId={accountId}
          presentAlert={props.presentAlert}
          key={rowProps.row.accountTagId}
          device={rowProps.row}
          index={rowProps.index}
          renderedColumns={rowProps.renderedColumns}
          rowHeight={rowProps.rowHeight}
        />
      ),
      // eslint-disable-next-line react/no-unstable-nested-components
      NoRowsOverlay: () => <Stack height="100%" alignItems="center" justifyContent="center" />,
      // eslint-disable-next-line
      Pagination: (props) => <DataGridPagination {...props} onRefresh={onRefresh} />,
    }),
    [accountId, props.presentAlert]
  );

  return (
    <div>
      <Typography sx={{ mb: 2 }} variant="h5" id="DeviceSearch-text-header">
        Device Search
      </Typography>
      {!props.loading && (
        <Stack direction="column" spacing={2}>
          <SearchBar
            parentId="Device"
            handleSearchSubmit={handleSearchSubmit}
            clearSearch={clearSearch}
            searchTypes={['Account Number', 'Plate', 'VIN', 'Tag Serial Number']}
          />
          <DataGridTable
            columns={COLUMNS}
            loading={loading}
            data={data}
            overridingComponent={components}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRefresh={onRefresh}
            totalCount={totalCount}
            sort={sort}
            onSortModelChange={onSortChange}
            hideFooter={!Object.keys(data).length}
          />
        </Stack>
      )}
    </div>
  );
}

DeviceSearchGrid.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
};

export default WithLoader(DeviceSearchGrid);
