import DataGridHeader from 'components/Custom/DataGridHeader';

const COLUMNS = [
  {
    field: 'statementNumber',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="statementNo" heading="Statement Number" />,
  },
  {
    field: 'statementMonth',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="statementMonth" heading="Statement Month" />,
    sortable: false,
  },
  {
    field: 'periodStartDate',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="startPeriod" heading="Starting Period" />,
    sortable: false,
  },
  {
    field: 'periodEndDate',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="endPeriod" heading="Ending Period" />,
    sortable: false,
  },
  {
    field: 'beginBalance',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="beginBalance" heading="Beginning Balance" />,
    sortable: false,
  },
  {
    field: 'endBalance',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="endBalance" heading="Ending Balance" />,
    sortable: false,
  },
  {
    field: 'newCharge',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="newCharge" heading="New Charge" />,
    sortable: false,
  },
  {
    field: 'statementDate',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="statementDate" heading="Process Date" />,
    sortable: false,
  },
  {
    field: 'action',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="action" heading="Action" />,
    sortable: false,
  },
];

export default COLUMNS;
