export function creditCardValidation(creditCardObj) {
  const someday = new Date();
  const today = new Date();

  const todaysDay = String(today.getDate()).padStart(2, '0');

  let validationResultObj = null;
  let cardNumLengthError = '';
  let expirationError = '';
  let postalCodeError = '';

  someday.setFullYear(
    creditCardObj.expiryYear,
    creditCardObj.expiryMonth - 1,
    parseInt(todaysDay, 10)
  );
  if (someday < today || creditCardObj.expiryYear?.length !== 4 || creditCardObj.expiryMonth > 12) {
    expirationError = 'Invalid Expiration Month/Year';
  }

  if (creditCardObj.cardNumber?.length < 16) {
    cardNumLengthError = 'Invalid Card Number';
  }

  if (creditCardObj.postalCode?.length !== 5) {
    postalCodeError = 'Invalid Postal Code';
  }

  validationResultObj = {
    expirationError,
    cardNumLengthError,
    postalCodeError,
  };

  return validationResultObj;
}

export function amountValidation(amount, vehicleCapAmount = 0, capFeeAmountValidation = false) {
  let amountValueError = '';

  if (amount <= 0) {
    amountValueError = 'Amount must be greater than 0';
  }

  if (amount > vehicleCapAmount && capFeeAmountValidation) {
    amountValueError = 'Posting amount cannot be greater than CAP amount';
  }
  return amountValueError;
}
