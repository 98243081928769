import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import OrderNewDeviceCard from './OrderNewDeviceCard';

export default function OrderNewDeviceDialogForm(props) {
  const { eligibleVehicles, orderDevice, getProductDesc } = props;

  const eligibleVehiclesMapper = eligibleVehicles.map((vehicle, index) => {
    return (
      <OrderNewDeviceCard
        vehicle={vehicle}
        index={index}
        orderDevice={orderDevice}
        getProductDesc={getProductDesc}
        handleClose={props.handleClose}
      />
    );
  });

  return (
    <div>
      <DialogContent>
        <Stack spacing={2}>{eligibleVehiclesMapper}</Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          id="OrderNewDevice-btn-cancel"
          onClick={props.handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </div>
  );
}

OrderNewDeviceDialogForm.propTypes = {
  eligibleVehicles: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.number.isRequired,
      vin: PropTypes.string.isRequired,
      plateNumber: PropTypes.string.isRequired,
      vehicleId: PropTypes.number.isRequired,
    })
  ).isRequired,
  orderDevice: PropTypes.func.isRequired,
  getProductDesc: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
