import DataGridHeader from 'components/Custom/DataGridHeader';

const COLUMNS = [
  {
    field: 'fjNo',
    flex: 0.8,
    renderHeader: () => <DataGridHeader columnId="fjNo" heading="FJ #" />,
  },
  {
    field: 'chargebackDate',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="chargebackDate" heading="Chargeback Date" />,
  },
  {
    field: 'accountNumber',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="accountNumber" heading="Account #" />,
    sortable: false,
  },
  {
    field: 'description',
    flex: 1.5,
    renderHeader: () => <DataGridHeader columnId="description" heading="Description" />,
    sortable: false,
  },
  {
    field: 'reason',
    flex: 1.5,
    renderHeader: () => <DataGridHeader columnId="reason" heading="Reason" />,
    sortable: false,
  },
  {
    field: 'finAmount',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="finAmount" heading="Amount" />,
  },
  {
    field: 'originalPNRef',
    flex: 1.5,
    renderHeader: () => <DataGridHeader columnId="originalPNRef" heading="Original PNREF" />,
    sortable: false,
  },
  {
    field: 'reversalFJNo',
    flex: 0.8,
    renderHeader: () => <DataGridHeader columnId="reversalFjNo" heading="Reversal FJ #" />,
    sortable: false,
  },
  {
    field: 'createdDate',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="createdDate" heading="Created Date" />,
  },
  {
    field: 'action',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="actionId" heading="Action" />,
    sortable: false,
  },
];

export default COLUMNS;
