import { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import axios from 'utilities/authAxios';
import WithLoader from 'components/Custom/WithLoader';
import DataGridTable, { createQuery } from 'components/Custom/DataGridTable';
import DataGridPagination from 'components/Custom/DataGridPagination';
import useTableState from 'hooks/useTableState';
import COLUMNS from './DevicesHistoryColumns';
import DevicesHistoryCard from './DevicesHistoryCard';

const INITIAL_STATE = {
  pageNumber: 0,
  pageSize: 25,
  sort: { field: 'tagSerialNumber', order: 'desc' },
  filter: undefined,
};

const NO_OVERLAY = <Stack height="100%" alignItems="center" justifyContent="center" />;

function DevicesHistoryPage(props) {
  const { accountId } = useParams();

  const [deviceStatusDescs, setdeviceStatusDescs] = useState({});

  const getdeviceStatusDesc = (deviceStatusId) => {
    return deviceStatusDescs[deviceStatusId.toString()];
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/TagStatus`)
      .then((res) => {
        setdeviceStatusDescs(res.data);
        props.setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const queryDevicesHistory = useMemo(
    () =>
      createQuery({
        extraParams: { accountId },
        presentAlert: props.presentAlert,
        dismissAlert: props.dismissAlert,
        setLoading: props.setLoading,
        url: `${process.env.REACT_APP_API_URL}/Device/GetAccountDevicesHistory`,
      }),
    [accountId]
  );

  const {
    data,
    totalCount,
    loading,
    onSortChange,
    onPageChange,
    onPageSizeChange,
    onRefresh,
    sort,
    pageNumber,
    pageSize,
  } = useTableState(INITIAL_STATE, queryDevicesHistory);

  const components = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      Row: (rowProps) => (
        <DevicesHistoryCard
          accountId={accountId}
          presentAlert={props.presentAlert}
          devicesHistory={rowProps.row}
          index={rowProps.index}
          renderedColumns={rowProps.renderedColumns}
          rowHeight={rowProps.rowHeight}
          getdeviceStatusDesc={getdeviceStatusDesc}
        />
      ),
      NoRowsOverlay: () => NO_OVERLAY,
      // eslint-disable-next-line
      Pagination: (props) => <DataGridPagination {...props} onRefresh={onRefresh} />,
    }),
    [accountId, props.presentAlert, deviceStatusDescs]
  );

  return (
    <div>
      {!props.loading && (
        <Stack direction="column" spacing={2}>
          <DataGridTable
            columns={COLUMNS}
            loading={loading}
            data={data}
            overridingComponent={components}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRefresh={onRefresh}
            totalCount={totalCount}
            sort={sort}
            onSortModelChange={onSortChange}
          />
        </Stack>
      )}
    </div>
  );
}

DevicesHistoryPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
};

export default WithLoader(DevicesHistoryPage);
