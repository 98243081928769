import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export default function AssignTrackingForm(props) {
  const { state, handleChange, back, handleAssignmentFormSubmit, disabledInput } = props;
  return (
    <Card sx={{ mt: 2, boxShadow: 5 }}>
      <Typography variant="h4">Assign Tracking Number</Typography>
      <Typography variant="h5">{state.fullName}</Typography>
      <Typography variant="body1">Account Tag: {state.accountTagId}</Typography>
      <Box
        sx={{ '& > :not(style)': { m: 1 }, marginTop: '16px' }}
        component="form"
        onSubmit={handleAssignmentFormSubmit}
      >
        <FormControl variant="standard" sx={{ width: '40%' }}>
          <TextField
            disabled={disabledInput}
            required
            label="Tracking Number"
            size="small"
            variant="outlined"
            id="AssignMROTracking-textField-trackingNumber"
            name="trackingNumber"
            value={state.trackingNumber}
            onChange={handleChange}
          />
        </FormControl>
        <br />
        <FormControl variant="standard" sx={{ width: '40%' }}>
          <TextField
            disabled
            label="License Plate Number"
            size="small"
            variant="outlined"
            id="AssignMROTracking-textField-licPlateNo"
            name="licPlateNo"
            value={state.licPlateNo}
            onChange={handleChange}
          />
        </FormControl>
        <br />
        <FormControl variant="standard" sx={{ width: '40%' }}>
          <TextField
            disabled
            label="Vehicle Identification Number"
            size="small"
            variant="outlined"
            id="AssignMROTracking-textField-vin"
            name="vin"
            value={state.vin}
            onChange={handleChange}
          />
        </FormControl>
        <Divider />
        <Typography variant="h4">Shipping Address</Typography>
        <FormControl variant="standard" sx={{ width: '40%' }}>
          <TextField
            disabled
            label="Address Line 1"
            size="small"
            variant="outlined"
            id="AssignMROTracking-textField-addressLine1"
            name="addressLine1"
            value={state.addressLine1}
            onChange={handleChange}
          />
        </FormControl>
        <br />
        <FormControl variant="standard" sx={{ width: '40%' }}>
          <TextField
            disabled
            label="City"
            size="small"
            variant="outlined"
            id="AssignMROTracking-textField-city"
            name="city"
            value={state.city}
            onChange={handleChange}
          />
        </FormControl>
        <br />
        <FormControl variant="standard" sx={{ width: '40%' }}>
          <TextField
            disabled
            label="State"
            size="small"
            variant="outlined"
            id="AssignMROTracking-textField-stateProvinceCode"
            name="stateProvinceCode"
            value={state.stateProvinceCode}
            onChange={handleChange}
          />
        </FormControl>
        <br />
        <FormControl variant="standard" sx={{ width: '40%' }}>
          <TextField
            disabled
            label="Postal Code"
            size="small"
            variant="outlined"
            id="AssignMROTracking-textField-postalCode"
            name="postalCode"
            value={state.postalCode}
            onChange={handleChange}
          />
        </FormControl>
        <br />
        <Button variant="contained" size="small" id="AssignMROTracking-btn-back" onClick={back}>
          Previous
        </Button>
        <Button
          disabled={disabledInput}
          variant="contained"
          size="small"
          id="AssignMROTracking-btn-assignTracking"
          type="submit"
        >
          Submit
        </Button>
      </Box>
    </Card>
  );
}

AssignTrackingForm.propTypes = {
  state: PropTypes.shape({
    accountTagId: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    trackingNumber: PropTypes.string.isRequired,
    vin: PropTypes.string.isRequired,
    licPlateNo: PropTypes.string.isRequired,
    addressLine1: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    stateProvinceCode: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }).isRequired,
  disabledInput: PropTypes.bool.isRequired,
  back: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleAssignmentFormSubmit: PropTypes.func.isRequired,
};
