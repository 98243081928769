import { useState, cloneElement } from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Alert from './Alert';

export default function FormDialog(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      if (props.handleParentClose !== null) props.handleParentClose();
    }
  };

  const handleOnClick = () => {
    if (props.handleParentOpen !== null) {
      const result = props.handleParentOpen();
      if (result) {
        setOpen(true);
      }
    } else {
      setOpen(true);
    }
  };

  const customButton = () => {
    if (props.customButton !== null) {
      return cloneElement(props.customButton, {
        onClick: handleOnClick,
      });
    }
    return null;
  };

  return (
    <>
      {props.buttonText !== null ? (
        <Button
          disabled={props.disableButton}
          variant="contained"
          onClick={handleOnClick}
          sx={{ marginBottom: '16px' }}
          id={`${props.formId}-btn-openDialog`}
        >
          {props.buttonText}
        </Button>
      ) : (
        customButton() || (
          <Tooltip title={props.dialogTitle}>
            <IconButton
              color="inherit"
              onClick={handleOnClick}
              id={`${props.formId}-btn-openDialog`}
              sx={{ color: 'black' }}
              size="small"
            >
              {props.buttonIcon}
            </IconButton>
          </Tooltip>
        )
      )}

      {open && (
        <Dialog
          fullWidth
          open
          onClose={handleClose}
          id={`${props.formId}-dialog`}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1, position: 'absolute' }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogTitle>{props.dialogTitle}</DialogTitle>
          <Alert
            childrenId={props.formId}
            isIndependent
            customStyle={{ ml: '4%', mr: '4%', mt: '16px' }}
          >
            {props.children !== undefined
              ? cloneElement(props.children, { handleClose, setLoading })
              : ''}
          </Alert>
        </Dialog>
      )}
    </>
  );
}

FormDialog.propTypes = {
  formId: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.node,
  dialogTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  customButton: PropTypes.node,
  handleParentClose: PropTypes.func,
  handleParentOpen: PropTypes.func,
  disableButton: PropTypes.bool,
};

FormDialog.defaultProps = {
  buttonText: null,
  buttonIcon: null,
  customButton: null,
  handleParentClose: null,
  handleParentOpen: null,
  disableButton: false,
};
