import { useState } from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import axios from 'utilities/authAxios';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

export default function UpdateVehicleFormDialog(props) {
  const { accountId, vehicle } = props;
  const [state, setState] = useState({
    plateNumber: vehicle.plateNumber,
    lookupSuccess: false,
    accountId,
    dmvRegDate: '',
    dmvExpDate: '',
    currentAltFuelVehFlatFee: '',
    renewalAltFuelVehFlatFee: '',
  });

  const getRUCEnrollmentStatus = () => {
    const request = {
      titleNo: vehicle.visKey,
      vin4: vehicle.vin.slice(-4),
      customerNo: vehicle.customerNo,
    };
    if (!state.plateNumber.length) {
      props.presentAlert('error', 'Plate cannot be empty');
    } else {
      props.setLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/Vehicle/DMVStatus`, request)
        .then((res) => {
          props.setLoading(false);
          if (state.plateNumber.toLowerCase() === res.data.plate.toLowerCase()) {
            props.presentAlert('success', 'New plate number is valid');
            setState({
              ...state,
              lookupSuccess: true,
              dmvRegDate: res.data.regDte,
              dmvExpDate: res.data.regExpDte,
              currentAltFuelVehFlatFee: res.data.annualHufAmt,
            });
          } else {
            setState({
              ...state,
              lookupSuccess: false,
            });
            props.presentAlert('error', 'New plate number does not match DMV record');
          }
        })
        .catch(() => {
          props.setLoading(false);
          props.presentAlert('error', 'Enrollment Status lookup failed');
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updateVehicle = {
      plateId: vehicle.vehicleId,
      oldPlateNumber: vehicle.plateNumber,
      plateNumber: state.plateNumber.toUpperCase().trim(),
    };
    props.updateVehicle(updateVehicle);
    props.handleClose();
  };

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                disabled
                fullWidth
                label="VIN"
                size="small"
                id="UpdateVehicle-textField-vin"
                name="vin"
                value={vehicle.vin}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="License Plate Number"
                size="small"
                id="UpdateVehicle-textField-plateNumber"
                name="plateNumber"
                value={state.plateNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                disabled
                label="Owner Last Name"
                size="small"
                id="UpdateVehicle-textField-ownerLastName"
                value={props.accountData.lastName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                disabled
                label="Postal Code"
                size="small"
                id="UpdateVehicle-textField-postalCode"
                value={props.accountData.postalCode}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={getRUCEnrollmentStatus}
                id="UpdateVehicle-btn-rucStatusLookup"
              >
                Get DMV Status
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label="Vehicle Make"
                size="small"
                id="UpdateVehicle-textField-vehicleMake"
                value={vehicle.vehicleMake}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label="Vehicle Model"
                size="small"
                variant="outlined"
                id="UpdateVehicle-textField-vehicleModel"
                value={vehicle.vehicleModel}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="Registration Date"
                variant="outlined"
                id="UpdateVehicle-textField-dmvRegDate"
                value={state.dmvRegDate}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="Expiration Date"
                variant="outlined"
                id="UpdateVehicle-textField-dmvExpDate"
                value={state.dmvExpDate}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="Current Cap Fee"
                variant="outlined"
                id="UpdateVehicle-textField-currentAltFuelVehFlatFee"
                value={`$${vehicle.capFee}`}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="UpdateVehicle-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={!state.lookupSuccess || state.plateNumber === vehicle.plateNumber}
            variant="contained"
            size="small"
            id="UpdateVehicle-btn-updateVehicle"
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

UpdateVehicleFormDialog.propTypes = {
  accountData: PropTypes.shape({
    lastName: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }).isRequired,
  vehicle: PropTypes.shape({
    plateNumber: PropTypes.string.isRequired,
    vin: PropTypes.string.isRequired,
    vehicleMake: PropTypes.string.isRequired,
    vehicleModel: PropTypes.string.isRequired,
    visKey: PropTypes.string.isRequired,
    vehicleId: PropTypes.number.isRequired,
    capFee: PropTypes.number.isRequired,
    customerNo: PropTypes.string.isRequired,
  }).isRequired,
  updateVehicle: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};
