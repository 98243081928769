import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import axios from 'utilities/authAxios';
import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { convertToDate } from 'utilities/format';
import TextField from '@mui/material/TextField';
import { Alert, Typography } from '@mui/material';

export default function CloseAccountDialogForm(props) {
  const { handleClose } = props;
  const [accountClosureReasons, setAccountClosureReasons] = useState({});
  const [closureFees, setClosureFees] = useState([]);
  const [closureType, setClosureType] = useState(1);
  const [closureReason, setClosureReason] = useState(0);
  const { accountId } = useParams();
  const isAccount = props.plate === null;
  const idPrefix = isAccount ? 'CloseAccount' : 'ClosePlate';

  const handleSubmit = (e) => {
    e.preventDefault();
    const accountClosureObj = {
      accountId: parseInt(accountId, 10),
      closeReasonId: closureReason,
      selectedCloseType: closureType,
      accountClosureFeeResponse: {
        accountId: parseInt(accountId, 10),
        closeFees: closureFees.filter((x) => x.closeType === closureType),
      },
    };
    props.accountClosure(accountClosureObj);
    handleClose();
  };

  const handleChangeReasonType = (e) => {
    setClosureType(parseInt(e.target.value, 10));
  };

  const handleChangedReason = (e) => {
    setClosureReason(parseInt(e.target.value, 10));
  };

  const getAccountClosureReasons = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/CloseReason`)
      .then((res) => {
        setAccountClosureReasons(res.data);
      })
      .catch(() => {
        props.presentAlert('error', 'Failed to retrieve close reasons');
      });
  };

  const getAccountClosureResponses = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Account/OptOutCSRCheckForAccountClosure/${accountId}`)
      .then((res) => {
        if (res.data.closeFees.length !== 0) {
          setClosureFees(res.data.closeFees);
          props.setLoading(false);
        } else {
          props.setLoading(false);
          props.presentAlert('error', 'Failed to retrieve closure fees');
        }
      })
      .catch((err) => {
        props.setLoading(false);
        props.presentAlert(
          'error',
          err.response?.status === 400
            ? err.response?.data.message
            : 'Failed to retrieve closure fees'
        );
      });
  };

  const getPlateClosureResponses = () => {
    props.setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Vehicle/${props.plate.vehicleId}/CSROptOutCheckForPlateClosure`
      )
      .then((res) => {
        if (res.data.closeFees.length !== 0) {
          props.setLoading(false);
          setClosureFees(res.data.closeFees);
        } else {
          props.setLoading(false);
          props.presentAlert('error', 'Failed to retrieve closure fees');
        }
      })
      .catch((err) => {
        props.setLoading(false);
        props.presentAlert(
          'error',
          err.response?.status === 400
            ? err.response?.data.message
            : 'Failed to retrieve closure fees'
        );
      });
  };

  const feeRows = Object.entries(closureFees.filter((x) => x.closeType === closureType)).map(
    (fee) => {
      return (
        <TableRow sx={fee[1].mustCloseImmediate === true ? { color: 'red' } : {}}>
          <TableCell>{fee[1].plateNumber.replaceAll(' ', '')}</TableCell>
          <TableCell>{parseFloat(fee[1].capFee.toFixed(2)).toFixed(2)}</TableCell>
          <TableCell>{fee[1].accruedAmount}</TableCell>
          <TableCell>{parseFloat(fee[1].initialTotalOwed).toFixed(2)}</TableCell>
          <TableCell>{convertToDate(fee[1].closeDate)}</TableCell>
        </TableRow>
      );
    }
  );

  const pendingVeh = props.pendingCloseVeh.map((pVeh) => {
    return (
      <li>
        {pVeh.vehicleYear} {pVeh.vehicleMake} {pVeh.vehicleModel} {pVeh.plateNumber}
      </li>
    );
  });

  useEffect(() => {
    if (isAccount) {
      getAccountClosureReasons();
      getAccountClosureResponses();
    } else {
      getPlateClosureResponses();
    }
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Typography component="div">
            You may choose to {isAccount ? 'close your account' : 'remove your vehicle'} immediately
            or at the end of your Mileage Choice period.
          </Typography>
          {closureType === 1 && (
            <Typography component="div">
              When {isAccount ? 'closing your account' : 'removing your vehicle'}, annual user
              fee(s) and/or an update of your odometer reading may apply. Your initial total owed is
              listed below and may be modified if an adjustment is required based on your final
              odometer reading. Your final total owed will be sent via email on the closure date
              below.
            </Typography>
          )}
          {closureType === 2 && (
            <Typography component="div">
              When {isAccount ? 'closing your account' : 'removing your vehicle'}, annual user
              fee(s) and/or an update of your odometer reading may apply. Your initial total owed is
              listed below and may be modified if an adjustment is required based on your final
              odometer reading and/or if additional mileage charges are paid. Your final total owed
              will be sent via email on the closure date below.
            </Typography>
          )}
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl>
                {isAccount ? (
                  <FormLabel id="CloseAccount-label-type">Close Account Type</FormLabel>
                ) : (
                  <FormLabel id="ClosePlate-label-type">Close Plate Type</FormLabel>
                )}
                <RadioGroup
                  row
                  aria-labelledby="immediateclose-radio-buttons-group-label"
                  defaultValue="1"
                  name="closetype-radiobuttons-group"
                >
                  <FormControlLabel
                    value="1"
                    label="Close Immediately"
                    control={<Radio />}
                    onChange={handleChangeReasonType}
                  />
                  <FormControlLabel
                    value="2"
                    label="Close at End of Term"
                    control={<Radio />}
                    disabled={
                      closureFees.filter((x) => x.mustCloseImmediate === false).length === 0
                    }
                    onChange={handleChangeReasonType}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {isAccount && (
              <Grid item xs={6}>
                <Box>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label="Reason"
                    id={`${idPrefix}-select-reason`}
                    value={closureReason}
                    name="reason"
                    onChange={handleChangedReason}
                  >
                    {Object.entries(accountClosureReasons).map((option) => (
                      <MenuItem
                        id={`${idPrefix}-select-reason-${option[1]
                          .replaceAll(' ', '')
                          .toLowerCase()}`}
                        value={option[0]}
                      >
                        {option[1]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>
            )}
            {closureFees.length > 0 && (
              <Fade in timeout={500}>
                <Grid item xs={12}>
                  <TableHead>
                    <TableRow>
                      <TableCell>PLATE</TableCell>
                      <TableCell>ANNUAL USE FEE</TableCell>
                      <TableCell>PAID TO DATE</TableCell>
                      <TableCell>AMOUNT DUE</TableCell>
                      <TableCell>CLOSE DATE</TableCell>
                    </TableRow>
                  </TableHead>
                  {feeRows}
                  <br />
                  {closureType === 1 && props.pendingCloseVeh.length > 0 && (
                    <Alert
                      icon={false}
                      variant="outlined"
                      severity="error"
                      sx={{ color: 'red', m: 1 }}
                    >
                      <Typography component="div">
                        You previously requested that the following{' '}
                        {props.pendingCloseVeh.length > 1 ? 'vehicles' : 'vehicle'}:{' '}
                        <ul>{pendingVeh}</ul>
                        be removed from the Mileage Choice Program at the end of your registration
                        term. If you close your account, these vehicles will be moved to close
                        immediately.
                      </Typography>
                    </Alert>
                  )}
                  {closureFees.filter((x) => x.mustCloseImmediate === true).length > 0 &&
                    closureType === 2 && (
                      <Alert
                        icon={false}
                        variant="outlined"
                        severity="error"
                        sx={{ color: 'red', m: 1 }}
                      >
                        <Typography component="span">
                          {closureFees.filter((x) => x.mustCloseImmediate === true).length === 2
                            ? 'This vehicle'
                            : 'These vehicles'}{' '}
                          cannot be removed at the end of its Mileage Choice period. It will be
                          removed immediately.
                        </Typography>
                      </Alert>
                    )}
                </Grid>
              </Fade>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id={`${idPrefix}-btn-cancel`}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            id={`${idPrefix}-btn-close`}
            disabled={
              closureFees.length === 0 ||
              (props.plate == null && closureReason === 0) ||
              (props.plate != null && closureFees.length === 0)
            }
            type="submit"
          >
            {isAccount ? 'Close Account' : 'Close Plate'}
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

CloseAccountDialogForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  accountClosure: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  plate: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  pendingCloseVeh: PropTypes.arrayOf(
    PropTypes.shape({
      plateNumber: PropTypes.string,
      vehicleId: PropTypes.number,
      state: PropTypes.string,
      country: PropTypes.string,
      vehicleMake: PropTypes.string,
      vehicleModel: PropTypes.string,
      vehicleYear: PropTypes.string,
      dmvRegDate: PropTypes.string,
      visKey: PropTypes.string,
      capFee: PropTypes.string,
      dmvExpDate: PropTypes.string,
      accruedCharges: PropTypes.number,
      vin: PropTypes.string,
      plateStatus: PropTypes.number,
      plateId: PropTypes.number,
      statusDate: PropTypes.string,
      IsEarlyEnrollee: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      pendingCloseDate: PropTypes.string,
    })
  ),
};

CloseAccountDialogForm.defaultProps = {
  plate: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  pendingCloseVeh: [],
};
