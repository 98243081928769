import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from 'react-router-dom';

export default function ErrorDialog(props) {
  const history = useHistory();
  const [open, setOpen] = useState(true);

  return (
    <div>
      <Dialog open={props.open && open} id="Error-dialog">
        <DialogTitle id="Error-dialog-title">Error</DialogTitle>
        {props.description && (
          <DialogContent sx={{ mt: 2 }}>
            <DialogContentText id="Error-dialog-description">{props.description}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            id="Error-btn-ok"
            size="small"
            variant="contained"
            onClick={() => {
              if (props.redirectLink) history.push(props.redirectLink);
              else setOpen(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  redirectLink: PropTypes.string,
};

ErrorDialog.defaultProps = {
  redirectLink: null,
};
