import { TableCell, TableRow } from '@mui/material';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { convertToDateTime } from 'utilities/format';

const DataGridCell = ({ id, width, children, center = false, rowHeight }) => (
  <TableCell
    id={id}
    style={{
      width: `${width}px`,
      justifyContent: !center ? 'left' : 'center',
      display: 'flex',
      alignItems: 'center',
      paddingTop: '0px',
      paddingBottom: '0px',
      overflow: 'hidden',
      height: `${rowHeight}px`,
    }}
  >
    {children}
  </TableCell>
);
const DateCell = ({ id, width, children, rowHeight }) => (
  <DataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? convertToDateTime(children) : '-'}
  </DataGridCell>
);

const CELL_TYPE = {
  dateTimeModified: DateCell,
};

export default function HistorySearchCard(props) {
  const { history, index } = props;

  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, []);

  return (
    <TableRow
      id={`History-tableRow-history-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`History-tableCell-history-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            history={history}
            index={index}
            rowHeight={props.rowHeight}
          >
            {history[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

HistorySearchCard.propTypes = {
  history: PropTypes.shape({
    dateTimeModified: PropTypes.string.isRequired,
    auditTableName: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    originalValue: PropTypes.string.isRequired,
    newValue: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  renderedColumns: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

DataGridCell.defaultProps = {
  center: false,
};

DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
