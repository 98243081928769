import { TableCell, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import CustomWidthTooltip from './CustomWidthToolTip';

export default function CustomTextDataGridCell(props) {
  const ref = React.createRef();
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useLayoutEffect(() => {
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [ref]);

  return (
    <TableCell
      id={props.id}
      ref={ref}
      style={
        !props.center
          ? {
              display: 'block',
              width: `${props.width}px`,
              paddingTop: props.disableOffset ? '0px' : '8px',
              paddingBottom: '0px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              height: `${props.rowHeight}px`,
            }
          : {
              width: `${props.width}px`,
              justifyContent: !props.center ? 'left' : 'center',
              display: 'flex',
              alignItems: 'center',
              paddingTop: '0px',
              paddingBottom: '0px',
              overflow: 'hidden',
              height: `${props.rowHeight}px`,
            }
      }
    >
      {showTooltip ? (
        <CustomWidthTooltip
          title={
            props.large ? <Typography variant="body1">{props.children}</Typography> : props.children
          }
        >
          <span>{props.children}</span>
        </CustomWidthTooltip>
      ) : (
        props.children
      )}
      {props.customButton != null ? props.customButton : ''}
    </TableCell>
  );
}

CustomTextDataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  disableOffset: PropTypes.bool,
  children: PropTypes.node.isRequired,
  customButton: PropTypes.node,
  large: PropTypes.bool,
  center: PropTypes.bool,
};

CustomTextDataGridCell.defaultProps = {
  disableOffset: false,
  customButton: null,
  large: false,
  center: false,
};
