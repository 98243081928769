import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

export default function OrderNewDeviceCard(props) {
  const { vehicle, getProductDesc, orderDevice, handleClose, index } = props;
  const [state] = useState({
    plateId: vehicle.vehicleId,
    productId: vehicle.productId,
  });

  const handleSubmit = (e) => {
    const orderDeviceParams = {
      ...state,
    };
    e.preventDefault();
    orderDevice(orderDeviceParams);
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            disabled
            label="VIN"
            size="small"
            id={`OrderNewDevice-row-${index}-vin`}
            name="vin"
            value={vehicle.vin}
          />
        </Grid>
        <Grid item xs={2.5}>
          <TextField
            required
            fullWidth
            disabled
            label="Plate #"
            size="small"
            id={`OrderNewDevice-row-${index}-plateNumber`}
            name="vin"
            value={vehicle.plateNumber}
          />
        </Grid>
        <Grid item xs={2.5}>
          <TextField
            required
            fullWidth
            disabled
            label="Product Type"
            size="small"
            id={`OrderNewDevice-row-${index}-productDesc`}
            name="vin"
            value={getProductDesc(vehicle.productId)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            id={`OrderNewDevice-row-${index}-orderNewDevice`}
            type="submit"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

OrderNewDeviceCard.propTypes = {
  vehicle: PropTypes.shape({
    vehicleId: PropTypes.number.isRequired,
    productId: PropTypes.number.isRequired,
    plateNumber: PropTypes.string.isRequired,
    vin: PropTypes.string.isRequired,
  }).isRequired,
  orderDevice: PropTypes.func.isRequired,
  getProductDesc: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
