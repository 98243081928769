import DataGridHeader from 'components/Custom/DataGridHeader';

const COLUMNS = [
  {
    field: 'dateTimeModified',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="dateTimeModified" heading="Date Modified" />,
    sortable: true,
  },
  {
    field: 'auditTableName',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="auditTableName" heading="Table Name" />,
    sortable: false,
  },
  {
    field: 'operation',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="operation" heading="Operation" />,
    sortable: false,
  },
  {
    field: 'fieldName',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="fieldName" heading="Field Name" />,
    sortable: false,
  },
  {
    field: 'originalValue',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="originalValue" heading="Original Value" />,
    sortable: false,
  },
  {
    field: 'newValue',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="newValue" heading="New Value" />,
    sortable: false,
  },
  {
    field: 'userName',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="userName" heading="User Name" />,
    sortable: false,
  },
];

export default COLUMNS;
