import { useCallback, useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'utilities/authAxios';
import Stack from '@mui/material/Stack';
import WithLoader from 'components/Custom/WithLoader';
import SearchBar from 'components/Custom/SearchBar';
import DataGridTable, { createQuery } from 'components/Custom/DataGridTable';
import DataGridPagination from 'components/Custom/DataGridPagination';
import useTableState from 'hooks/useTableState';
import TransactionsCard from './TransactionsCard';
import COLUMNS from './TransactionsColumns';

const INITIAL_STATE = {
  pageNumber: 0,
  pageSize: 25,
  sort: { field: 'tollTransactionId', order: 'desc' },
  filter: undefined,
};

function TransactionsPage(props) {
  const { accountId } = useParams();
  const [reasonCodes, setReasonCodes] = useState([]);
  const [isReversed, setIsReversed] = useState(false);

  const queryDevices = useMemo(
    () =>
      createQuery({
        extraParams: { accountId },
        presentAlert: props.presentAlert,
        dismissAlert: props.dismissAlert,
        setLoading: props.setLoading,
        url: `${process.env.REACT_APP_API_URL}/Transaction/GetTransactionsByAccount`,
      }),
    [accountId, isReversed]
  );

  const {
    data,
    totalCount,
    loading,
    onSortChange,
    onFilterChange,
    onPageChange,
    onPageSizeChange,
    onRefresh,
    sort,
    pageNumber,
    pageSize,
  } = useTableState(INITIAL_STATE, queryDevices);

  const clearSearch = useCallback(() => {
    onFilterChange(undefined);
  }, [onFilterChange]);

  const handleSearchSubmit = useCallback(
    (searchBy, searchString = '', startDate = '', endDate = '') => {
      onFilterChange({
        type: searchBy,
        keyword: searchString,
        startDate,
        endDate,
      });
    },
    []
  );

  const getReasonCodes = useCallback(() => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Financial/GetReasonCodes`)
      .then((res) => {
        setReasonCodes(res.data);
        props.setLoading(false);
      })
      .catch(() => {
        props.setLoading(false);
        props.presentAlert('Error', 'Failed to get reason codes');
      });
  }, []);

  const postTransactionReversal = (reversalObj) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Financial/ReverseMileage`, reversalObj)
      .then((res) => {
        if (res.data > 0) {
          setIsReversed(!isReversed);
          props.presentAlert(
            'success',
            `Mileage transaction: ${reversalObj.transactionId} was reversed successfully`
          );
        } else {
          props.setLoading(false);
          props.presentAlert(
            'error',
            `Mileage transaction: ${reversalObj.transactionId} was not reversed`
          );
        }
      })
      .catch(() => {
        props.setLoading(false);
        props.presentAlert(
          'error',
          `Mileage transaction: ${reversalObj.transactionId} was not reversed`
        );
      });
  };

  useEffect(() => {
    getReasonCodes();
  }, []);

  const mileageReversalReasons = reasonCodes?.filter(
    (reasonCode) => reasonCode.formText === 'Mileage Reversal' && reasonCode.finTransTypeId === 5
  );

  const components = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      Row: (rowProps) => (
        <TransactionsCard
          mileageReversalReasons={mileageReversalReasons}
          postTransactionReversal={postTransactionReversal}
          accountId={accountId}
          presentAlert={props.presentAlert}
          key={rowProps.row.accountTagId}
          transaction={rowProps.row}
          index={rowProps.index}
          renderedColumns={rowProps.renderedColumns}
          rowHeight={rowProps.rowHeight}
        />
      ),
      // eslint-disable-next-line
      Pagination: (props) => <DataGridPagination {...props} onRefresh={onRefresh} />,
    }),
    [accountId, props.presentAlert, mileageReversalReasons]
  );

  return (
    <div>
      {!props.loading && (
        <Stack direction="column" spacing={2}>
          <SearchBar
            parentId="Transaction"
            handleSearchSubmit={handleSearchSubmit}
            clearSearch={clearSearch}
            searchTypes={['Plate', 'Post Date', 'Trans Date', 'VIN']}
          />
          <DataGridTable
            columns={COLUMNS}
            loading={loading}
            data={data}
            overridingComponent={components}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRefresh={onRefresh}
            totalCount={totalCount}
            sort={sort}
            onSortModelChange={onSortChange}
          />
        </Stack>
      )}
    </div>
  );
}

TransactionsPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
};

export default WithLoader(TransactionsPage);
