import { useState, useEffect } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import WithLoader from 'components/Custom/WithLoader';
import axios from 'utilities/authAxios';
import PropTypes from 'prop-types';
import ACHDetailsCard from './ACHDetailsCard';

function ACHDetailsGrid(props) {
  const { accountId } = useParams();
  const [achDetails, setAchDetails] = useState([]);
  const [achStatusDesc, setACHStatusDesc] = useState({});

  const getACHStatusLookup = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/ACHStatus`)
      .then((res) => {
        setACHStatusDesc(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getACHStatusDesc = (achStatusId) => {
    return achStatusDesc[achStatusId.toString()];
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Payment/GetAchForAccount/${accountId}`)
      .then((res) => {
        setAchDetails(res.data);
        props.setLoading(false);
        getACHStatusLookup();
      })
      .catch((err) => {
        console.log(err);
        props.presentError('Server could not be reached. Please refresh the page.');
      });
  }, [accountId]);

  const achDetailsMapper = achDetails.map((ach, index) => {
    return (
      <ACHDetailsCard
        key={ach.ACHId}
        achDetails={ach}
        index={index}
        getACHStatusDesc={getACHStatusDesc}
      />
    );
  });

  return (
    <div>
      {!props.loading && (
        <TableContainer component={Paper}>
          <Table size="small" id="PaymentMethods-table-achDetails">
            <TableHead>
              <TableRow>
                <TableCell>Account Holder Name</TableCell>
                <TableCell>Display ACH Info</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>ACH Added on</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{achDetailsMapper}</TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

ACHDetailsGrid.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentError: PropTypes.func.isRequired,
};

export default WithLoader(ACHDetailsGrid);
