import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteConfirmationDialog from 'components/Custom/DeleteConfirmationDialog';
import FormDialog from 'components/Custom/FormDialog';
import { useRecoilValue } from 'recoil';
import { demographicRight } from 'utilities/userRole';
import UpdateContactDialogForm from './UpdateContactDialogForm';

export default function ContactsCard(props) {
  const { contact, index } = props;
  const fullName = `${contact.firstName} ${contact.lastName}`;
  const hasDemographicRight = useRecoilValue(demographicRight);

  return (
    <TableRow id={`Contacts-tableRow-contact-${index}`}>
      <TableCell id={`Contacts-tableCell-contact-${index}-isPrimary`}>
        {contact.isPrimary ? 'Primary' : 'None'}
      </TableCell>
      <TableCell id={`Contacts-tableCell-contact-${index}-fullName`}>{fullName}</TableCell>
      <TableCell id={`Contacts-tableCell-contact-${index}-email`}>{contact.email}</TableCell>
      <TableCell id={`Contacts-tableCell-contact-${index}-primaryPhone`}>
        {contact.primaryPhone}
      </TableCell>
      <TableCell id={`Contacts-tableCell-contact-${index}-ivrPin`}>{contact.ivrPin}</TableCell>
      {hasDemographicRight && (
        <TableCell
          padding="none"
          align="center"
          id={`Contacts-tableCell-contact-${index}-updateContact`}
        >
          <FormDialog formId="UpdateContact" buttonIcon={<EditIcon />} dialogTitle="Update Contact">
            <UpdateContactDialogForm contactObj={contact} updateContact={props.updateContact} />
          </FormDialog>
        </TableCell>
      )}
      {hasDemographicRight && (
        <TableCell padding="none" align="center">
          <DeleteConfirmationDialog
            buttonId={`Contacts-tableCell-contact-${index}-deleteContact`}
            dialogTitle="Remove Contact"
            title={`Remove Contact - ${fullName}`}
            description="Are you sure you want to remove this contact?"
            handleConfirmation={(success) => {
              if (success) {
                if (contact.isPrimary === true) {
                  props.presentAlert('error', 'Cannot remove primary contact');
                } else {
                  props.removeContact(contact.contactId);
                }
              }
            }}
          />
        </TableCell>
      )}
    </TableRow>
  );
}

ContactsCard.propTypes = {
  contact: PropTypes.shape({
    contactId: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    primaryPhone: PropTypes.string.isRequired,
    isPrimary: PropTypes.bool.isRequired,
    ivrPin: PropTypes.string.isRequired,
  }).isRequired,
  updateContact: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  removeContact: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
};
