import { cloneElement, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';

export default function DeleteConfirmationDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const customButton = () => {
    if (props.customButton !== null) {
      return cloneElement(props.customButton, {
        onClick: handleClickOpen,
      });
    }
    return null;
  };

  return (
    <div>
      {props.customButton !== null ? (
        customButton()
      ) : (
        <Tooltip title={props.dialogTitle}>
          <IconButton
            color="inherit"
            aria-label="delete"
            onClick={handleClickOpen}
            id={props.buttonId}
            style={{ color: 'black' }}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      <Dialog open={open} onClose={handleClose} id="Confirmation-dialog">
        <DialogTitle id="Confirmation-dialog-title">{props.title}</DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText id="Confirmation-dialog-description">
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="Confirmation-btn-no"
            size="small"
            variant="outlined"
            onClick={() => {
              props.handleConfirmation(false);
              handleClose();
            }}
          >
            No
          </Button>
          <Button
            id="Confirmation-btn-yes"
            size="small"
            variant="contained"
            onClick={() => {
              props.handleConfirmation(true);
              handleClose();
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  customButton: PropTypes.node,
  buttonId: PropTypes.string,
  dialogTitle: PropTypes.string.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
};

DeleteConfirmationDialog.defaultProps = {
  customButton: null,
  buttonId: null,
};
