import { useState, useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ForwardIcon from '@mui/icons-material/Forward';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import axios from 'utilities/authAxios';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';

export default function ChangeDeviceTypeDialogForm(props) {
  const { handleClose, getProductDesc, device, productDescs, changeDeviceType, setLoading } = props;
  const [disableLocationCheckBox, setDisableLocationCheckBox] = useState(false);
  const [state, setState] = useState({
    accountTagId: device.accountTagId,
    isLocationTracked: device.isLocationTracked,
  });

  function getProductIdByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }
  const newProductId =
    device.productDesc === 'OBD'
      ? getProductIdByValue(productDescs, 'Telematic')
      : getProductIdByValue(productDescs, 'OBD');

  const [notCompatible, setNotCompatible] = useState(true);

  const eligibleDevices = (vin) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Device/DeviceCompatibilityCheck?vin=${vin}`)
      .then((res) => {
        setLoading(false);
        if (res.data.some((x) => x.productId === parseInt(newProductId, 10))) {
          props.presentAlert('success', 'Vehicle is compatible with new device type');
          setNotCompatible(false);
          if (
            !res.data.some(
              (x) => x.productId === parseInt(newProductId, 10) && x.hasLocation === true
            )
          ) {
            setDisableLocationCheckBox(true);
            setState({ ...state, isLocationTracked: false });
          }
        } else {
          setDisableLocationCheckBox(true);
          props.presentAlert('error', 'Vehicle is not compatible with new device type');
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setDisableLocationCheckBox(true);
        props.presentAlert('error', 'Vehicle is not compatible with new device type');
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const changeDeviceRequest = {
      ...state,
    };
    changeDeviceType(changeDeviceRequest, device.vin);
    handleClose();
  };

  useEffect(() => {
    setLoading(true);
    eligibleDevices(device.vin);
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <TextField
                fullWidth
                required
                disabled
                size="small"
                variant="outlined"
                id="ChangeDeviceType-textField-oldProductDesc"
                value={getProductDesc(device.productId)}
              />
            </Grid>
            <Grid item xs={2} style={{ alignSelf: 'center', textAlign: 'center' }}>
              <ForwardIcon style={{ fontSize: '30px' }} />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                required
                disabled
                size="small"
                variant="outlined"
                id="ChangeDeviceType-textField-newProductDesc"
                value={getProductDesc(newProductId)}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormControlLabel
                  value="start"
                  disabled={disableLocationCheckBox}
                  control={
                    <Checkbox
                      color="success"
                      id="ChangeDeviceType-checkbox-isLocationTracked"
                      checked={state.isLocationTracked && !disableLocationCheckBox}
                      onChange={(e) => setState({ ...state, isLocationTracked: e.target.checked })}
                    />
                  }
                  label="Track Location"
                  labelPlacement="end"
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="ChangeDeviceType-btn-cancel"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            id="ChangeDeviceType-btn-submit"
            type="submit"
            disabled={notCompatible}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

ChangeDeviceTypeDialogForm.propTypes = {
  device: PropTypes.shape({
    productId: PropTypes.number.isRequired,
    accountTagId: PropTypes.number.isRequired,
    productDesc: PropTypes.string.isRequired,
    isLocationTracked: PropTypes.bool.isRequired,
    vin: PropTypes.string.isRequired,
  }).isRequired,
  presentAlert: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  getProductDesc: PropTypes.func.isRequired,
  changeDeviceType: PropTypes.func.isRequired,
  productDescs: PropTypes.objectOf(PropTypes.string).isRequired,
  setLoading: PropTypes.func.isRequired,
};
