/* eslint-disable no-param-reassign */
import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { msalConfig, silentRequest } from 'components/AzureAD/authConfig';

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_PATH,
  headers: {
    'Content-Type': 'application/json',
  },
};

const instance = axios.create(defaultOptions);

const msalApp = new PublicClientApplication(msalConfig);

// Set the AUTH token for any request
instance.interceptors.request.use(async (config) => {
  silentRequest.account = msalApp.getAllAccounts()[0];
  try {
    const response = await msalApp.acquireTokenSilent(silentRequest);
    const accessToken = response.accessToken;
    // Call your API with token
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
    return config;
  } catch {
    return config;
  }
});

export default instance;
