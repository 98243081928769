import DataGridPagination from 'components/Custom/DataGridPagination';
import DataGridTable, { createQuery } from 'components/Custom/DataGridTable';
import useTableState from 'hooks/useTableState';
import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'utilities/authAxios';
import PropTypes from 'prop-types';
import WithLoader from 'components/Custom/WithLoader';
import { useParams } from 'react-router-dom';
import { Button, Grid, MenuItem, Stack, TextField } from '@mui/material';
import HistorySearchCard from './HistorySearchCard';
import COLUMNS from './HistorySearchColumns';

const INITIAL_STATE = {
  pageNumber: 0,
  pageSize: 25,
  sort: { field: 'dateTimeModified', order: 'desc' },
  filter: undefined,
};

function HistorySearchPage(props) {
  const { accountId } = useParams();
  const [fieldNameData, setFieldNameData] = useState([]);
  const [historyFieldNames, setHistoryFieldNames] = useState({});
  const [selectedAuditTableName, setSelectedAuditTableName] = useState(null);
  const [selectedFieldName, setSelectedFieldName] = useState(null);
  const queryHistory = useMemo(
    () =>
      createQuery({
        extraParams: { accountId },
        presentAlert: props.presentAlert,
        dismissAlert: props.dismissAlert,
        setLoading: props.setLoading,
        url: `${process.env.REACT_APP_API_URL}/Account/History`,
      }),
    [accountId]
  );

  const {
    data,
    totalCount,
    loading,
    onSortChange,
    onFilterChange,
    onPageChange,
    onPageSizeChange,
    onRefresh,
    sort,
    pageNumber,
    pageSize,
  } = useTableState(INITIAL_STATE, queryHistory);

  const clearSearch = useCallback(() => {
    onFilterChange(undefined);
  }, [onFilterChange]);

  const clearSelectedValue = () => {
    setSelectedFieldName(null);
    setSelectedAuditTableName(null);
    clearSearch();
  };

  const handleSearchSubmit = useCallback(
    (searchBy = selectedAuditTableName, searchString = selectedFieldName) => {
      onFilterChange({
        type: searchBy,
        keyword: searchString,
      });
    },
    [selectedFieldName, selectedAuditTableName]
  );

  const validate = (e) => {
    e.preventDefault();
    handleSearchSubmit();
  };

  const components = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      Row: (rowProps) => (
        <HistorySearchCard
          key={rowProps.row.noteId}
          history={rowProps.row}
          index={rowProps.index}
          presentAlert={props.presentAlert}
          dismissAlert={props.dismissAlert}
          renderedColumns={rowProps.renderedColumns}
          rowHeight={rowProps.rowHeight}
        />
      ),
      // eslint-disable-next-line
      Pagination: (props) => <DataGridPagination {...props} onRefresh={onRefresh} />,
    }),
    [accountId, props.presentAlert]
  );

  const getDropDownData = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Account/History/FieldNames?accountId=${accountId}`)
      .then((res) => {
        setHistoryFieldNames(res.data);
        props.setLoading(false);
      })
      .catch(() => {
        props.setLoading(false);
        props.presentAlert('error', 'Failed to retrieve field names');
      });
  };

  const handleChangedAuditTableName = (e) => {
    setSelectedAuditTableName(e.target.value);
    setFieldNameData(historyFieldNames[e.target.value]);
    setSelectedFieldName(null);
  };

  const handleChangedFieldName = (e) => {
    setSelectedFieldName(e.target.value);
  };

  useEffect(() => {
    if (Object.keys(historyFieldNames).length === 0) {
      getDropDownData();
    }
  }, [historyFieldNames]);

  return (
    <div>
      {!props.loading && (
        <Stack direction="column" spacing={2}>
          <form onSubmit={validate}>
            <Grid container spacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} sx={{ mb: 1 }}>
              <Grid item>
                <TextField
                  select
                  required
                  name="searchType"
                  id="HistorySearchPage-select-TableName"
                  value={selectedAuditTableName}
                  label="Table Name"
                  size="small"
                  sx={{ minWidth: 180 }}
                  onChange={handleChangedAuditTableName}
                >
                  {Object.entries(historyFieldNames).map(([key]) => (
                    <MenuItem
                      key={key}
                      value={key}
                      id={`HistorySearchPage-select-tableName-${key}`}
                    >
                      {key}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {selectedAuditTableName !== null && (
                <Grid item>
                  <TextField
                    select
                    name="searchType"
                    id="HistorySearchPage-select-FieldName"
                    value={selectedFieldName}
                    label="Field Name"
                    size="small"
                    sx={{ minWidth: 180 }}
                    onChange={handleChangedFieldName}
                  >
                    {fieldNameData.map((key) => (
                      <MenuItem
                        key={key}
                        value={key}
                        id={`HistorySearchPage-select-fieldName-${key}`}
                      >
                        {key}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="contained"
                  id="HistorySearchPage-btn-submitSearch"
                  size="medium"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  id="HistorySearchPage-btn-clearSearch"
                  size="medium"
                  onClick={clearSelectedValue}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </form>
          <DataGridTable
            columns={COLUMNS}
            loading={loading}
            data={data}
            overridingComponent={components}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRefresh={onRefresh}
            totalCount={totalCount}
            sort={sort}
            onSortModelChange={onSortChange}
          />
        </Stack>
      )}
    </div>
  );
}

HistorySearchPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
};

export default WithLoader(HistorySearchPage);
