export default function buildURLParams(inputParams) {
  const paramList = [];
  Object.entries(inputParams).forEach((entry) => {
    const [key, value] = entry;
    if (value !== '') {
      paramList.push([key, value]);
    }
  });
  const params = new URLSearchParams(paramList);
  return params;
}
