import { IconButton, InputBase, Paper, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { isGlobalSearchAtom, searchTermAtom } from 'utilities/State';

export default function GlobalSearch() {
  const setSearchTermAtom = useSetRecoilState(searchTermAtom);
  const setGlobalSearchAtom = useSetRecoilState(isGlobalSearchAtom);
  const history = useHistory();
  const [search, setSearch] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (search.trim().length > 0) {
      setSearch(search.trim());
      setSearchTermAtom(search.trim());
      setGlobalSearchAtom(true);
      history.push('/');
    }
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: { md: 250, lg: 400 },
        }}
      >
        <InputBase
          autoFocus
          sx={{ ml: 1, flex: 1 }}
          placeholder="OmniSearch"
          value={search}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'OmniSearch' }}
          id="OmniSearch-textField-search"
        />
        <Tooltip title="OmniSearch">
          <IconButton
            type="submit"
            sx={{ p: '10px' }}
            aria-label="search"
            id="OmniSearch-btn-search"
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </Paper>
    </form>
  );
}
