import { useState } from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import axios from 'utilities/authAxios';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { convertToDateMMddyyyy } from 'utilities/format';

export default function AddVehicleDialogForm(props) {
  const { accountId } = props;
  const [compatibleDevices, setCompatibleDevices] = useState([]);
  const [state, setState] = useState({
    lookupSuccess: false,
    accountId,
    customerNo: '',
    vin4: '',
    vin: '',
    visKey: '',
    vehicleMake: '',
    vehicleModel: '',
    vehicleYear: '',
    plateNumber: '',
    productId: null,
    dmvRegDate: '',
    dmvExpDate: '',
    capFee: null,
    mpg: null,
    fuelType: '',
    perMileFee: null,
    isLocationTracked: true,
  });

  const eligibleDevices = (vin) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Device/DeviceCompatibilityCheck?vin=${vin}&location=${state.isLocationTracked}`
      )
      .then((res) => {
        if (res.data.length === 0) {
          props.setLoading(false);
          props.presentAlert('error', 'This vehicle is not supported at this time');
        } else {
          setCompatibleDevices(
            res.data?.filter((deviceObj) => deviceObj.hasLocation === state.isLocationTracked)
          );
          props.setLoading(false);
          props.presentAlert('success', 'Vehicle is qualified for the program');
        }
      })
      .catch(() => {
        props.setLoading(false);
        props.presentAlert('error', 'Failed to get eligible device types');
      });
  };

  const getDmvLookupResults = () => {
    state.vin4 = state.vin4.toUpperCase().trim();

    if (!state.vin4.length || !state.visKey.length || !state.customerNo) {
      props.presentAlert('error', 'Title number, VIN4 and Customer number cannot be empty');
    } else {
      props.setLoading(true);
      const lookupRequest = {
        vin4: state.vin4,
        customerNo: state.customerNo,
        titleNo: state.visKey,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/Vehicle/GetDMVLookupResults?accountId=${accountId}`,
          lookupRequest
        )
        .then((res) => {
          if (res.data.isQualified === false) {
            props.setLoading(false);
            setState({
              ...state,
              lookupSuccess: false,
            });
            props.presentAlert('error', res.data.errorMessage);
          } else {
            eligibleDevices(res.data.vin);
            setState({
              ...state,
              lookupSuccess: true,
              vehicleModel: res.data.vehicleModel,
              vin: res.data.vin,
              vehicleMake: res.data.vehicleMake,
              vehicleYear: res.data.vehicleYear,
              plateNumber: res.data.plateNumber,
              dmvRegDate: res.data.dmvRegDate,
              dmvExpDate: res.data.dmvExpDate,
              capFee: res.data.capFee,
              mpg: res.data.mpg,
              fuelType: res.data.fuelType,
              perMileFee: res.data.perMileFee,
            });
          }
        })
        .catch((err) => {
          props.setLoading(false);
          props.presentAlert('error', 'DMV Lookup failed');
          console.log(err);
        });
    }
  };

  const compatibleDevicesDropdown = compatibleDevices.map((entry) => {
    return (
      <MenuItem
        key={entry.productId}
        value={entry.productId}
        id={`Addvehicle-select-compatibleDevices-${entry.productType}`}
      >
        {entry.productType}
      </MenuItem>
    );
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.dismissAlert();
    props.dismissVehiclePageAlert();
    const newVehicle = {
      ...state,
    };
    props.addVehicle(newVehicle);
    props.handleClose();
  };

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                label="Title #"
                size="small"
                id="AddVehicle-textField-visKey"
                name="visKey"
                value={state.visKey}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                required
                select
                disabled={Object.keys(compatibleDevices).length === 0}
                size="small"
                label="Product"
                variant="outlined"
                id="AddVehicle-textField-productId"
                name="productId"
                value={state.productId}
                onChange={handleChange}
              >
                {compatibleDevicesDropdown}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <FormControl>
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      color="success"
                      id="AddVehicle-checkbox-isLocationTracked"
                      checked={state.isLocationTracked}
                      onChange={(e) => setState({ ...state, isLocationTracked: e.target.checked })}
                    />
                  }
                  label="Location Tracked"
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                label="VIN4"
                size="small"
                id="AddVehicle-textField-vin4"
                name="vin4"
                onInput={(e) => {
                  // @ts-ignore
                  e.target.value = e.target.value.slice(0, 4);
                }}
                value={state.vin4}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                label="Customer #"
                size="small"
                id="AddVehicle-textField-customerNo"
                name="customerNo"
                value={state.customerNo}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={getDmvLookupResults}
                id="AddVehicle-btn-dmvLookup"
              >
                DMV Lookup
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                disabled
                label="Vehicle Make"
                size="small"
                id="AddVehicle-textField-vehicleMake"
                value={state.vehicleMake}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                disabled
                label="Vehicle Model"
                size="small"
                variant="outlined"
                id="AddVehicle-textField-vehicleModel"
                value={state.vehicleModel}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="Vehicle Year"
                variant="outlined"
                id="AddVehicle-textField-vehicleYear"
                value={state.vehicleYear}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="VIN #"
                variant="outlined"
                id="AddVehicle-textField-vin"
                value={state.vin}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="Plate #"
                variant="outlined"
                id="AddVehicle-textField-plateNumber"
                value={state.plateNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="Registration Date"
                variant="outlined"
                id="AddVehicle-textField-dmvRegDate"
                value={state.dmvRegDate ? convertToDateMMddyyyy(state.dmvRegDate) : ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="Expiration Date"
                variant="outlined"
                id="AddVehicle-textField-dmvExpDate"
                value={state.dmvExpDate ? convertToDateMMddyyyy(state.dmvExpDate) : ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="AddVehicle-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={!state.lookupSuccess || state.productId === null}
            variant="contained"
            size="small"
            id="AddVehicle-btn-createVehicle"
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

AddVehicleDialogForm.propTypes = {
  accountData: PropTypes.shape({
    accountId: PropTypes.number.isRequired,
  }).isRequired,
  addVehicle: PropTypes.func.isRequired,
  dismissVehiclePageAlert: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};
