import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { useParams, useHistory, useLocation } from 'react-router-dom';

export default function CorrespondenceView() {
  const history = useHistory();
  const location = useLocation();
  const { previousTabIndex, correspondenceData } = location.state;
  const { accountId } = useParams();

  const handleBackClick = () => {
    history.push({
      pathname: `/account-management/${accountId}`,
      state: { tabIndex: previousTabIndex },
    });
  };

  return (
    <div style={{ padding: 8 }}>
      <button type="submit" onClick={handleBackClick}>
        Back
      </button>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(correspondenceData),
        }}
      />
    </div>
  );
}

CorrespondenceView.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      correspondenceData: PropTypes.string.isRequired,
      previousTabIndex: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
