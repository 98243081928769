import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function AssignMroForm(props) {
  const { state, handleChange, continues, disabledInput } = props;

  return (
    <Card sx={{ mt: 2, boxShadow: 5 }}>
      <Typography variant="h4">Assign MRO Serial Number</Typography>
      <Typography variant="h5">{state.fullName}</Typography>
      <Typography variant="body1">Account Tag: {state.accountTagId}</Typography>
      <Box sx={{ '& > :not(style)': { m: 1 }, marginTop: '16px' }} component="form">
        <FormControl variant="standard" sx={{ width: '40%' }}>
          <TextField
            required
            disabled={disabledInput}
            label="MRO Serial Number"
            size="small"
            variant="outlined"
            id="AssignMRO-textField-tagSerialNumber"
            name="tagSerialNumber"
            value={state.tagSerialNumber}
            onChange={handleChange}
          />
        </FormControl>
        <br />
        <FormControl variant="standard" sx={{ width: '40%' }}>
          <TextField
            disabled
            label="License Plate Number"
            size="small"
            variant="outlined"
            id="AssignMRO-textField-licPlateNo"
            name="licPlateNo"
            value={state.licPlateNo}
            onChange={handleChange}
          />
        </FormControl>
        <br />
        <FormControl variant="standard" sx={{ width: '40%' }}>
          <TextField
            disabled
            label="Vehicle Identification Number"
            size="small"
            variant="outlined"
            id="AssignMRO-textField-vin"
            name="vin"
            value={state.vin}
            onChange={handleChange}
          />
        </FormControl>
        <br />
        {state.tagSerialNumber?.length === 0 ? (
          ''
        ) : (
          <Button variant="contained" onClick={continues} size="small" id="AssignMRO-btn-assignMro">
            Next
          </Button>
        )}
      </Box>
    </Card>
  );
}
AssignMroForm.propTypes = {
  state: PropTypes.shape({
    accountTagId: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    tagSerialNumber: PropTypes.string.isRequired,
    vin: PropTypes.string.isRequired,
    licPlateNo: PropTypes.string.isRequired,
  }).isRequired,
  disabledInput: PropTypes.bool.isRequired,
  continues: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};
