import { useState } from 'react';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import FormDialog from 'components/Custom/FormDialog';
import { useRecoilState, useRecoilValue } from 'recoil';
import { refreshAccountInfo } from 'utilities/State';
import { creditAdjustmentRight, processPaymentRight, refundRight } from 'utilities/userRole';
import PostRefundDialogForm from './PostRefundDialogForm';
import PostAccountFeeDialogForm from './PostAccountFeeDialogForm';
import OneTimePaymentDialogForm from './OneTimePaymentDialogForm';
import PostAdjustmentDialogForm from './PostAdjustmentDialogForm';

export default function AccountToolMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [refresh, toggleRefresh] = useRecoilState(refreshAccountInfo);
  const hasCreditAdjustmentRight = useRecoilValue(creditAdjustmentRight);
  const hasRefundRight = useRecoilValue(refundRight);
  const hasProcessPaymentRight = useRecoilValue(processPaymentRight);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const reloadAccountInfo = () => {
    toggleRefresh(!refresh);
  };

  return (
    <div>
      {(hasCreditAdjustmentRight || hasProcessPaymentRight || hasRefundRight) && (
        <div>
          <Button
            id="AccountInfo-btn-accountTools"
            aria-controls={open ? 'AccountTools-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Account Tools
          </Button>
          <Menu
            id="AccountInfo-menu-accountTools"
            MenuListProps={{
              'aria-labelledby': 'AccountTools-menu-list',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {hasProcessPaymentRight && (
              <FormDialog
                formId="OneTimePayment"
                customButton={
                  <MenuItem id="AccountToolMenu-btn-oneTimePayment">One-Time Payment</MenuItem>
                }
                handleParentClose={handleClose}
                dialogTitle="One-Time Payment"
              >
                <OneTimePaymentDialogForm
                  presentParentAlert={props.presentAlert}
                  reloadAccountInfo={reloadAccountInfo}
                />
              </FormDialog>
            )}
            {hasRefundRight && (
              <FormDialog
                formId="PostRefund"
                customButton={
                  <MenuItem id="AccountToolMenu-btn-postRefund">One-Time Refund</MenuItem>
                }
                handleParentClose={handleClose}
                dialogTitle="One-Time Refund"
              >
                <PostRefundDialogForm
                  presentParentAlert={props.presentAlert}
                  reloadAccountInfo={reloadAccountInfo}
                />
              </FormDialog>
            )}
            {(hasCreditAdjustmentRight || hasProcessPaymentRight) && (
              <FormDialog
                formId="PostAccountFee"
                customButton={
                  <MenuItem id="AccountToolMenu-btn-postAccountFee">Account Fee</MenuItem>
                }
                handleParentClose={handleClose}
                dialogTitle="Account Fee"
              >
                <PostAccountFeeDialogForm
                  presentParentAlert={props.presentAlert}
                  reloadAccountInfo={reloadAccountInfo}
                />
              </FormDialog>
            )}
            {hasCreditAdjustmentRight && (
              <FormDialog
                formId="PostAdjustment"
                customButton={
                  <MenuItem id="AccountToolMenu-btn-postAdjustment">Adjustment</MenuItem>
                }
                handleParentClose={handleClose}
                dialogTitle="Adjustment"
              >
                <PostAdjustmentDialogForm
                  presentParentAlert={props.presentAlert}
                  reloadAccountInfo={reloadAccountInfo}
                />
              </FormDialog>
            )}
          </Menu>
        </div>
      )}
    </div>
  );
}

AccountToolMenu.propTypes = {
  presentAlert: PropTypes.func.isRequired,
};
