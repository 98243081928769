import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import axios from 'utilities/authAxios';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { creditCardValidation } from 'utilities/PaymentValidation';

export default function UpdateCardDialogForm(props) {
  const [state, setState] = useState({
    cardId: props.paymentCards.cardId,
    cardNumber: props.paymentCards.cardNum,
    expiryYear: props.paymentCards.expYear.toString(),
    expiryMonth: props.paymentCards.expMonth.toString(),
    nameOnCard: props.paymentCards.nameOnCard,
    cardTypeId: props.paymentCards.cardTypeId,
    postalCode: props.paymentCards.postalCode,
    created: props.paymentCards.created,
  });

  const [address, setAddresses] = useState([]);
  const [expirationError, setExpirationError] = useState({ helperText: '', hasError: false });

  const { accountId } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();

    const creditCardObj = {
      expiryYear: state.expiryYear,
      expiryMonth: state.expiryMonth,
    };
    const creditCardValidationResult = creditCardValidation(creditCardObj);

    Object.entries(creditCardValidationResult).forEach(([key, value]) => {
      if (key === 'expirationError' && value !== '') {
        setExpirationError({ helperText: value, hasError: true });
      }
      if (key === 'expirationError' && value === '') {
        setExpirationError({ helperText: value, hasError: false });
      }
    });

    if (creditCardValidationResult.expirationError !== '') {
      return;
    }

    const updatedCard = {
      ...state,
      accountId: `${accountId}`,
      address: address[0].addressLine1,
      city: address[0].city,
      stateProvince: address[0].stateProvinceCode,
      postalCode: address[0].postalCode,
    };
    props.updateCard(updatedCard);
    props.handleClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const cardTypesMenuItems = Object.entries(props?.creditCardTypes).map((entry) => {
    return (
      <MenuItem
        key={entry[0]}
        value={entry[0]}
        id={`UpdateCard-select-creditCardTypeValue-${entry[1]}`}
      >
        {entry[1]}
      </MenuItem>
    );
  });

  const getAddress = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Account/GetAddresses/${accountId}`)
      .then((res) => {
        const newAddresses = [{ ...res.data[0] }];
        setAddresses(newAddresses);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAddress();
  }, [accountId]);

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                size="small"
                label="Card Holder's Name"
                id="UpdateCard-textField-nameOnCard"
                name="nameOnCard"
                value={state.nameOnCard}
                onChange={handleChange}
              />
            </Grid>
            <Box width="100%" />
            <Grid item xs={6}>
              <TextField
                select
                disabled
                fullWidth
                size="small"
                label="Card Type"
                id="UpdateCard-select-cardType"
                name="cardType"
                value={state.cardTypeId}
                onChange={handleChange}
              >
                {cardTypesMenuItems}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                size="small"
                label="Card Number"
                id="UpdateCard-textField-cardNumber"
                name="cardNumber"
                value={state.cardNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="Expiration Month"
                id="UpdateCard-textField-expiryMonth"
                name="expiryMonth"
                helperText={expirationError.helperText}
                error={expirationError.hasError}
                value={state.expiryMonth}
                onInput={(e) => {
                  // @ts-ignore
                  e.target.value = e.target.value.slice(0, 2);
                }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="Expiration Year"
                id="UpdateCard-textField-expiryYear"
                name="expiryYear"
                helperText={expirationError.helperText}
                error={expirationError.hasError}
                value={state.expiryYear}
                onInput={(e) => {
                  // @ts-ignore
                  e.target.value = e.target.value.slice(0, 4);
                }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                size="small"
                label="Postal Code"
                id="UpdateCard-textField-postalCode"
                value={state.postalCode}
                name="postalCode"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="UpdateAddress-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            id="UpdateAddress-btn-updateAddress"
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

UpdateCardDialogForm.propTypes = {
  paymentCards: PropTypes.shape({
    cardId: PropTypes.string.isRequired,
    cardNum: PropTypes.string.isRequired,
    expYear: PropTypes.string.isRequired,
    expMonth: PropTypes.string.isRequired,
    nameOnCard: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    cardTypeId: PropTypes.string.isRequired,
  }).isRequired,
  updateCard: PropTypes.func.isRequired,
  creditCardTypes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleClose: PropTypes.func.isRequired,
};
