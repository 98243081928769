import DataGridHeader from 'components/Custom/DataGridHeader';

const COLUMNS = [
  {
    field: 'accountNumber',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="accountNoId" heading="Account #" />,
    sortable: false,
  },
  {
    field: 'fullName',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="fullNameId" heading="Name" />,
    sortable: false,
  },
  {
    field: 'acctStatusDesc',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="acctStatusId" heading="Status" />,
    sortable: false,
  },
  {
    field: 'tagStatusDesc',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="tagStatusId" heading="MRO Status" />,
    sortable: false,
  },
  {
    field: 'productDesc',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="productDescId" heading="Product" />,
    sortable: false,
  },
  {
    field: 'vehicleModel',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="vehicleModelId" heading="Model" />,
    sortable: false,
  },
  {
    field: 'dayTimePhone',
    flex: 1.5,
    renderHeader: () => <DataGridHeader columnId="phoneNoId" heading="Phone #" />,
    sortable: false,
  },
  {
    field: 'salesOrderStatusDesc',
    flex: 1.2,
    renderHeader: () => <DataGridHeader columnId="orderStatusId" heading="Order Status" />,
    sortable: false,
  },
  {
    field: 'shipDt',
    flex: 1.5,
    renderHeader: () => <DataGridHeader columnId="orderDateId" heading="Order Date" />,
  },
  {
    field: 'vin',
    flex: 2.5,
    renderHeader: () => <DataGridHeader columnId="vinId" heading="VIN" />,
    sortable: false,
  },
  {
    field: 'plateNumber',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="plateNoId" heading="Plate #" />,
    sortable: false,
  },
  {
    field: 'tagSerialNumber',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="tagSerialNoId" heading="MRO #" />,
    sortable: false,
  },
  {
    field: 'trackingNumber',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="trackingNoId" heading="Tracking #" />,
    sortable: false,
  },
  {
    field: 'action',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="actionId" heading="Action" />,
    sortable: false,
  },
];

export default COLUMNS;
