import { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@mui/material';
import { convertToDateMMddyyyy } from 'utilities/format';
import DescriptionDialog from 'components/Custom/DescriptionDialog';

export default function CaseCommunicationCard(props) {
  const { caseCommunication, index, emailAddress } = props;

  const [open, setOpen] = useState(false);
  const handleDoubleClick = () => {
    setOpen(!open);
  };

  return (
    <TableRow
      id={`CaseCommunications-tableRow-caseCommunication-${index}`}
      onDoubleClick={handleDoubleClick}
    >
      <TableCell
        style={{ width: '100px' }}
        id={`CaseCommunications-tableCell-caseCommunication-${index}-created`}
      >
        {convertToDateMMddyyyy(caseCommunication.created)}
      </TableCell>
      <TableCell id={`CaseCommunications-tableCell-caseCommunication-${index}-from`}>
        {emailAddress}
      </TableCell>
      <TableCell id={`CaseCommunications-tableCell-caseCommunication-${index}-subject`}>
        {caseCommunication.caseType}
      </TableCell>
      <TableCell id={`CaseCommunications-tableCell-caseCommunication-${index}-message`}>
        {caseCommunication.description && caseCommunication.description.length > 100 ? (
          <Tooltip title={<Typography variant="body1">{caseCommunication.description}</Typography>}>
            <div>{`${caseCommunication.description.substr(0, 100)}...`}</div>
          </Tooltip>
        ) : (
          caseCommunication.description || '-'
        )}

        <DescriptionDialog
          open={open}
          dialogTitle="Message"
          setOpen={setOpen}
          description={caseCommunication.description}
        />
      </TableCell>
      <TableCell id={`CaseCommunications-tableCell-caseCommunication-${index}-outgoing`}>
        {caseCommunication.outgoing === true ? 'Outbound' : 'Inbound'}
      </TableCell>
    </TableRow>
  );
}

CaseCommunicationCard.propTypes = {
  caseCommunication: PropTypes.shape({
    created: PropTypes.string.isRequired,
    emailAddress: PropTypes.string,
    caseType: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    outgoing: PropTypes.bool.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  emailAddress: PropTypes.string.isRequired,
};
