import { useState } from 'react';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';

export default function ReversalDialogForm(props) {
  const { reasonCodes, parentId, transaction } = props;
  const { accountId } = useParams();

  const GetAmount = () => {
    switch (parentId) {
      case 'MileageTransactionReversal':
        return transaction.amountPaid;
      case 'PaymentReversal':
        return transaction.finAmount;
      case 'ChargebackReversal':
        return transaction.finAmount;
      case 'FeeReversal':
        return transaction.finAmount;
      default:
        return 0;
    }
  };

  const [state, setState] = useState({
    reasonCodeId: null,
    amount: GetAmount(),
    comment: '',
    accountId,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const getFeeReversalTransCodeId = () => {
    switch (transaction.finTransCodeKey) {
      case 'DEVICE_FEE':
        return 16;
      case 'CAP_FEE':
        return 17;
      case 'TRUEUP_FEE':
        return 18;
      case 'PHOTO_LATE_FEE':
        return 19;
      default:
        return 0;
    }
  };

  const GetFinTransCodeId = () => {
    switch (parentId) {
      case 'MileageTransactionReversal':
        return 15;
      case 'PaymentReversal':
        return 11;
      case 'ChargebackReversal':
        return 12;
      case 'FeeReversal':
        return getFeeReversalTransCodeId();
      default:
        return 0;
    }
  };

  const reasonCodesMenuItems =
    parentId !== 'ChargebackReversal'
      ? reasonCodes?.map((reasonCode) => {
          return (
            <MenuItem
              key={reasonCode.reasonCodeId}
              value={reasonCode.reasonCodeId}
              id={`${parentId}-select-reasonCodeValue-${reasonCode.reasonCodeDesc.replaceAll(
                ' ',
                ''
              )}`}
            >
              {reasonCode.reasonCodeDesc}
            </MenuItem>
          );
        })
      : null;

  const handleSubmit = (e) => {
    e.preventDefault();
    const reversalObj = {
      accountId: state.accountId ?? transaction.accountId,
      fjno: transaction.fjNo,
      amount: state.amount,
      finTransTypeId: parentId === 'ChargebackReversal' ? 7 : 5,
      finTransCodeId: GetFinTransCodeId(),
      reasonCodeId: state.reasonCodeId,
      chargebackReason: parentId === 'ChargebackReversal' ? reasonCodes : null,
      comments: state.comment,
      transactionId: transaction.tollTransactionId ?? null,
      plateId: transaction.plateId ?? null,
    };
    props.postReversal(reversalObj);
    props.handleClose();
  };

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="FjNo"
                id={`${parentId}-textField-reversalFjNo`}
                value={transaction.fjNo}
                name="reversalFjNo"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="Amount"
                id={`${parentId}-textField-amount`}
                value={`$ ${
                  transaction.tollTransactionId != null ? state.amount.toFixed(2) : state.amount
                }`}
                name="amount"
                onChange={handleChange}
              />
            </Grid>
            {parentId === 'FeeReversal' && (
              <Grid item xs={6}>
                <TextField
                  required
                  disabled
                  fullWidth
                  size="small"
                  label="FinTransCodeKey"
                  id={`${parentId}-textField-finTransCodeKey`}
                  value={transaction.finTransCodeKey}
                />
              </Grid>
            )}
            {parentId === 'ChargebackReversal' && (
              <Grid item xs={6}>
                <TextField
                  required
                  disabled
                  fullWidth
                  size="small"
                  label="Chargeback Reason"
                  id={`${parentId}-textField-chargebackReason`}
                  value={reasonCodes}
                />
              </Grid>
            )}
            {parentId !== 'ChargebackReversal' && (
              <Grid item xs={6}>
                <TextField
                  select
                  required
                  fullWidth
                  size="small"
                  label="Reason"
                  id={`${parentId}-select-reasonCode`}
                  value={state.reasonCodeId}
                  name="reasonCodeId"
                  onChange={handleChange}
                >
                  {reasonCodesMenuItems}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                multiline
                label="Comment"
                variant="outlined"
                minRows={10}
                placeholder="Enter Comment..."
                id={`${parentId}-textField-comment`}
                name="comment"
                value={state.comment}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id={`${parentId}-btn-cancel`}
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button variant="contained" size="small" id={`${parentId}-btn-post`} type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

ReversalDialogForm.propTypes = {
  transaction: PropTypes.shape({
    fjNo: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    amountPaid: PropTypes.number,
    csrid: PropTypes.number.isRequired,
    finAmount: PropTypes.number,
    finTransTypeId: PropTypes.number.isRequired,
    finTransCodeId: PropTypes.number.isRequired,
    comment: PropTypes.string.isRequired,
    reasonCodeId: PropTypes.number.isRequired,
    finTransCodeKey: PropTypes.string.isRequired,
    finDate: PropTypes.string,
    tollTransactionId: PropTypes.number,
    plateId: PropTypes.number.isRequired,
  }).isRequired,
  postReversal: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  reasonCodes: PropTypes.arrayOf(
    PropTypes.shape({
      finTransTypeId: PropTypes.number.isRequired,
      formText: PropTypes.string.isRequired,
      reasonCodeDesc: PropTypes.string.isRequired,
      reasonCodeId: PropTypes.number.isRequired,
    })
  ).isRequired,
  parentId: PropTypes.string.isRequired,
};
