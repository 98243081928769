import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { convertToDateMMddyyyy } from 'utilities/format';

export default function ACHDetailsCard(props) {
  const { achDetails, index, getACHStatusDesc } = props;
  return (
    <TableRow id={`PaymentMethods-table-achDetails-${index}`}>
      <TableCell id={`PaymentMethods-table-achDetails-${index}-HolderName`}>
        {achDetails.holderName === null ? '-' : `${achDetails.holderName}`}
      </TableCell>
      <TableCell id={`PaymentMethods-table-achDetails-${index}-DisplayACHInfo`}>
        {achDetails.displayACHInfo === null ? '-' : `${achDetails.displayACHInfo}`}
      </TableCell>
      <TableCell id={`PaymentMethods-table-achDetails-${index}-ACHStatusId`}>
        {getACHStatusDesc(achDetails.achStatusId)}
      </TableCell>
      <TableCell id={`PaymentMethods-table-achDetails-${index}-Priority`}>
        {achDetails.priority === null ? '-' : `${achDetails.priority}`}
      </TableCell>
      <TableCell id={`PaymentMethods-table-achDetails-${index}-Created`}>
        {achDetails.created === null ? '-' : `${convertToDateMMddyyyy(achDetails.created)}`}
      </TableCell>
    </TableRow>
  );
}

ACHDetailsCard.propTypes = {
  achDetails: PropTypes.shape({
    holderName: PropTypes.string.isRequired,
    displayACHInfo: PropTypes.string.isRequired,
    achStatusId: PropTypes.string.isRequired,
    priority: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  getACHStatusDesc: PropTypes.func.isRequired,
};
