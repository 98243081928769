import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'App';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'components/AzureAD/authConfig';

const msalApp = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <RecoilRoot>
    <Router>
      <MsalProvider instance={msalApp}>
        <App />
      </MsalProvider>
    </Router>
  </RecoilRoot>,
  document.getElementById('root')
);
