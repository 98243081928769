/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import axios from 'utilities/authAxios';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { TagStatusId } from 'shared/tagStatusEnum';
import { refundRight } from 'utilities/userRole';
import { useRecoilValue } from 'recoil';
import { convertToDate } from 'utilities/format';

export default function UpdateDeviceDialogForm(props) {
  const { device, getTagStatusDesc, getProductDesc, tagStatusDescs, updateDevice, handleClose } =
    props;
  const [disableLocationCheckBox, setDisableLocationCheckBox] = useState(false);
  const [state, setState] = useState({
    accountTagId: device.accountTagId,
    vin: device.vin,
    plateNumber: device.plateNumber,
    tagSerialNumber: device.tagSerialNumber,
    productId: device.productId,
    tagStatusId: device.tagStatusId,
    tagStatusDate: device.tagStatusDate,
    startDate: device.startDate,
    endDate: device.endDate,
    canReplaceDevice: false,
    canPostDeviceFee: false,
    isLocationTracked: device.isLocationTracked,
  });

  const hasRefundRight = useRecoilValue(refundRight);

  const eligibleDevices = (vin) => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Device/DeviceCompatibilityCheck?vin=${vin}`)
      .then((res) => {
        props.setLoading(false);
        if (res.data.some((x) => x.productId === device.productId && x.hasLocation === true)) {
          setDisableLocationCheckBox(false);
        } else {
          setState({
            ...state,
            isLocationTracked: false,
          });
          setDisableLocationCheckBox(true);
        }
      })
      .catch(() => {
        props.setLoading(false);
        props.presentAlert('error', 'Error while checking for device compatibility');
      });
  };

  useEffect(() => {
    if (state.canReplaceDevice) {
      eligibleDevices(device.vin);
    }
  }, [state.canReplaceDevice]);

  const postFeeDefault = (target) => {
    const newTagStatusDesc = getTagStatusDesc(target.value);
    let result = false;
    if (
      newTagStatusDesc === 'DAMAGED' ||
      newTagStatusDesc === 'LOST' ||
      newTagStatusDesc === 'STOLEN'
    ) {
      result = false;
    }
    return result;
  };

  const replaceDeviceDefault = (target) => {
    const newTagStatusDesc = getTagStatusDesc(target.value);
    let result = false;
    if (
      newTagStatusDesc === 'DAMAGED' ||
      newTagStatusDesc === 'STOLEN' ||
      newTagStatusDesc === 'LOST'
    ) {
      result = true;
    }

    return result;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
      canPostDeviceFee: name === 'tagStatusId' ? postFeeDefault(e.target) : false,
      canReplaceDevice: name === 'tagStatusId' ? replaceDeviceDefault(e.target) : false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.tagStatusId == TagStatusId.RETURNED && !hasRefundRight) {
      props.setLoading(false);
      props.presentAlert(
        'error',
        "You don't have the permissions to change device status to returned."
      );
    } else {
      const updatedDevice = {
        ...state,
        tagStatusId: parseInt(e.target.tagStatusId.value, 10),
      };
      updateDevice(updatedDevice);
      handleClose();
    }
  };

  // eslint-disable-next-line array-callback-return
  const tagStatusMenuItems = Object.entries(tagStatusDescs).map((entry) => {
    if (
      (entry[1] !== 'INACTIVE' && entry[1] !== 'ACTIVE') ||
      (entry[1] === 'ACTIVE' && device.tagStatusDesc === 'ACTIVE') ||
      (entry[1] === 'INACTIVE' && device.tagStatusDesc === 'INACTIVE')
    ) {
      return (
        <MenuItem
          disabled={
            (device.plateStatusDesc === 'Inactive' &&
              device.tagStatusDesc === 'INACTIVE' &&
              entry[1] === 'ACTIVE') ||
            device.tagStatusId === parseInt(entry[0], 10) ||
            ((device.plateStatusDesc === 'Inactive' ||
              device.plateStatusDesc === 'Pending Close') &&
              entry[1] !== 'RETURNED') ||
            ((device.tagStatusDesc === 'STOLEN' ||
              device.tagStatusDesc === 'DAMAGED' ||
              device.tagStatusDesc === 'LOST') &&
              entry[1] !== 'RETURNED')
          }
          key={entry[0]}
          value={entry[0]}
          id={`UpdateDevice-select-tagStatusDescValue-${entry[1]}`}
        >
          {entry[1]}
        </MenuItem>
      );
    }
  });

  const convertDate = (date) => {
    if (date === null) {
      return null;
    }
    return convertToDate(date);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                disabled
                label="VIN"
                size="small"
                variant="outlined"
                id="UpdateDevice-textField-vin"
                name="vin"
                value={state.vin}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                disabled
                fullWidth
                label="Plate #"
                size="small"
                variant="outlined"
                id="UpdateDevice-textField-plateNumber"
                name="plateNumber"
                value={state.plateNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label="Plate Status"
                size="small"
                variant="outlined"
                id="UpdateDevice-textField-plateStatusDesc"
                name="plateStatusDesc"
                value={device.plateStatusDesc}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                disabled
                label="MRO #"
                size="small"
                variant="outlined"
                id="UpdateDevice-textField-tagSerialNumber"
                name="tagSerialNumber"
                value={state.tagSerialNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                disabled
                label="Product Type"
                size="small"
                id="UpdateDevice-textField-productDesc"
                value={getProductDesc(state.productId)}
                name="productId"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                disabled
                label="Device Start Date"
                size="small"
                variant="outlined"
                id="UpdateDevice-textField-startDate"
                name="startDate"
                value={convertDate(state.startDate)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label="Device End Date"
                size="small"
                variant="outlined"
                id="UpdateDevice-textField-endDate"
                name="endDate"
                value={convertDate(state.endDate)}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                required
                fullWidth
                disabled={
                  device.tagSerialNumber === null ||
                  (device.productDesc === 'Telematic' && device.tagSerialNumber !== null)
                }
                size="small"
                label="Tag Status"
                id="UpdateDevice-select-tagStatusDesc"
                value={state.tagStatusId}
                name="tagStatusId"
                onChange={handleChange}
              >
                {tagStatusMenuItems}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                disabled
                label="Order Status"
                size="small"
                variant="outlined"
                id="UpdateDevice-textField-salesOrderStatusDesc"
                name="salesOrderStatusDesc"
                value={device.salesOrderStatusDesc}
              />
            </Grid>
            <Box width="100%" />
            <Grid item xs={4}>
              <FormControl>
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      disabled={device.tagSerialNumber === null}
                      color="success"
                      id="UpdateDevice-checkbox-postDeviceFee"
                      checked={state.canPostDeviceFee}
                      onChange={(e) => setState({ ...state, canPostDeviceFee: e.target.checked })}
                    />
                  }
                  label="Post Fee"
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      disabled={
                        device.tagSerialNumber === null ||
                        state.tagStatusId == TagStatusId.DAMAGED ||
                        state.tagStatusId == TagStatusId.LOST ||
                        state.tagStatusId == TagStatusId.STOLEN
                      }
                      color="success"
                      id="UpdateDevice-checkbox-replaceDevice"
                      checked={state.canReplaceDevice}
                      onChange={(e) => setState({ ...state, canReplaceDevice: e.target.checked })}
                    />
                  }
                  label="Replace Device"
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>
            {state.canReplaceDevice && (
              <Grid item xs={4}>
                <FormControl>
                  <FormControlLabel
                    value="start"
                    disabled={disableLocationCheckBox}
                    control={
                      <Checkbox
                        disabled={device.tagSerialNumber === null}
                        color="success"
                        id="UpdateDevice-checkbox-replaceDevice"
                        checked={state.isLocationTracked}
                        onChange={(e) =>
                          setState({ ...state, isLocationTracked: e.target.checked })
                        }
                      />
                    }
                    label="Track Location"
                    labelPlacement="start"
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="UpdateDevice-btn-cancel"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            id="UpdateDevice-btn-updateDevice"
            type="submit"
            disabled={device.tagStatusId === parseInt(state.tagStatusId, 10)}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

UpdateDeviceDialogForm.propTypes = {
  device: PropTypes.shape({
    isLocationTracked: PropTypes.bool.isRequired,
    accountTagId: PropTypes.number.isRequired,
    plateStatusId: PropTypes.number.isRequired,
    salesOrderStatusId: PropTypes.number.isRequired,
    vin: PropTypes.string.isRequired,
    plateNumber: PropTypes.string.isRequired,
    tagSerialNumber: PropTypes.string.isRequired,
    tagStatusId: PropTypes.number.isRequired,
    productId: PropTypes.number.isRequired,
    salesOrderStatusDesc: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    tagStatusDate: PropTypes.string.isRequired,
    tagStatusDesc: PropTypes.string.isRequired,
    plateStatusDesc: PropTypes.string.isRequired,
    productDesc: PropTypes.string,
  }).isRequired,
  getProductDesc: PropTypes.objectOf(PropTypes.string).isRequired,
  updateDevice: PropTypes.func.isRequired,
  tagStatusDescs: PropTypes.objectOf(PropTypes.string).isRequired,
  getTagStatusDesc: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};
