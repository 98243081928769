import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import WithLoader from 'components/Custom/WithLoader';
import SearchBar from 'components/Custom/SearchBar';
import DataGridTable, { createQuery } from 'components/Custom/DataGridTable';
import DataGridPagination from 'components/Custom/DataGridPagination';
import { refreshAccountInfo } from 'utilities/State';
import { useRecoilValue } from 'recoil';
import useTableState from 'hooks/useTableState';
import COLUMNS from './CaseSearchColumns';
import CaseSearchCard from './CaseSearchCard';

const INITIAL_STATE = {
  pageNumber: 0,
  pageSize: 25,
  sort: { field: 'created', order: 'desc' },
  filter: undefined,
};

function CaseSearchGrid(props) {
  const { accountId } = useParams();
  const isAccountLevel = accountId !== undefined;

  const refresh = useRecoilValue(refreshAccountInfo);
  const { caseStatusDescs, casePriorityDescs } = props;

  const getCaseStatusDesc = (caseStatusId) => {
    return caseStatusDescs[caseStatusId.toString()];
  };

  const getCasePriorityDesc = (casePriorityId) => {
    return casePriorityDescs[casePriorityId.toString()];
  };

  const queryCases = useMemo(
    () =>
      createQuery({
        extraParams: { accountId },
        presentAlert: props.presentAlert,
        dismissAlert: props.dismissAlert,
        setLoading: props.setLoading,
        url: `${process.env.REACT_APP_API_URL}/CaseManagement/Cases`,
      }),
    [accountId, refresh]
  );

  const {
    data,
    totalCount,
    loading,
    onSortChange,
    onFilterChange,
    onPageChange,
    onPageSizeChange,
    onRefresh,
    sort,
    pageNumber,
    pageSize,
  } = useTableState(INITIAL_STATE, queryCases);

  const clearSearch = useCallback(() => {
    onFilterChange(undefined);
  }, [onFilterChange]);

  const handleSearchSubmit = useCallback(
    (searchBy, searchString = '', startDate = '', endDate = '') => {
      onFilterChange({
        type: searchBy,
        keyword: searchString,
        startDate,
        endDate,
      });
    },
    []
  );

  const components = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      Row: (rowProps) => (
        <CaseSearchCard
          accountId={accountId}
          isAccountLevel={isAccountLevel}
          getCaseStatusDesc={getCaseStatusDesc}
          getCasePriorityDesc={getCasePriorityDesc}
          presentParentAlert={props.presentAlert}
          caseObj={rowProps.row}
          index={rowProps.index}
          renderedColumns={rowProps.renderedColumns}
          rowHeight={rowProps.rowHeight}
        />
      ),
      // eslint-disable-next-line
      Pagination: (props) => <DataGridPagination {...props} onRefresh={onRefresh} />,
    }),
    [accountId, props.presentAlert]
  );

  return (
    <div>
      <Typography sx={{ mb: 2 }} variant="h5" id="Cases-text-header">
        Case Search
      </Typography>
      {!props.loading && (
        <Stack direction="column" spacing={2}>
          <SearchBar
            parentId="Case"
            handleSearchSubmit={handleSearchSubmit}
            clearSearch={clearSearch}
            searchTypes={['Case Number', 'Account Number', 'Case Type', 'Open Date']}
          />
          <DataGridTable
            columns={COLUMNS}
            loading={loading}
            data={data}
            overridingComponent={components}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRefresh={onRefresh}
            totalCount={totalCount}
            sort={sort}
            onSortModelChange={onSortChange}
          />
        </Stack>
      )}
    </div>
  );
}

CaseSearchGrid.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
  caseStatusDescs: PropTypes.objectOf(PropTypes.string).isRequired,
  casePriorityDescs: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default WithLoader(CaseSearchGrid);
