import DataGridHeader from 'components/Custom/DataGridHeader';

const COLUMNS = [
  {
    field: 'fjNo',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="fjNoId" heading="FJ #" />,
  },
  {
    field: 'finDate',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="finDateId" heading="Date" />,
  },
  {
    field: 'transactionType',
    flex: 1.2,
    renderHeader: () => <DataGridHeader columnId="transTypeId" heading="Transaction Type" />,
    sortable: false,
  },
  {
    field: 'description',
    flex: 2,
    renderHeader: () => <DataGridHeader columnId="descId" heading="Description" />,
    sortable: false,
  },
  {
    field: 'credit',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="credit" heading="Credit" />,
  },
  {
    field: 'debit',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="debit" heading="Debit" />,
  },
  {
    field: 'accountBalance',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="accountBalId" heading="Balance" />,
    sortable: false,
  },
  {
    field: 'displayCardInfo',
    flex: 2,
    renderHeader: () => <DataGridHeader columnId="displayCardInfo" heading="Card Number" />,
    sortable: false,
  },
  {
    field: 'createdBy',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="createdById" heading="Created By" />,
    sortable: false,
  },
  {
    field: 'message',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="messageId" heading="Message" />,
    sortable: false,
  },
  {
    field: 'reversalFjNo',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="revFjNoId" heading="Reversal FJ #" />,
    sortable: false,
  },
  {
    field: 'action',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="actionId" heading="Action" />,
    sortable: false,
  },
];

export default COLUMNS;
