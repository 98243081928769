import { useState, useEffect } from 'react';
import axios from 'utilities/authAxios';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { amountValidation } from 'utilities/PaymentValidation';

export default function PostAdjustmentDialogForm(props) {
  const [adjustmentTypes, setAdjustmentTypes] = useState([]);
  const [accountBalance, setAccountBalance] = useState(null);
  const [reasonCodes, setReasonCodes] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [amountError, setAmountError] = useState({ helperText: '', hasError: false });
  const { accountId } = useParams();

  const [state, setState] = useState({
    finTransTypeId: null,
    finTransCodeId: 8,
    amount: null,
    balanceAfterAdjustment: null,
    reasonCodeId: null,
    comment: '',
    accountId,
  });

  const calculateBalance = () => {
    if (accountBalance == null || state.amount == null) return null;
    const adjustmentAmount = state.amount;
    let balance = null;
    if (state.finTransCodeId === 9) {
      balance = parseFloat(accountBalance) + parseFloat(adjustmentAmount);
    } else {
      balance = parseFloat(accountBalance) - parseFloat(adjustmentAmount);
    }
    return balance;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'amount') {
      let amountValidationResult = '';
      amountValidationResult = amountValidation(value);
      if (amountValidationResult !== '') {
        setAmountError({ helperText: amountValidationResult, hasError: true });
        setDisableBtn(true);
      } else {
        setAmountError({ helperText: amountValidationResult, hasError: false });
        setDisableBtn(false);
      }
    }

    setState({
      ...state,
      [name]: value,
    });
  };

  const adjustmentTypesMenuItems = adjustmentTypes.map((adjustmentType) => {
    return (
      <MenuItem
        key={adjustmentType.finTransCodeId}
        value={adjustmentType.finTransCodeId}
        id={`PostAdjustment-select-adjustmentTypeValue-${adjustmentType.finTransCodeDesc.replaceAll(
          ' ',
          ''
        )}`}
      >
        {adjustmentType.finTransCodeDesc}
      </MenuItem>
    );
  });

  const getAdjustmentTypes = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Financial/GetFinTransCodes/${'Adjustment'}`)
      .then((res) => {
        setAdjustmentTypes(res.data);
        setState({
          ...state,
          finTransTypeId: res.data[0].finTransTypeId,
        });
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.setLoading(false);
        props.presentAlert('error', 'Server could not be reached. Please refresh the page.');
      });
  };

  const getAccountBalance = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Financial/GetAccountBalance/${accountId}`)
      .then((res) => {
        setAccountBalance(res.data);
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.setLoading(false);
        props.presentAlert('error', 'Server could not be reached. Please refresh the page.');
      });
  };

  const getReasonCodes = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Financial/GetReasonCodes`)
      .then((res) => {
        setReasonCodes(res.data);
        props.setLoading(false);
      })
      .catch(() => {
        props.setLoading(false);
        props.presentAlert('Error', 'Failed to get reason codes');
      });
  };

  useEffect(() => {
    getAdjustmentTypes();
    getAccountBalance();
    getReasonCodes();
  }, []);

  const reasonCodesMenuItems = reasonCodes
    ?.filter(
      (reasonTransType) =>
        reasonTransType.finTransTypeId === 4 || reasonTransType.finTransTypeId === 5
    )
    .map((reasonCode) => {
      return (
        <MenuItem
          key={reasonCode.reasonCodeId}
          value={reasonCode.reasonCodeId}
          id={`PostAdjustment-select-reasonCodeValue-${reasonCode.reasonCodeDesc.replaceAll(
            ' ',
            ''
          )}`}
        >
          {reasonCode.reasonCodeDesc}
        </MenuItem>
      );
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setLoading(true);
    const adjustmentObj = {
      accountId: state.accountId,
      amount: state.amount,
      reasonCodeId: state.reasonCodeId,
      finTransTypeId: state.finTransTypeId,
      finTransCodeId: state.finTransCodeId,
      comments: state.comment,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/Financial/PostAdjustment`, adjustmentObj)
      .then((res) => {
        if (res.data > 0) {
          props.setLoading(false);
          props.reloadAccountInfo();
          props.handleClose();
          // present alert in accountInfoHeader
          props.presentParentAlert('success', 'Adjustment posted successfully');
        } else {
          // present alert in dialog
          props.setLoading(false);
          props.presentAlert('error', 'Adjustment was not posted');
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err);
        props.presentAlert('error', 'Adjustment was not posted');
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                select
                required
                disabled
                fullWidth
                size="small"
                label="Adjustment Type"
                id="PostAdjustment-select-adjustmentType"
                value={state.finTransCodeId}
                name="finTransCodeId"
                onChange={handleChange}
              >
                {adjustmentTypesMenuItems}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                type="number"
                size="small"
                label="Adjustment Amount"
                variant="outlined"
                id="PostAdjustment-textField-amount"
                name="amount"
                value={state.amount}
                helperText={amountError.helperText}
                error={amountError.hasError}
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                size="small"
                label="Current Balance"
                id="PostAdjustment-textField-balance"
                name="accountBalance"
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                value={accountBalance}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                size="small"
                variant="outlined"
                id="PostAdjustment-textField-balanceAfterAdjustment"
                label="New Balance"
                name="balanceAfterAdjustment"
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                value={calculateBalance() || ''}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                required
                fullWidth
                size="small"
                label="Reason"
                id="PostAdjustment-select-reasonCode"
                value={state.reasonCodeId}
                name="reasonCodeId"
                onChange={handleChange}
              >
                {reasonCodesMenuItems}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                multiline
                label="Comment"
                variant="outlined"
                minRows={10}
                placeholder="Enter Comment..."
                id="PostAdjustment-textField-comment"
                name="comment"
                value={state.comment}
                onChange={handleChange}
                onKeyDown={(e) => e.stopPropagation()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="PostAdjustment-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={disableBtn}
            variant="contained"
            size="small"
            id="PostAdjustment-btn-post"
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

PostAdjustmentDialogForm.propTypes = {
  presentAlert: PropTypes.func.isRequired,
  presentParentAlert: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  reloadAccountInfo: PropTypes.func.isRequired,
};
