import Alert from 'components/Custom/Alert';
import DeviceSearchGrid from './DeviceSearchGrid';

function DeviceSearchPage() {
  return (
    <Alert childrenId="DeviceSearch">
      <DeviceSearchGrid />
    </Alert>
  );
}

export default DeviceSearchPage;
