import { useCallback, useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import WithLoader from 'components/Custom/WithLoader';
import axios from 'utilities/authAxios';
import SearchBar from 'components/Custom/SearchBar';
import DataGridTable, { createQuery } from 'components/Custom/DataGridTable';
import DataGridPagination from 'components/Custom/DataGridPagination';
import useTableState from 'hooks/useTableState';
import COLUMNS from './FinancialTableColumns';
import FinancialsCard from './FinancialsCard';

const INITIAL_STATE = {
  pageNumber: 0,
  pageSize: 25,
  sort: { field: 'fjNo', order: 'desc' },
  filter: undefined,
};

function FinancialsPage(props) {
  const { accountId } = useParams();
  const [reasonCodes, setReasonCodes] = useState([]);
  const [isReversed, setIsReversed] = useState(false);

  const queryFinancials = useMemo(
    () =>
      createQuery({
        extraParams: { accountId },
        presentAlert: props.presentAlert,
        dismissAlert: props.dismissAlert,
        setLoading: props.setLoading,
        url: `${process.env.REACT_APP_API_URL}/Financial/GetFinancialsByAccount`,
      }),
    [accountId, isReversed]
  );

  const {
    data,
    totalCount,
    loading,
    onSortChange,
    onFilterChange,
    onPageChange,
    onPageSizeChange,
    onRefresh,
    sort,
    pageNumber,
    pageSize,
  } = useTableState(INITIAL_STATE, queryFinancials);

  const clearSearch = useCallback(() => {
    onFilterChange(undefined);
  }, [onFilterChange]);

  const handleSearchSubmit = useCallback(
    (searchBy, searchString = '', startDate = '', endDate = '') => {
      onFilterChange({
        type: searchBy,
        keyword: searchString,
        startDate,
        endDate,
      });
    },
    []
  );

  const getReasonCodes = useCallback(() => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Financial/GetReasonCodes`)
      .then((res) => {
        setReasonCodes(
          res.data?.filter(
            (reasonTransType) =>
              reasonTransType.finTransTypeId === 4 || reasonTransType.finTransTypeId === 5
          )
        );
        props.setLoading(false);
      })
      .catch(() => {
        props.setLoading(false);
        props.presentAlert('Error', 'Failed to get reason codes');
      });
  }, []);

  const postPaymentReversal = (reversalObj) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Financial/ProcessCreditCardReversal`, reversalObj)
      .then(() => {
        setIsReversed(!isReversed);
        props.presentAlert(
          'success',
          `Payment with FjNo: ${reversalObj.fjno} reversed successfully`
        );
      })
      .catch((err) => {
        props.presentAlert(
          'error',
          err.response?.data || `Failed to process reversal for FjNo: ${reversalObj.fjno}`
        );
      });
  };

  const postFeeReversal = (reversalObj) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Financial/ReverseFee`, reversalObj)
      .then(() => {
        setIsReversed(!isReversed);
        props.presentAlert(
          'success',
          `Fee transaction with FjNo: ${reversalObj.fjno} reversed successfully`
        );
      })
      .catch(() => {
        props.presentAlert('error', `Failed to process reversal for FjNo: ${reversalObj.fjno}`);
      });
  };

  useEffect(() => {
    getReasonCodes();
  }, []);

  const components = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      Row: (rowProps) => (
        <FinancialsCard
          accountId={accountId}
          paymentReversalReasonCodes={reasonCodes}
          presentAlert={props.presentAlert}
          postPaymentReversal={postPaymentReversal}
          postFeeReversal={postFeeReversal}
          financial={rowProps.row}
          index={rowProps.index}
          renderedColumns={rowProps.renderedColumns}
          rowHeight={rowProps.rowHeight}
        />
      ),
      // eslint-disable-next-line
      Pagination: (props) => <DataGridPagination {...props} onRefresh={onRefresh} />,
    }),
    [accountId, props.presentAlert, postPaymentReversal, reasonCodes]
  );

  return (
    <div>
      {!props.loading && (
        <Stack direction="column" spacing={2}>
          <SearchBar
            parentId="Financial"
            handleSearchSubmit={handleSearchSubmit}
            clearSearch={clearSearch}
            searchTypes={['FjNo', 'FinDate']}
          />
          <DataGridTable
            columns={COLUMNS}
            loading={loading}
            data={data}
            overridingComponent={components}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRefresh={onRefresh}
            totalCount={totalCount}
            sort={sort}
            onSortModelChange={onSortChange}
          />
        </Stack>
      )}
    </div>
  );
}

FinancialsPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
};

export default WithLoader(FinancialsPage);
