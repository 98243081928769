import { useState, useEffect, useCallback } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import axios from 'utilities/authAxios';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useRecoilValue } from 'recoil';
import { caseStatusDescsAtom } from 'utilities/caseManagementState';
import CaseCommunicationCard from './CaseCommunicationCard';

export default function CaseCommunicationPage(props) {
  const { caseObj } = props;
  const [refresh, toggleRefresh] = useState(false);
  const subject = caseObj.caseType.includes(caseObj.caseNumber)
    ? caseObj.caseType
    : `${caseObj.caseNumber}: ${caseObj.caseType}`;
  const caseStatusDescs = useRecoilValue(caseStatusDescsAtom);
  const [caseCommunications, setCaseCommunications] = useState([]);
  const [state, setState] = useState({
    body: '',
    subject,
  });

  const getCaseCommuncations = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/CaseManagement/Case/${caseObj.caseNumber}`)
      .then((res) => {
        if (res.data.length !== 0) {
          res.data = res.data.sort((a, b) => new Date(b.created) - new Date(a.created));
          setCaseCommunications(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  const getCaseStatusDesc = (caseStatusId) => {
    return caseStatusDescs[caseStatusId].toString();
  };

  useEffect(() => {
    getCaseCommuncations();
  }, [refresh]);

  const caseCommunicationsMapper = caseCommunications.map((caseCommuncation, index) => {
    return (
      <CaseCommunicationCard
        getCaseStatusDesc={getCaseStatusDesc}
        emailAddress={caseObj.emailAddress}
        index={index}
        key={caseCommuncation.caseId}
        caseCommunication={caseCommuncation}
      />
    );
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const respondObject = {
      accountId: caseObj.accountId,
      caseContactId: caseObj.caseContactId,
      emailSubject: state.subject,
      body: state.body,
      caseNumber: caseObj.caseNumber,
      recipient: caseObj.emailAddress,
      mobilePhone: caseObj.mobilePhone,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/CaseManagement/Case/Respond`, respondObject)
      .then((res) => {
        if (res.data) {
          props.presentAlert('success', 'Message has been sent');
          toggleRefresh(!refresh);
          setState({
            ...state,
            body: '',
          });
        } else {
          props.presentAlert('error', 'Fail to send message');
        }
      })
      .catch((err) => {
        console.log(err);
        props.presentAlert('error', 'Fail to send message');
      });
  };
  return (
    <Stack direction="column" spacing={2}>
      <TableContainer component={Paper} style={{ height: '200px' }}>
        <Table size="small" id="CaseCommunication-table-caseCommunications">
          <TableHead>
            <TableRow>
              <TableCell>Created</TableCell>
              <TableCell>From</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Direction</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{caseCommunicationsMapper}</TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography
            variant="h6"
            color="inherit"
            sx={{ mb: 2 }}
            component="div"
            id="CaseCommunication-header-sendMessage"
          >
            Send Message
          </Typography>
          <Grid item xs={3}>
            <TextField
              disabled
              required
              fullWidth
              size="small"
              label="Recipient"
              id="CaseCommunication-textField-recipient"
              value={caseObj.emailAddress ? caseObj.emailAddress : caseObj.mobilePhone}
            />
          </Grid>
          <Grid item xs={6} style={{ marginTop: '10px' }}>
            <TextField
              required
              disabled
              fullWidth
              size="small"
              label="Subject"
              id="CaseCommunication-textField-subject"
              placeholder="Enter subject for email..."
              name="subject"
              value={state.subject}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '10px' }}>
            <TextField
              required
              fullWidth
              multiline
              variant="outlined"
              minRows={10}
              placeholder="Enter message..."
              id="CaseCommunication-textField-body"
              name="body"
              value={state.body}
              onChange={handleChange}
            />
          </Grid>
          <DialogActions>
            <Button variant="contained" size="small" id="CaseCommunication-btn-send" type="submit">
              Send
            </Button>
          </DialogActions>
        </Box>
      </form>
    </Stack>
  );
}

CaseCommunicationPage.propTypes = {
  presentAlert: PropTypes.func.isRequired,
  caseObj: PropTypes.shape({
    created: PropTypes.string.isRequired,
    caseNumber: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    caseType: PropTypes.string.isRequired,
    caseStatusId: PropTypes.number.isRequired,
    dueDate: PropTypes.string,
    emailAddress: PropTypes.string,
    accountId: PropTypes.number,
    caseContactId: PropTypes.number,
    mobilePhone: PropTypes.string,
  }).isRequired,
};
