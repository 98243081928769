/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import FormDialog from 'components/Custom/FormDialog';
import { convertToDateMMddyyyy } from 'utilities/format';
import ReversalDialogForm from 'components/Custom/ReversalDialogForm';
import { useRecoilValue } from 'recoil';
import { processPaymentRight } from 'utilities/userRole';
import CustomTextDataGridCell from 'components/Custom/CustomTextDataGridCell';

const DataGridCell = ({ id, width, children, center, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} center={center} rowHeight={rowHeight}>
    {id.includes('accountBalance') && children < 0
      ? `($${(children * -1)?.toFixed(2)})`
      : id.includes('accountBalance') && children >= 0
      ? `$${children?.toFixed(2)}`
      : children}
  </CustomTextDataGridCell>
);

const renderReversalDialog = (
  financial,
  postPaymentReversal,
  postFeeReversal,
  handleClickOpen,
  paymentReversalReasonCodes,
  canAccess
  // eslint-disable-next-line consistent-return
) => {
  if (financial.transactionType === 'Payment' && financial.reversalFjNo === 0 && canAccess) {
    return (
      <FormDialog
        formId="PaymentReversal"
        buttonIcon={<SettingsBackupRestoreIcon />}
        dialogTitle="Payment Reversal"
        handleParentOpen={handleClickOpen}
      >
        <ReversalDialogForm
          reasonCodes={paymentReversalReasonCodes}
          transaction={financial}
          postReversal={postPaymentReversal}
          parentId="PaymentReversal"
        />
      </FormDialog>
    );
  }
  if (financial.transactionType === 'Fee' && financial.reversalFjNo === 0 && canAccess) {
    return (
      <FormDialog
        formId="FeeReversal"
        buttonIcon={<SettingsBackupRestoreIcon />}
        dialogTitle="Fee Reversal"
      >
        <ReversalDialogForm
          reasonCodes={paymentReversalReasonCodes}
          transaction={financial}
          postReversal={postFeeReversal}
          parentId="FeeReversal"
        />
      </FormDialog>
    );
  }
};

const ActionCell = ({
  id,
  width,
  financial,
  postPaymentReversal,
  postFeeReversal,
  handleClickOpen,
  rowHeight,
  paymentReversalReasonCodes,
  canAccess,
}) => (
  <DataGridCell id={id} width={width} center rowHeight={rowHeight}>
    {renderReversalDialog(
      financial,
      postPaymentReversal,
      postFeeReversal,
      handleClickOpen,
      paymentReversalReasonCodes,
      canAccess
    )}
  </DataGridCell>
);

const DateCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? convertToDateMMddyyyy(children) : '-'}
  </CustomTextDataGridCell>
);

const AmountCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? `$${children?.toFixed(2)}` : '$0.00'}
  </CustomTextDataGridCell>
);

const CELL_TYPE = {
  action: ActionCell,
  finDate: DateCell,
  credit: AmountCell,
  debit: AmountCell,
};

export default function FinancialsCard(props) {
  const {
    financial,
    index,
    postPaymentReversal,
    postFeeReversal,
    paymentReversalReasonCodes,
    presentAlert,
  } = props;
  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, [props.renderedColumns]);
  const hasProcessPaymentRight = useRecoilValue(processPaymentRight);

  const handleClickOpen = () => {
    const tDate = new Date();
    const todayDate = new Date(tDate.getTime() - tDate.getTimezoneOffset() * 60000)
      .toISOString()
      .split('T')[0];
    if (financial.finDate?.split('T')[0] !== todayDate) {
      presentAlert('error', 'Only same-day payments can be reversed');
      return false;
    }
    return true;
  };

  return (
    <TableRow
      id={`Financials-tableRow-financial-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`Financials-tableCell-financial-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            financial={financial}
            index={index}
            postPaymentReversal={postPaymentReversal}
            postFeeReversal={postFeeReversal}
            handleClickOpen={handleClickOpen}
            rowHeight={props.rowHeight}
            paymentReversalReasonCodes={paymentReversalReasonCodes}
            canAccess={hasProcessPaymentRight}
          >
            {financial[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

FinancialsCard.propTypes = {
  financial: PropTypes.shape({
    fjNo: PropTypes.number.isRequired,
    finDate: PropTypes.string.isRequired,
    transactionType: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    accountId: PropTypes.number.isRequired,
    finAmount: PropTypes.number.isRequired,
    accountBalance: PropTypes.number.isRequired,
    createdBy: PropTypes.string.isRequired,
    message: PropTypes.string,
    reversalFjNo: PropTypes.number.isRequired,
    displayCardInfo: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  presentAlert: PropTypes.func.isRequired,
  postPaymentReversal: PropTypes.func.isRequired,
  postFeeReversal: PropTypes.func.isRequired,
  renderedColumns: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
  paymentReversalReasonCodes: PropTypes.arrayOf(
    PropTypes.shape({
      finTransTypeId: PropTypes.number.isRequired,
      formText: PropTypes.string.isRequired,
      reasonCodeDesc: PropTypes.string.isRequired,
      reasonCodeId: PropTypes.number.isRequired,
    })
  ).isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

DataGridCell.defaultProps = {
  center: false,
};

DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

ActionCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};
