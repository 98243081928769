import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'utilities/authAxios';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import WithLoader from 'components/Custom/WithLoader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PaymentMethodCard from './PaymentMethodCard';
import ACHDetailsGrid from './ACHDetailsGrid';

function PaymentMethodPage(props) {
  const { accountId } = useParams();
  const [paymentCards, setPaymentCards] = useState([]);
  const [creditCardTypeDesc, setCreditCardTypeDesc] = useState({});
  const [creditCardStatusDesc, setCreditCardStatusDesc] = useState({});

  const getCreditCardTypesLookup = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/CreditCardType`)
      .then((res) => {
        setCreditCardTypeDesc(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getCreditCardStatusLookup = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/CreditCardStatus`)
      .then((res) => {
        setCreditCardStatusDesc(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getCreditCardTypeDesc = (creditCardTypeId) => {
    return creditCardTypeDesc[creditCardTypeId.toString()];
  };

  const getCreditCardStatusDesc = (creditCardStatusId) => {
    return creditCardStatusDesc[creditCardStatusId.toString()];
  };

  const updateCard = (updatedCard) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Payment/UpdateCard`, updatedCard)
      .then((res) => {
        if (res.data === true) {
          // Update UI:
          const updatedCardInfo = paymentCards.map((cardObj) => {
            if (cardObj.cardId === updatedCard.cardId) {
              return {
                cardTypeId: updatedCard.cardTypeId,
                cardNum: updatedCard.cardNumber,
                nameOnCard: updatedCard.nameOnCard,
                cardStatusId: 1,
                priority: 1,
                expMonth: updatedCard.expiryMonth,
                expYear: updatedCard.expiryYear,
                created: updatedCard.created,
              };
            }
            return cardObj;
          });
          setPaymentCards(updatedCardInfo);
          props.presentAlert(
            'success',
            `Card: ${updatedCard.cardNumber.replace(/\d(?=\d{4})/g, '*')} updated successfully`
          );
        } else {
          props.presentAlert(
            'error',
            'Card information was not updated',
            `${updatedCard.cardNumber.replace(/\d(?=\d{4})/g, '*')}`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        props.presentAlert(
          'error',
          'Card information was not updated',
          `${updatedCard.cardNumber.replace(/\d(?=\d{4})/g, '*')}`
        );
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Payment/GetCardsForAccount/${accountId}`)
      .then((res) => {
        setPaymentCards(res.data);
        props.setLoading(false);
        getCreditCardTypesLookup();
        getCreditCardStatusLookup();
      })
      .catch((err) => {
        console.log(err);
        props.presentError('Server could not be reached. Please refresh the page.');
      });
  }, [accountId, setPaymentCards]);

  const paymentCardsMapper = paymentCards.map((card, index) => {
    return (
      <PaymentMethodCard
        key={card.cardId}
        paymentCards={card}
        updateCard={updateCard}
        index={index}
        getCreditCardTypeDesc={getCreditCardTypeDesc}
        getCreditCardStatusDesc={getCreditCardStatusDesc}
        creditCardTypes={creditCardTypeDesc}
      />
    );
  });

  return (
    <div>
      {!props.loading && (
        <Stack direction="column" spacing={2}>
          <Typography variant="h6" id="PaymentMethods-header-achInfo">
            Credit Card
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small" id="PaymentMethods-table-paymentMethods">
              <TableHead>
                <TableRow>
                  <TableCell>Card Type</TableCell>
                  <TableCell>Card Number</TableCell>
                  <TableCell>Name on Card</TableCell>
                  <TableCell>Exp. Month</TableCell>
                  <TableCell>Exp. Year</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Card Added on</TableCell>
                  <TableCell align="center" colSpan={2}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{paymentCardsMapper}</TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h6" id="PaymentMethods-header-achInfo">
            ACH
          </Typography>
          <ACHDetailsGrid />
        </Stack>
      )}
    </div>
  );
}

PaymentMethodPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentError: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
};

export default WithLoader(PaymentMethodPage);
