import { useState } from 'react';
import axios from 'utilities/authAxios';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CaseManagementTabPanel from './CaseManagementTabPanel';

export default function UpdateCaseDialog(props) {
  const { caseObj } = props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleClick = () => {
    if (caseObj.isUnreadEmail) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/CaseManagement/Case/${caseObj.caseId}/Read`)
        .catch((err) => {
          console.log(err);
        });
    }
    setOpen(true);
  };

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Tooltip title="Update Case">
        <IconButton
          color="inherit"
          onClick={handleClick}
          id="UpdateCaseDialog-btn-openDialog"
          sx={{ color: 'black' }}
          size="small"
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      {open && (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
          <DialogTitle>
            <Typography
              variant="h6"
              color="inherit"
              component="div"
              id="UpdateCaseDialog-header-caseNumber"
            >
              Case #{caseObj.caseNumber}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ width: '100%' }} mt={1}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                  <Tab label="Details" id="UpdateCaseDialog-tab-details" />
                  <Tab label="Communication" id="UpdateCaseDialog-tab-communication" />
                </Tabs>
              </Box>
              <CaseManagementTabPanel
                caseObj={caseObj}
                childrenId="UpdateCaseDialog-details"
                value={value}
                index={0}
              />
              <CaseManagementTabPanel
                caseObj={caseObj}
                childrenId="UpdateCaseDialog-communication"
                value={value}
                index={1}
              />
            </Box>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

UpdateCaseDialog.propTypes = {
  caseObj: PropTypes.shape({
    caseId: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    caseStatusId: PropTypes.number.isRequired,
    casePriorityId: PropTypes.number.isRequired,
    caseType: PropTypes.string.isRequired,
    caseNumber: PropTypes.string.isRequired,
    isUnreadEmail: PropTypes.bool.isRequired,
    dueDate: PropTypes.string,
  }).isRequired,
};
