import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import WithLoader from 'components/Custom/WithLoader';
import axios from 'utilities/authAxios';
import DataGridTable, { createQuery } from 'components/Custom/DataGridTable';
import DataGridPagination from 'components/Custom/DataGridPagination';
import useTableState from 'hooks/useTableState';
import COLUMNS from './StatementTableColumns';
import StatementCard from './StatementsCard';

const INITIAL_STATE = {
  pageNumber: 0,
  pageSize: 25,
  sort: { field: 'statementNumber', order: 'desc' },
  filter: undefined,
};

function StatementsPage(props) {
  const { accountId } = useParams();
  const queryStatements = useMemo(
    () =>
      createQuery({
        extraParams: { accountId },
        presentAlert: props.presentAlert,
        dismissAlert: props.dismissAlert,
        setLoading: props.setLoading,
        url: `${process.env.REACT_APP_API_URL}/Account/GetMileageStatementsByAccount`,
      }),
    [accountId]
  );

  const {
    data,
    totalCount,
    loading,
    onSortChange,
    onPageChange,
    onPageSizeChange,
    onRefresh,
    sort,
    pageNumber,
    pageSize,
  } = useTableState(INITIAL_STATE, queryStatements);

  const viewStatement = (statementId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Account/${accountId}/GetStatementDocument/${statementId}`,
        {
          responseType: 'blob',
        }
      )
      .then((res) => {
        const file = new Blob([res.data], { type: res.headers['content-type'] });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank', 'noreferrer');
      })
      .catch(() => props.presentAlert('error', 'Failed to retrieve statement'));
  };

  const components = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      Row: (rowProps) => (
        <StatementCard
          viewStatement={viewStatement}
          accountId={accountId}
          presentAlert={props.presentAlert}
          statement={rowProps.row}
          index={rowProps.index}
          renderedColumns={rowProps.renderedColumns}
          rowHeight={rowProps.rowHeight}
        />
      ),
      // eslint-disable-next-line
      Pagination: (props) => <DataGridPagination {...props} onRefresh={onRefresh} />,
    }),
    [accountId, props.presentAlert]
  );

  return (
    <div>
      {!props.loading && (
        <Stack direction="column" spacing={2}>
          <DataGridTable
            columns={COLUMNS}
            loading={loading}
            data={data}
            overridingComponent={components}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRefresh={onRefresh}
            totalCount={totalCount}
            sort={sort}
            onSortModelChange={onSortChange}
          />
        </Stack>
      )}
    </div>
  );
}

StatementsPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
};

export default WithLoader(StatementsPage);
