/* eslint-disable react/jsx-props-no-spreading */
import Box from '@mui/material/Box';
import { useEffect, useRef, useState } from 'react';
import ErrorDialog from './ErrorDialog';
import LoadingOverlay from './LoadingOverlay';

const WithLoader = (WrappedComponent, fill = true) => {
  return (props) => {
    const [loading, setLoading] = useState(true);
    const [offsetTop, setOffsetTop] = useState(0);
    const [height, setHeight] = useState(0);
    const ref = useRef(null);
    const heightRef = useRef(null);

    useEffect(() => {
      if (fill) setOffsetTop(ref.current?.offsetTop);
      else setHeight(heightRef.current?.clientHeight);
    }, []);

    const [errorDialogState, setErrorDialogState] = useState({
      open: false,
      description: '',
      redirectLink: '',
    });

    const presentError = (description, redirectLink = '') => {
      setErrorDialogState({
        open: true,
        description,
        redirectLink,
      });
    };

    return (
      <Box ref={ref}>
        <Box
          sx={{
            position: 'relative',
            top: `${fill ? (window.innerHeight - offsetTop) / 2 : height / 2}px`,
          }}
        >
          {loading && <LoadingOverlay />}
          <ErrorDialog
            open={errorDialogState.open}
            description={errorDialogState.description}
            redirectLink={errorDialogState.redirectLink}
          />
        </Box>
        <div ref={heightRef} style={{ visibility: `${loading ? 'hidden' : 'visible'}` }}>
          <WrappedComponent
            {...props}
            loading={loading}
            setLoading={setLoading}
            presentError={presentError}
          />
        </div>
      </Box>
    );
  };
};

export default WithLoader;
