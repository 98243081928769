import { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { convertToDateMMddyyyy } from 'utilities/format';
import { Tooltip } from '@mui/material';

const DataGridCell = ({ id, width, children, center = false, rowHeight }) => (
  <TableCell
    id={id}
    style={{
      width: `${width}px`,
      justifyContent: !center ? 'left' : 'center',
      display: 'flex',
      alignItems: 'center',
      paddingTop: '0px',
      paddingBottom: '0px',
      overflow: 'hidden',
      height: `${rowHeight}px`,
    }}
  >
    {id.includes('beginBalance') || id.includes('endBalance') || id.includes('newCharge')
      ? `$${children}`
      : children}
  </TableCell>
);

// eslint-disable-next-line react/prop-types
const ActionCell = ({ id, width, handleClick, rowHeight }) => (
  <DataGridCell id={id} width={width} center rowHeight={rowHeight}>
    <Tooltip title="View Statement">
      <IconButton
        color="inherit"
        aria-label="statement"
        size="small"
        id="Statements-btn-viewStatement"
        onClick={handleClick}
      >
        <VisibilityIcon />
      </IconButton>
    </Tooltip>
  </DataGridCell>
);

const DateCell = ({ id, width, children, rowHeight }) => (
  <DataGridCell id={id} width={width} rowHeight={rowHeight}>
    {convertToDateMMddyyyy(children)}
  </DataGridCell>
);

const CELL_TYPE = {
  action: ActionCell,
  statementDate: DateCell,
  periodStartDate: DateCell,
  periodEndDate: DateCell,
};

export default function StatementsCard(props) {
  const { statement, index } = props;

  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, []);

  const handleClick = () => {
    props.viewStatement(statement.statementSummaryId);
  };

  return (
    <TableRow
      id={`Statements-tableRow-statement-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`Statements-tableCell-statement-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            statement={statement}
            index={index}
            handleClick={handleClick}
            rowHeight={props.rowHeight}
          >
            {statement[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

StatementsCard.propTypes = {
  statement: PropTypes.shape({
    statementSummaryId: PropTypes.number.isRequired,
    statementNumber: PropTypes.string.isRequired,
    statementMonth: PropTypes.string.isRequired,
    periodStartDate: PropTypes.string.isRequired,
    periodEndDate: PropTypes.string.isRequired,
    beginBalance: PropTypes.number.isRequired,
    endBalance: PropTypes.number.isRequired,
    newCharge: PropTypes.number.isRequired,
    statementDate: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  renderedColumns: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
  viewStatement: PropTypes.func.isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

DataGridCell.defaultProps = {
  center: false,
};

DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

ActionCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
};
