import { styled, Tooltip, tooltipClasses } from '@mui/material';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip placement="bottom-start" {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 550,
  },
});
export default CustomWidthTooltip;
