import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { convertToDateMMddyyyy } from 'utilities/format';

export default function VehicleDetailsCard(props) {
  const { vehicleDetails, index, getEnrollmentStatusDesc } = props;
  const enrollmentStatusDesc = getEnrollmentStatusDesc(vehicleDetails?.enrollmentStatusId);

  const getfuelType = vehicleDetails.fuelType === 'E' ? 'E' : 'Non-E';

  return (
    <TableRow
      id={`Vehicle-tableRow-vehicleDetails-${index}`}
      style={{
        ...(vehicleDetails.isActive ? { background: '#FFFFE0' } : {}),
        color: '#FFFFE0',
      }}
    >
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-plateNumber`}>
        {vehicleDetails.plateNumber === null ? '-' : `${vehicleDetails.plateNumber}`}
      </TableCell>
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-enrollmentStatusDesc`}>
        {enrollmentStatusDesc === null ? '-' : enrollmentStatusDesc}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicleDetails-${index}-startDMVRegDate`}>
        {convertToDateMMddyyyy(vehicleDetails.startDMVRegDate, false)}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicleDetails-${index}-endDMVExpDate`}>
        {convertToDateMMddyyyy(vehicleDetails.endDMVExpDate, false)}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicleDetails-${index}-dmvRegDate`}>
        {convertToDateMMddyyyy(vehicleDetails.dmvRegDate, false)}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicleDetails-${index}-dmvExpDate`}>
        {convertToDateMMddyyyy(vehicleDetails.dmvExpDate, false)}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicleDetails-${index}-isActive`}>
        {vehicleDetails.isActive === null ? '-' : vehicleDetails.isActive.toString()}
      </TableCell>
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-perMileFee`}>
        {vehicleDetails.perMileFee === null ? '-' : `$${vehicleDetails.perMileFee}`}
      </TableCell>
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-capFee`}>
        {vehicleDetails.capFee === null ? '-' : `$${vehicleDetails.capFee.toFixed(2)}`}
      </TableCell>
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-accruedFeeAmount`}>
        {vehicleDetails.accruedFeeAmount === null
          ? '-'
          : `$${vehicleDetails.accruedFeeAmount.toFixed(2)}`}
      </TableCell>
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-balance`}>
        {vehicleDetails.capFee === null || vehicleDetails.accruedFeeAmount === null
          ? '-'
          : `$${(vehicleDetails.capFee - vehicleDetails.accruedFeeAmount).toFixed(2)}`}
      </TableCell>
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-startOdometerUploadDate`}>
        {!vehicleDetails.startOdometerUploadDate
          ? '-'
          : convertToDateMMddyyyy(vehicleDetails.startOdometerUploadDate)}
      </TableCell>
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-startOdometer`}>
        {!vehicleDetails.startOdometerReading ? '-' : `${vehicleDetails.startOdometerReading}`}
      </TableCell>
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-endOdometerUploadDate`}>
        {!vehicleDetails.endOdometerUploadDate
          ? '-'
          : convertToDateMMddyyyy(vehicleDetails.endOdometerUploadDate)}
      </TableCell>
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-endOdometer`}>
        {!vehicleDetails.endOdometerReading ? '-' : `${vehicleDetails.endOdometerReading}`}
      </TableCell>
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-isDMVRenewed`}>
        {vehicleDetails.isDMVRenewed === null ? '-' : vehicleDetails.isDMVRenewed.toString()}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicleDetails-${index}-isEarlyEnrollee`}>
        {vehicleDetails.isEarlyEnrollee === null ? '-' : vehicleDetails.isEarlyEnrollee.toString()}
      </TableCell>
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-mpg`}>
        {vehicleDetails.mpg === null ? '-' : vehicleDetails.mpg}
      </TableCell>
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-fuelType`}>
        {vehicleDetails.fuelType === null ? '-' : getfuelType}
      </TableCell>
      <TableCell id={`Vehicle-tableCell-vehicleDetails-${index}-lastUpdated`}>
        {!vehicleDetails.lastUpdated ? '-' : convertToDateMMddyyyy(vehicleDetails.lastUpdated)}
      </TableCell>
    </TableRow>
  );
}

VehicleDetailsCard.propTypes = {
  accountData: PropTypes.shape({
    lastName: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }).isRequired,
  vehicleDetails: PropTypes.shape({
    plateNumber: PropTypes.string.isRequired,
    visKey: PropTypes.string.isRequired,
    nextProcessMonth: PropTypes.string.isRequired,
    nextProcessYear: PropTypes.string.isRequired,
    perMileFee: PropTypes.string.isRequired,
    capFee: PropTypes.string.isRequired,
    accruedFeeAmount: PropTypes.string.isRequired,
    startOdometerUploadDate: PropTypes.string.isRequired,
    startOdometerReading: PropTypes.string.isRequired,
    endOdometerUploadDate: PropTypes.string.isRequired,
    endOdometerReading: PropTypes.string.isRequired,
    isDMVRenewed: PropTypes.bool.isRequired,
    mpg: PropTypes.string.isRequired,
    fuelType: PropTypes.string.isRequired,
    lastUpdated: PropTypes.string.isRequired,
    enrollmentStatusId: PropTypes.string.isRequired,
    dmvRegDate: PropTypes.string.isRequired,
    dmvExpDate: PropTypes.string.isRequired,
    endDMVExpDate: PropTypes.string.isRequired,
    startDMVRegDate: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isEarlyEnrollee: PropTypes.bool.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  getEnrollmentStatusDesc: PropTypes.func.isRequired,
};
