import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import axios from 'utilities/authAxios';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accountStatusDescsAtom } from 'utilities/State';
import { creditAdjustmentRight, processPaymentRight, refundRight } from 'utilities/userRole';
import { Link } from 'react-router-dom';

export default function FinancialProcessingMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const setAccountStatusDescs = useSetRecoilState(accountStatusDescsAtom);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const hasRefundRight = useRecoilValue(refundRight);
  const hasCreditAdjustmentRight = useRecoilValue(creditAdjustmentRight);
  const hasProcessPaymentRight = useRecoilValue(processPaymentRight);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/AccountStatus`)
      .then((res) => {
        setAccountStatusDescs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Button
        id="Header-btn-financialProcessing"
        aria-controls={open ? 'financialProcessing-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
        variant="text"
        size="small"
      >
        <Typography
          variant="h6"
          color="white"
          component="div"
          id="Header-text-financialProcessing"
          sx={{ textTransform: 'none' }}
        >
          Financial Processing
        </Typography>
      </Button>
      <Menu
        id="Header-menu-financialProcessing"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'financialProcessing-menu-list',
        }}
      >
        {hasProcessPaymentRight && hasRefundRight && hasCreditAdjustmentRight && (
          <MenuItem
            component={Link}
            id="FinancialProcessingMenu-link-financials"
            to="/financial-processing/financials"
            onClick={handleClose}
          >
            Financials
          </MenuItem>
        )}
        {hasProcessPaymentRight && (
          <MenuItem
            component={Link}
            id="FinancialProcessingMenu-link-payments"
            to="/financial-processing/payments"
            onClick={handleClose}
          >
            Payments
          </MenuItem>
        )}
        {hasRefundRight && (
          <div>
            <MenuItem
              component={Link}
              id="FinancialProcessingMenu-link-reversals"
              to="/financial-processing/reversals"
              onClick={handleClose}
            >
              Reversals
            </MenuItem>
            <MenuItem
              component={Link}
              id="FinancialProcessingMenu-link-refunds"
              to="/financial-processing/refunds"
              onClick={handleClose}
            >
              Refunds
            </MenuItem>
            <MenuItem
              component={Link}
              id="FinancialProcessingMenu-link-chargebacks"
              to="/financial-processing/chargebacks"
              onClick={handleClose}
            >
              Chargebacks
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
}
