import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { convertToDateMMddyyyy } from 'utilities/format';
import { useRecoilValue } from 'recoil';
import { accountStatusDescsAtom } from 'utilities/State';
import FinancialDetailFormDialog from 'components/Account/FinancialProcessing/FinancialDetailFormDialog';

const DataGridCell = ({ id, width, children, center = false, rowHeight }) => (
  <TableCell
    id={id}
    style={{
      width: `${width}px`,
      justifyContent: !center ? 'left' : 'center',
      display: 'flex',
      alignItems: 'center',
      paddingTop: '5px',
      paddingBottom: '5px',
      overflow: 'hidden',
      height: `${rowHeight}px`,
    }}
  >
    {id.includes('finAmount') ? `$${children?.toFixed(2)}` : children}
  </TableCell>
);

const DateCell = ({ id, width, children, rowHeight }) => (
  <DataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? convertToDateMMddyyyy(children) : '-'}
  </DataGridCell>
);

// eslint-disable-next-line react/prop-types
const AccountStatusCell = ({ id, width, children, rowHeight, open, setOpen, reversal }) => (
  <DataGridCell id={id} width={width} rowHeight={rowHeight}>
    {useRecoilValue(accountStatusDescsAtom)[children ? children.toString() : '-'] ?? children}
    <FinancialDetailFormDialog
      financial={reversal}
      parentId="FinancialDetailsForm-reversals"
      open={open}
      setOpen={setOpen}
    />
  </DataGridCell>
);

const ReversalFjNoCell = ({ id, width, children, rowHeight }) => (
  <DataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children !== 0 ? children : ''}
  </DataGridCell>
);

const CELL_TYPE = {
  finDate: DateCell,
  accountStatus: AccountStatusCell,
  reversalFjNo: ReversalFjNoCell,
};

export default function ReversalSearchCard(props) {
  const { reversal, index } = props;
  const [open, setOpen] = useState(false);

  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, [props.renderedColumns]);

  return (
    <TableRow
      id={`ReversalSearch-tableRow-reversal-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`ReversalSearch-tableCell-reversal-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            reversal={reversal}
            index={index}
            rowHeight={props.rowHeight}
            open={open}
            setOpen={setOpen}
          >
            {reversal[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

ReversalSearchCard.propTypes = {
  reversal: PropTypes.shape({
    fjNo: PropTypes.number.isRequired,
    finDate: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    accountId: PropTypes.number.isRequired,
    accountNumber: PropTypes.string.isRequired,
    finAmount: PropTypes.number.isRequired,
    accountStatus: PropTypes.number.isRequired,
    accountEffect: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    pnRef: PropTypes.string,
    message: PropTypes.string,
    reversalFjNo: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  renderedColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowHeight: PropTypes.number.isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node,
  center: PropTypes.bool,
};

DataGridCell.defaultProps = {
  center: false,
  children: null,
};

DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

ReversalFjNoCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

AccountStatusCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
