import { useEffect } from 'react';
import axios from 'utilities/authAxios';
import {
  caseStatusDescsAtom,
  caseSourceDescsAtom,
  casePriorityDescsAtom,
} from 'utilities/caseManagementState';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Alert from 'components/Custom/Alert';
import CaseSearchGrid from './CaseSearchGrid';

function CaseSearchPage() {
  const [caseStatusDescs, setCaseStatusDescs] = useRecoilState(caseStatusDescsAtom);
  const setCaseSourceDescs = useSetRecoilState(caseSourceDescsAtom);
  const [casePriorityDescs, setCasePriorityDescs] = useRecoilState(casePriorityDescsAtom);

  const lookupStatus = () => {
    Promise.all([
      axios.get(`${process.env.REACT_APP_API_URL}/Lookup/Case/Source`),
      axios.get(`${process.env.REACT_APP_API_URL}/Lookup/Case/Priority`),
      axios.get(`${process.env.REACT_APP_API_URL}/Lookup/Case/Status`),
    ])
      .then(([{ data: caseSource }, { data: casePriority }, { data: caseStatus }]) => {
        Promise.all([
          setCaseSourceDescs(caseSource),
          setCasePriorityDescs(casePriority),
          setCaseStatusDescs(caseStatus),
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    lookupStatus();
  }, []);

  return (
    <Alert childrenId="Cases" customStyle={{ width: '40%', mb: '16px' }}>
      <CaseSearchGrid caseStatusDescs={caseStatusDescs} casePriorityDescs={casePriorityDescs} />
    </Alert>
  );
}

export default CaseSearchPage;
