/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import FormDialog from 'components/Custom/FormDialog';
import { convertToDateMMddyyyy } from 'utilities/format';
import ReversalDialogForm from 'components/Custom/ReversalDialogForm';
import { useRecoilValue } from 'recoil';
import { accountStatusDescsAtom } from 'utilities/State';
import { processPaymentRight } from 'utilities/userRole';
import CustomTextDataGridCell from 'components/Custom/CustomTextDataGridCell';

const DataGridCell = ({ id, width, children, center, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} center={center} rowHeight={rowHeight}>
    {id.includes('finAmount') ? `$${children?.toFixed(2)}` : children}
  </CustomTextDataGridCell>
);

const renderReversalDialog = (
  chargeback,
  postPaymentReversal,
  handleClickOpen,
  paymentReversalReasonCodes,
  canAccess
  // eslint-disable-next-line consistent-return
) => {
  if (chargeback.reversalFJNo === null && canAccess) {
    return (
      <FormDialog
        formId="Chargeback"
        buttonIcon={<SettingsBackupRestoreIcon />}
        dialogTitle="Chargeback"
        handleParentOpen={handleClickOpen}
      >
        <ReversalDialogForm
          reasonCodes={paymentReversalReasonCodes}
          transaction={chargeback}
          postReversal={postPaymentReversal}
          parentId="ChargebackReversal"
        />
      </FormDialog>
    );
  }
};

const ActionCell = ({
  id,
  width,
  chargeback,
  postPaymentReversal,
  handleClickOpen,
  rowHeight,
  paymentReversalReasonCodes,
  canAccess,
}) => (
  <DataGridCell id={id} width={width} center rowHeight={rowHeight}>
    {renderReversalDialog(
      chargeback,
      postPaymentReversal,
      handleClickOpen,
      paymentReversalReasonCodes,
      canAccess
    )}
  </DataGridCell>
);

const DateCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? convertToDateMMddyyyy(children) : '-'}
  </CustomTextDataGridCell>
);

const AccountStatusCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {useRecoilValue(accountStatusDescsAtom)[children ? children.toString() : '-'] ?? children}
  </CustomTextDataGridCell>
);

const CELL_TYPE = {
  action: ActionCell,
  chargebackDate: DateCell,
  createdDate: DateCell,
  accountStatus: AccountStatusCell,
};

export default function ChargebackSearchCard(props) {
  const { chargeback, index, postPaymentReversal, paymentReversalReasonCodes } = props;
  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, [props.renderedColumns]);

  const hasProcessPaymentRight = useRecoilValue(processPaymentRight);

  return (
    <TableRow
      id={`ChargebackSearch-tableRow-chargeback-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`ChargebackSearch-tableCell-chargeback-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            chargeback={chargeback}
            index={index}
            postPaymentReversal={postPaymentReversal}
            presentParentAlert={props.presentParentAlert}
            rowHeight={props.rowHeight}
            paymentReversalReasonCodes={paymentReversalReasonCodes}
            canAccess={hasProcessPaymentRight}
          >
            {chargeback[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

ChargebackSearchCard.propTypes = {
  chargeback: PropTypes.shape({
    fjNo: PropTypes.number.isRequired,
    finDate: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    accountStatus: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    finAmount: PropTypes.number.isRequired,
    accountEffect: PropTypes.string.isRequired,
    pnRef: PropTypes.string.isRequired,
    reversalFjNo: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  postPaymentReversal: PropTypes.func.isRequired,
  paymentReversalReasonCodes: PropTypes.arrayOf(
    PropTypes.shape({
      finTransTypeId: PropTypes.number.isRequired,
      formText: PropTypes.string.isRequired,
      reasonCodeDesc: PropTypes.string.isRequired,
      reasonCodeId: PropTypes.number.isRequired,
    })
  ).isRequired,
  renderedColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowHeight: PropTypes.number.isRequired,
  presentParentAlert: PropTypes.func.isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

DataGridCell.defaultProps = {
  center: false,
};

DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

AccountStatusCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

ActionCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};
