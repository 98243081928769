import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormDialog from 'components/Custom/FormDialog';
import EditIcon from '@mui/icons-material/Edit';
import DeleteConfirmationDialog from 'components/Custom/DeleteConfirmationDialog';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { demographicRight } from 'utilities/userRole';
import UpdateAddressDialogForm from './UpdateAddressDialogForm';

export default function AddressCard(props) {
  const { address, getAddressTypeDesc, index } = props;
  const hasDemographicRight = useRecoilValue(demographicRight);

  return (
    <TableRow id={`Addresses-tableRow-address-${index}`}>
      <TableCell id={`Addresses-tableCell-address-${index}-isPrimary`}>
        {address.isPrimary ? 'Primary' : 'None'}
      </TableCell>
      <TableCell id={`Addresses-tableCell-address-${index}-addressType`}>
        {getAddressTypeDesc(address.addressType)}
      </TableCell>
      <TableCell id={`Addresses-tableCell-address-${index}-firstName`}>
        {address.firstName}
      </TableCell>
      <TableCell id={`Addresses-tableCell-address-${index}-lastName`}>{address.lastName}</TableCell>
      <TableCell id={`Addresses-tableCell-address-${index}-addressLine1`}>
        {address.addressLine1}
      </TableCell>
      <TableCell id={`Addresses-tableCell-address-${index}-addressLine2`}>
        {address.addressLine2}
      </TableCell>
      <TableCell id={`Addresses-tableCell-address-${index}-city`}>{address.city}</TableCell>
      <TableCell id={`Addresses-tableCell-address-${index}-stateProvinceCode`}>
        {address.stateProvinceCode}
      </TableCell>
      <TableCell id={`Addresses-tableCell-address-${index}-countryCode`}>
        {address.countryCode}
      </TableCell>
      <TableCell id={`Addresses-tableCell-address-${index}-postalCode`}>
        {address.postalCode}
      </TableCell>
      {hasDemographicRight && (
        <TableCell
          padding="none"
          align="center"
          id={`Addresses-tableCell-address-${index}-updateAddress`}
        >
          <FormDialog formId="UpdateAddress" buttonIcon={<EditIcon />} dialogTitle="Update Address">
            <UpdateAddressDialogForm
              address={address}
              updateAddress={props.updateAddress}
              addressTypes={props.addressTypes}
              provinces={props.provinces}
            />
          </FormDialog>
        </TableCell>
      )}
      {hasDemographicRight && (
        <TableCell padding="none" align="center">
          {hasDemographicRight && (
            <DeleteConfirmationDialog
              buttonId={`Addresses-tableCell-address-${index}-removeAddress`}
              dialogTitle="Remove address"
              title={`Remove Address - ${address.addressLine1}`}
              description="Are you sure you want to remove this address?"
              handleConfirmation={(success) => {
                if (success) {
                  if (address.isPrimary === true) {
                    props.presentAlert('error', 'Cannot remove primary address');
                  } else {
                    props.removeAddress(address.addressId);
                  }
                }
              }}
            />
          )}
        </TableCell>
      )}
    </TableRow>
  );
}

AddressCard.propTypes = {
  address: PropTypes.shape({
    addressType: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    addressId: PropTypes.number.isRequired,
    isPrimary: PropTypes.bool.isRequired,
    addressTypeId: PropTypes.number.isRequired,
    lastName: PropTypes.string.isRequired,
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    stateProvinceCode: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }).isRequired,
  updateAddress: PropTypes.func.isRequired,
  addressTypes: PropTypes.objectOf(PropTypes.string).isRequired,
  provinces: PropTypes.arrayOf(
    PropTypes.shape({
      stateProvinceCode: PropTypes.string.isRequired,
      stateName: PropTypes.string.isRequired,
    })
  ).isRequired,
  getAddressTypeDesc: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  presentAlert: PropTypes.func.isRequired,
  removeAddress: PropTypes.func.isRequired,
};
