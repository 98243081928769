import { DataGrid } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import buildURLParams from 'utilities/network';
import axios from 'utilities/authAxios';

LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_X_LICENSE}`);

function buildParams(state, extra) {
  const obj = {
    pageNumber: state.pageNumber + 1,
    pageSize: state.pageSize,
    sortColumn: state.sort?.field,
    sortOrder: state.sort?.order,
    ...extra,
    searchBy: state.filter?.type,
    searchString: state.filter?.keyword,
    startDate: state.filter?.startDate,
    endDate: state.filter?.endDate,
  };
  const payload = Object.entries(obj).reduce((acc, [key, val]) => {
    if (val != null) {
      acc[key] = val;
    }
    return acc;
  }, {});

  return buildURLParams(payload);
}

export const createQuery = ({
  presentAlert,
  // dismissAlert,
  setLoading,
  extraParams,
  url,
  retrieveOnPageLoad = true,
  loading = false,
}) =>
  function query(state) {
    if (retrieveOnPageLoad === false && state.filter?.keyword === undefined) {
      setLoading(false);
      return Promise.resolve(0);
    }
    if (loading) setLoading(true);
    return axios
      .get(url, {
        params: buildParams(state, extraParams),
      })
      .then((res) => {
        if (res.data.length === 0) {
          presentAlert('error', 'No results found');
          setLoading(false);
          return null;
        }
        // dismissAlert();
        setLoading(false);
        return {
          data: res.data,
          pageNumber: state.pageNumber,
          totalCount: res.data[0].totalCount,
        };
      })
      .catch(() => {
        presentAlert('error', 'Could not connect to API');
      });
  };

function DataGridTable(props) {
  const emptyArr = [];
  const getDataRowId = (rowData) =>
    (props.grouping
      ? rowData[Object.keys(rowData)[0]] + rowData[Object.keys(rowData)[1]]
      : rowData[Object.keys(rowData)[0]]) ?? rowData.dummyId;
  const sortModel = useMemo(
    () => [{ field: props.sort.field, sort: props.sort.order }],
    [props.sort.field, props.sort.order]
  );
  const handleSort = useCallback(
    // eslint-disable-next-line consistent-return
    (newModel) => {
      if (newModel.length > 0) {
        return props.onSortModelChange({ field: newModel[0].field, order: newModel[0].sort });
      }
    },
    [props.onSortModelChange]
  );

  if (props.grouping) {
    return (
      <div id="DataGrid-table">
        <DataGridPro
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props.groupingObj}
          getRowId={getDataRowId}
          initialState={{
            rowGrouping: {
              model: props.groupingModel,
            },
          }}
          experimentalFeatures={{
            rowGrouping: true,
          }}
          columns={props.columns}
          autoHeight
          density="compact"
          disableColumnMenu
          components={props.overridingComponent}
          onPageChange={props.onPageChange}
          onPageSizeChange={props.onPageSizeChange}
          pagination
          rowCount={props.totalCount}
          pageSize={props.pageSize}
          page={props.pageNumber}
          loading={props.loading}
          sortModel={sortModel}
          onSortModelChange={handleSort}
          sortingOrder={['asc', 'desc']}
          getTreeDataPath={props.getTreeDataPath}
          groupingColDef={props.groupingColDef}
        />
      </div>
    );
  }
  return (
    <div id="DataGrid-table">
      <DataGrid
        autoHeight
        density="compact"
        disableColumnMenu
        columns={props.columns}
        rows={props.loading ? emptyArr : props.data}
        components={props.overridingComponent}
        getRowId={getDataRowId}
        onPageChange={props.onPageChange}
        onPageSizeChange={props.onPageSizeChange}
        rowCount={props.totalCount}
        pageSize={props.pageSize}
        page={props.pageNumber}
        loading={props.loading}
        disableVirtualization
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSort}
        sortingOrder={['asc', 'desc']}
        disableColumnFilter
        hideFooter={props.hideFooter}
      />
    </div>
  );
}

DataGridTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onSortModelChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  overridingComponent: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  grouping: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  groupingObj: PropTypes.object,
  groupingModel: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  getTreeDataPath: PropTypes.elementType,
  // eslint-disable-next-line react/forbid-prop-types
  groupingColDef: PropTypes.object,
  hideFooter: PropTypes.bool,
};

DataGridTable.defaultProps = {
  totalCount: 0,
  grouping: false,
  groupingObj: null,
  groupingModel: [],
  getTreeDataPath: null,
  groupingColDef: null,
  hideFooter: false,
};

export default DataGridTable;
