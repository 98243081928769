import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { convertToDateTime } from 'utilities/format';

export default function DescriptionDialog(props) {
  const { description, dialogTitle, correspondence } = props;

  const handleClose = () => {
    props.setOpen();
  };

  return (
    <div>
      <Dialog fullWidth open={props.open} onClose={handleClose} id="Description-dialog">
        <DialogTitle>{dialogTitle}</DialogTitle>
        <div>
          <DialogContent>
            {dialogTitle === 'Resent Information' ? (
              <Typography variant="body1" id="Description-body-description">
                Last Resent Notification Date:{' '}
                {convertToDateTime(correspondence.lastResentNotificationDt)} EST
                <br />
                Last Resent Created By: {correspondence.resentCreatedBy}
              </Typography>
            ) : (
              <Typography variant="body1" id="Description-body-description">
                {description}
              </Typography>
            )}
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

DescriptionDialog.propTypes = {
  description: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  correspondence: PropTypes.shape({
    correspondenceLogId: PropTypes.number.isRequired,
    eventDesc: PropTypes.string.isRequired,
    notificationDt: PropTypes.string.isRequired,
    notificationStatusDesc: PropTypes.string.isRequired,
    lastUpdBy: PropTypes.number.isRequired,
    eventCode: PropTypes.string.isRequired,
    functionality: PropTypes.string.isRequired,
    lastResentNotificationDt: PropTypes.string.isRequired,
    resentCreatedBy: PropTypes.string.isRequired,
  }).isRequired,
};
