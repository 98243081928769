import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import WithLoader from 'components/Custom/WithLoader';
import SearchBar from 'components/Custom/SearchBar';
import DataGridTable, { createQuery } from 'components/Custom/DataGridTable';
import DataGridPagination from 'components/Custom/DataGridPagination';
import useTableState from 'hooks/useTableState';
import { useGridApiRef, GridRow } from '@mui/x-data-grid-pro';
import FinancialSearchCard from './FinancialSearchCard';
import CustomGroupingCell from './CustomGroupingCell';
import COLUMNS from './FinancialSearchColumns';

const INITIAL_STATE = {
  pageNumber: 0,
  pageSize: 50,
  sort: { field: 'fjNo', order: 'desc' },
  filter: undefined,
};

const INITIAL_GROUPING_COLUMN_MODEL = ['finTransTypeDesc'];

const transactionType = 'Financials';

const useKeepGroupingColumnsHidden = (apiRef, columns, initialModel) => {
  return useMemo(
    () =>
      columns.map((colDef) =>
        initialModel.includes(colDef.field) ? { ...colDef, hide: true } : colDef
      ),
    [columns, initialModel]
  );
};

function FinancialSearchGrid(props) {
  const { accountId } = useParams();
  const queryFinancials = useMemo(
    () =>
      createQuery({
        extraParams: { transactionType },
        presentAlert: props.presentAlert,
        dismissAlert: props.dismissAlert,
        setLoading: props.setLoading,
        url: `${process.env.REACT_APP_API_URL}/Financial/GetFinancialsByTransactionType`,
      }),
    [accountId]
  );

  const {
    data,
    totalCount,
    loading,
    onSortChange,
    onFilterChange,
    onPageChange,
    onPageSizeChange,
    onRefresh,
    sort,
    pageNumber,
    pageSize,
  } = useTableState(INITIAL_STATE, queryFinancials);

  const groupingObj = {
    columns: COLUMNS,
    rows: data,
  };

  const apiRef = useGridApiRef();
  const columns = useKeepGroupingColumnsHidden(apiRef, COLUMNS, INITIAL_GROUPING_COLUMN_MODEL);

  const groupingObjData = { ...groupingObj };

  const clearSearch = useCallback(() => {
    onFilterChange(undefined);
  }, [onFilterChange]);

  const handleSearchSubmit = useCallback(
    (searchBy, searchString = '', startDate = '', endDate = '') => {
      onFilterChange({
        type: searchBy,
        keyword: searchString,
        startDate,
        endDate,
      });
    },
    []
  );

  const components = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      Row: (rowProps) =>
        Object.keys(rowProps.row).length ? (
          <FinancialSearchCard
            accountId={accountId}
            key={rowProps.row.fjNo}
            presentAlert={props.presentAlert}
            financial={rowProps.row}
            index={rowProps.index}
            renderedColumns={rowProps.renderedColumns}
            rowHeight={rowProps.rowHeight}
          />
        ) : (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <GridRow {...rowProps} />
        ),
      // eslint-disable-next-line
      Pagination: (props) => <DataGridPagination {...props} onRefresh={onRefresh} />,
    }),
    [accountId, props.presentAlert]
  );

  const getTreeDataPath = (row) => row.finTransTypeDesc;

  const groupingColDef = {
    headerName: 'Transaction Type',
    // eslint-disable-next-line react/jsx-props-no-spreading
    renderCell: (params) => <CustomGroupingCell {...params} />,
  };

  return (
    <div>
      <Typography sx={{ mb: 2 }} variant="h5" id="Financials-text-header">
        Financials
      </Typography>
      {!props.loading && (
        <Stack direction="column" spacing={2}>
          <SearchBar
            parentId="Financial"
            handleSearchSubmit={handleSearchSubmit}
            clearSearch={clearSearch}
            searchTypes={['Account Number', 'FjNo', 'Amount', 'Date']}
          />
          <DataGridTable
            columns={columns}
            loading={loading}
            data={data}
            overridingComponent={components}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRefresh={onRefresh}
            totalCount={totalCount}
            sort={sort}
            onSortModelChange={onSortChange}
            groupingModel={INITIAL_GROUPING_COLUMN_MODEL}
            groupingObj={groupingObjData}
            grouping
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
          />
        </Stack>
      )}
    </div>
  );
}

FinancialSearchGrid.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
};

export default WithLoader(FinancialSearchGrid);
