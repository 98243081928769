import DataGridHeader from 'components/Custom/DataGridHeader';

const COLUMNS = [
  {
    field: 'tollTransactionId',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="tollTransactionId" heading="Trans Id" />,
  },
  {
    field: 'plateNumber',
    flex: 1.5,
    renderHeader: () => <DataGridHeader columnId="plateNumber" heading="Plate #" />,
    sortable: false,
  },
  {
    field: 'vin',
    flex: 2,
    renderHeader: () => <DataGridHeader columnId="vin" heading="VIN" />,
    sortable: false,
  },
  {
    field: 'entryTransDt',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="entryTransDt" heading="Trans Date" />,
    sortable: true,
  },
  {
    field: 'postDt',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="postDt" heading="Post Date" />,
    sortable: true,
  },
  {
    field: 'totalMiles',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="totalMiles" heading="Total Miles" />,
    sortable: false,
  },
  {
    field: 'price',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="price" heading="Price" />,
    sortable: false,
  },
  {
    field: 'chargedMiles',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="chargedMiles" heading="Charged Miles" />,
    sortable: false,
  },
  {
    field: 'amountPaid',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="amountPaid" heading="Charged" />,
    sortable: false,
  },
  {
    field: 'transTypeDesc',
    flex: 1.5,
    renderHeader: () => <DataGridHeader columnId="transTypeDesc" heading="Trans Type" />,
    sortable: false,
  },
  {
    field: 'fjNo',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="fjNo" heading="FJ #" />,
    sortable: false,
  },
  {
    field: 'isReversed',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="isReversed" heading="Reversed" />,
    sortable: false,
  },
  {
    field: 'action',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="actionId" heading="Action" />,
    sortable: false,
  },
];

export default COLUMNS;
