import { useEffect, useState } from 'react';
import axios from 'utilities/authAxios';
import PropTypes from 'prop-types';
import { redirectTo, refundRight } from 'utilities/userRole';
import Alert from 'components/Custom/Alert';
import WithLoader from 'components/Custom/WithLoader';
import { useRecoilValue } from 'recoil';
import ReversalSearchGrid from './ReversalSearchGrid';

function ReversalSearchPage(props) {
  const hasRefundRight = useRecoilValue(refundRight);
  if (!hasRefundRight) {
    redirectTo('/');
  }
  const [accountStatusDescs, setAccountStatusDescs] = useState({});

  useEffect(() => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/AccountStatus`)
      .then((res) => {
        props.setLoading(false);
        setAccountStatusDescs(res.data);
      })
      .catch(() => props.presentError('Server could not be reached. Please refresh the page.'));
  }, []);

  return (
    !props.loading && (
      <Alert childrenId="Reversals" customStyle={{ width: '40%', mb: '16px' }}>
        <ReversalSearchGrid accountStatusDescs={accountStatusDescs} />
      </Alert>
    )
  );
}

ReversalSearchPage.propTypes = {
  setLoading: PropTypes.func.isRequired,
  presentError: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default WithLoader(ReversalSearchPage);
