import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import FormDialog from 'components/Custom/FormDialog';
import { useRecoilValue } from 'recoil';
import { convertToDateMMddyyyy } from 'utilities/format';
import { deviceRight } from 'utilities/userRole';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ChangeDeviceTypeDialogForm from './ChangeDeviceTypeDialogForm';
import UpdateDeviceDialogForm from './UpdateDeviceDialogForm';
import DeviceHeartbeat from './DeviceHeartbeat';

export default function AccountDeviceCard(props) {
  const {
    device,
    index,
    getTagStatusDesc,
    getProductDesc,
    productDescs,
    tagStatusDescs,
    updateDevice,
    changeDeviceType,
  } = props;
  const hasDeviceRight = useRecoilValue(deviceRight);
  return (
    <TableRow id={`AccountDevices-tableRow-accountDevice-${index}`}>
      <TableCell
        style={{ width: '280px' }}
        id={`AccountDevices-tableCell-accountDevice-${index}-tagSerialNumber`}
      >
        {device.tagSerialNumber}
      </TableCell>
      <TableCell
        style={{ width: '170px' }}
        id={`AccountDevices-tableCell-accountDevice-${index}-vin`}
      >
        {device.vin}
      </TableCell>
      <TableCell id={`AccountDevices-tableCell-accountDevice-${index}-plateNumber`}>
        {device.plateNumber}
      </TableCell>
      <TableCell id={`AccountDevices-tableCell-accountDevice-${index}-plateStatusDesc`}>
        {device.plateStatusDesc}
      </TableCell>
      <TableCell id={`AccountDevices-tableCell-accountDevice-${index}-productDesc`}>
        {device.productDesc}
      </TableCell>
      <TableCell id={`AccountDevices-tableCell-accountDevice-${index}-tagStatusDesc`}>
        {device.tagStatusDesc}
      </TableCell>
      <TableCell
        padding="none"
        align="center"
        id={`AccountDevices-tableCell-accountDevice-${index}-heartbeat`}
      >
        <DeviceHeartbeat heartbeat={device.heartbeat} device={device} />
      </TableCell>
      <TableCell id={`AccountDevices-tableCell-accountDevice-${index}-tagStatusDate`}>
        {device.tagStatusDate === null ? '' : convertToDateMMddyyyy(device.tagStatusDate)}
      </TableCell>
      <TableCell id={`AccountDevices-tableCell-accountDevice-${index}-isLocationTracked`}>
        {device.isLocationTracked.toString()}
      </TableCell>
      <TableCell id={`AccountDevices-tableCell-accountDevice-${index}-startDate`}>
        {device.startDate === null ? '' : convertToDateMMddyyyy(device.startDate)}
      </TableCell>
      <TableCell id={`AccountDevices-tableCell-accountDevice-${index}-endDate`}>
        {device.endDate === null ? '' : convertToDateMMddyyyy(device.endDate)}
      </TableCell>
      <TableCell
        padding="none"
        align="center"
        id={`AccountDevices-tableCell-accountDevice-${index}-updateDevice`}
        sx={{ minWidth: '32px' }}
      >
        {hasDeviceRight &&
        device.tagStatusDesc !== 'RETURNED' &&
        device.productDesc !== 'Telematic' ? (
          <FormDialog formId="UpdateDevice" buttonIcon={<EditIcon />} dialogTitle="Update Device">
            <UpdateDeviceDialogForm
              productDescs={productDescs}
              tagStatusDescs={tagStatusDescs}
              getProductDesc={getProductDesc}
              device={device}
              getTagStatusDesc={getTagStatusDesc}
              updateDevice={updateDevice}
            />
          </FormDialog>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell
        padding="none"
        align="center"
        id={`AccountDevices-tableCell-accountDevice-${index}-changeDeviceType`}
        sx={{ minWidth: '32px' }}
      >
        {hasDeviceRight &&
        device.endDate === null &&
        device.productDesc === 'Telematic' &&
        (device.accountStatusDesc === 'Valid' || device.accountStatusDesc === 'Pending Open') &&
        (device.plateStatusDesc === 'Pending Activation' || device.plateStatusDesc === 'Active') ? (
          <FormDialog
            formId="ChangeDeviceType"
            buttonIcon={<SwapHorizIcon />}
            dialogTitle="Change Device Type"
          >
            <ChangeDeviceTypeDialogForm
              productDescs={productDescs}
              getProductDesc={getProductDesc}
              device={device}
              changeDeviceType={changeDeviceType}
            />
          </FormDialog>
        ) : (
          ''
        )}
      </TableCell>
    </TableRow>
  );
}

AccountDeviceCard.propTypes = {
  device: PropTypes.shape({
    tagSerialNumber: PropTypes.string.isRequired,
    vin: PropTypes.string.isRequired,
    plateNumber: PropTypes.string.isRequired,
    tagStatusId: PropTypes.number.isRequired,
    plateStatusId: PropTypes.number.isRequired,
    salesOrderStatusId: PropTypes.number.isRequired,
    productId: PropTypes.number,
    productDesc: PropTypes.string.isRequired,
    plateStatusDesc: PropTypes.string.isRequired,
    accountStatusDesc: PropTypes.string.isRequired,
    tagStatusDesc: PropTypes.string.isRequired,
    salesOrderStatusDesc: PropTypes.string.isRequired,
    tagStatusDate: PropTypes.string,
    isLocationTracked: PropTypes.bool.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    heartbeat: PropTypes.shape({
      mroId: PropTypes.string,
      mroHealth: PropTypes.number,
      lastTripTransmissionDate: PropTypes.string,
      lastCommunicationDate: PropTypes.number,
    }),
  }).isRequired,
  productDescs: PropTypes.objectOf(PropTypes.string).isRequired,
  tagStatusDescs: PropTypes.objectOf(PropTypes.string).isRequired,
  index: PropTypes.number.isRequired,
  updateDevice: PropTypes.func.isRequired,
  changeDeviceType: PropTypes.func.isRequired,
  getTagStatusDesc: PropTypes.func.isRequired,
  getProductDesc: PropTypes.func.isRequired,
};
