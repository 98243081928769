/* eslint-disable no-undef */
import 'amazon-connect-streams';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import RemoveIcon from '@mui/icons-material/Remove';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';

function CCP() {
  const [showCallCenter, setShowCallCenter] = useState(false);
  const [ccpLoaded, setCcpLoaded] = useState(false);
  const history = useHistory();
  const instanceBase = process.env.REACT_APP_AMAZON_CONNECT_INSTANCE_BASE;
  const checkURL = instanceBase.endsWith('awsapps.com');
  let ccpSuffix;
  let loginSuffix;

  if (checkURL === true) {
    ccpSuffix = '/connect/ccp-v2/';
    loginSuffix = '/connect/login';
  } else {
    ccpSuffix = '/ccp-v2/';
    loginSuffix = '/login';
  }

  const instanceURL = instanceBase + ccpSuffix;
  const instanceLogin = instanceBase + loginSuffix;

  const handleClick = () => {
    if (ccpLoaded) {
      setShowCallCenter(!showCallCenter);
    }
  };

  let loginWindow = null;

  const handleLogin = () => {
    loginWindow = window.open(instanceLogin);
  };

  const renderButton = () => {
    if (showCallCenter) {
      return <RemoveIcon />;
    }
    if (!showCallCenter && !ccpLoaded) {
      return 'Login';
    }
    return <HeadphonesIcon className="ccp-icon" />;
  };

  useEffect(() => {
    connect.agentApp.initApp('ccp', 'ccp-div-frame', instanceURL, {
      ccpParams: {
        loginPopup: false,
        loginPopupAutoClose: false,
        loginUrl: instanceLogin,
        softphone: {
          allowFramedSoftphone: true,
        },
      },
    });

    const eventBus = connect.core.getEventBus();
    eventBus.subscribe(connect.EventType.TERMINATED, () => {
      setShowCallCenter(false);
      setCcpLoaded(false);
      window.location.reload();
    });

    connect.core.onInitialized(() => {
      setShowCallCenter(true);
      setCcpLoaded(true);
      loginWindow?.close();
    });

    connect.contact((contact) => {
      setShowCallCenter(true);
      // eslint-disable-next-line no-shadow
      contact.onConnecting(() => {
        const attributeMap = contact.getAttributes();
        const baseURL = attributeMap.screenPopURL.value;
        const searchString = attributeMap.screenPopValue.value;
        const screenpopURL = baseURL + searchString;
        history.push(screenpopURL);
      });
    });
  }, []);

  return (
    <div id="ccp-wrapper">
      <Box className="chatbox-top" onClick={handleClick} style={{ cursor: 'pointer' }}>
        <Button
          id="ccp-button"
          type="button"
          disableRipple
          onClick={() => {
            if (!showCallCenter && !ccpLoaded) handleLogin();
          }}
          style={{ color: 'white' }}
        >
          {renderButton()}
        </Button>
      </Box>
      <div id="ccp-div-frame" style={{ display: showCallCenter ? '' : 'none' }} />
    </div>
  );
}

export default CCP;
