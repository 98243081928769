/* eslint-disable import/prefer-default-export */
import { atom } from 'recoil';

export const accountStatusDescsAtom = atom({
  key: 'accountStatusDescs',
  default: {},
});

export const currentActiveAlert = atom({
  key: 'activeAlert',
  default: { dismiss: undefined, alertId: '' },
});

export const refreshAccountInfo = atom({
  key: 'refreshAccountInfo',
  default: false,
});

export const searchTermAtom = atom({
  key: 'searchTermAtom',
  default: '',
});

export const isGlobalSearchAtom = atom({
  key: 'isGlobalSearchAtom',
  default: false,
});

export const noteCallReasonAtom = atom({
  key: 'noteCallReasonAtom',
  default: [],
});

export const accountRebillMethodAtom = atom({
  key: 'accountRebillMethod',
  default: null,
});

export const vehicleDetailsAtom = atom({
  key: 'vehicleDetailsAtom',
  default: [],
});

export const globalAccountStatusId = atom({
  key: 'globalAccountStatusId',
  default: 0,
});
