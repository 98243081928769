import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export default function LoadingOverlay() {
  return (
    <Backdrop
      id="LoadingOverlay-backdrop"
      sx={{
        backgroundColor: 'white',
        position: 'absolute',
      }}
      open
    >
      <Stack alignItems="center" spacing={2}>
        <CircularProgress />
        <Typography variant="h5">Loading</Typography>
      </Stack>
    </Backdrop>
  );
}
