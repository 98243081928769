import { useState } from 'react';
import axios from 'utilities/authAxios';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import {
  caseStatusDescsAtom,
  caseSourceDescsAtom,
  casePriorityDescsAtom,
} from 'utilities/caseManagementState';
import { useRecoilValue } from 'recoil';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { convertToDateMMddyyyy } from 'utilities/format';
import Stack from '@mui/material/Stack';

function StyledCategory(value) {
  return (
    <Typography variant="body1" id={`CaseDetails-text-${value}`} sx={{ fontWeight: 'bold' }}>
      {' '}
      {value}{' '}
    </Typography>
  );
}

function CaseDetails(props) {
  const caseStatuses = useRecoilValue(caseStatusDescsAtom);
  const casePriorities = useRecoilValue(casePriorityDescsAtom);
  const caseSources = useRecoilValue(caseSourceDescsAtom);
  const { caseObj } = props;

  const [state, setState] = useState({
    caseType: caseObj?.caseType,
    description: caseObj?.description,
    caseStatusId: caseObj?.caseStatusId,
    dueDate: caseObj?.dueDate,
    casePriorityId: caseObj?.casePriorityId,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedCase = { ...state };
    axios
      .post(`${process.env.REACT_APP_API_URL}/CaseManagement/Case/Update`, updatedCase)
      .then((res) => {
        if (res.data !== null) {
          props.presentAlert('success', 'Case has been updated');
        } else {
          props.presentAlert('error', 'Failed to update case');
        }
      })
      .catch((err) => {
        props.presentAlert('error', 'Failed to update case');
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const caseStatusesSelects = Object.entries(caseStatuses).map((entry) => {
    return (
      <MenuItem
        key={entry[0]}
        value={entry[0]}
        id={`CaseDetails-select-caseStatusValue-${entry[1]}`}
      >
        {entry[1]}
      </MenuItem>
    );
  });

  const casePrioritiesSelects = Object.entries(casePriorities).map((entry) => {
    return (
      <MenuItem
        key={entry[0]}
        value={entry[0]}
        id={`CaseDetails-select-casePriorityValue-${entry[1]}`}
      >
        {entry[1]}
      </MenuItem>
    );
  });

  return (
    <div>
      <Stack direction="column" spacing={2}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container rowSpacing={2}>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Grid container style={{ marginBottom: '5px' }}>
                  <Grid item xs={4} style={{ alignSelf: 'center' }}>
                    {StyledCategory('Case #')}
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      disabled
                      id="CaseDetails-textField-caseNumber"
                      value={caseObj?.caseNumber}
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ marginBottom: '5px' }}>
                  <Grid item xs={4} style={{ alignSelf: 'center' }}>
                    {StyledCategory('Case Type')}
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      style={{ width: '220px' }}
                      size="small"
                      disabled
                      id="CaseDetails-textField-caseType"
                      value={caseObj?.caseType}
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ marginBottom: '5px' }}>
                  <Grid item xs={4} style={{ alignSelf: 'center' }}>
                    {StyledCategory('Status')}
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      select
                      required
                      size="small"
                      id="CaseDetails-select-caseStatusId"
                      value={state.caseStatusId}
                      name="caseStatusId"
                      onChange={handleChange}
                    >
                      {caseStatusesSelects}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} style={{ alignSelf: 'center' }}>
                    {StyledCategory('Source')}
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      disabled
                      id="CaseDetails-textField-caseSourceId"
                      value={caseSources[caseObj?.caseSourceId.toString()]}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container style={{ marginBottom: '5px' }}>
                  <Grid item xs={4} style={{ alignSelf: 'center' }}>
                    {StyledCategory('Created')}
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      disabled
                      id="CaseDetails-textField-created"
                      value={convertToDateMMddyyyy(caseObj?.created)}
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ marginBottom: '5px' }}>
                  <Grid item xs={4} style={{ alignSelf: 'center' }}>
                    {StyledCategory('Assign To')}
                  </Grid>
                  <Grid item xs={8}>
                    <TextField select required size="small" id="CaseDetails-select-csr">
                      <MenuItem>Temp CSR</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container style={{ marginBottom: '5px' }}>
                  <Grid item xs={4} style={{ alignSelf: 'center' }}>
                    {StyledCategory('Priority')}
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      select
                      required
                      size="small"
                      id="CaseDetails-select-casePriorityId"
                      value={state.casePriorityId}
                      name="casePriorityId"
                      onChange={handleChange}
                    >
                      {casePrioritiesSelects}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container style={{ marginBottom: '5px' }}>
                  <Grid item xs={4} style={{ alignSelf: 'center' }}>
                    {StyledCategory('Due Date')}
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      disabled
                      id="CaseDetails-textField-dueDate"
                      value={convertToDateMMddyyyy(state.dueDate)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      size="small"
                      id="CaseDetails-btn-updateCase"
                      type="submit"
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </div>
  );
}

CaseDetails.propTypes = {
  caseObj: PropTypes.shape({
    created: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    caseStatusId: PropTypes.number.isRequired,
    casePriorityId: PropTypes.number.isRequired,
    caseSourceId: PropTypes.number.isRequired,
    caseType: PropTypes.string.isRequired,
    caseNumber: PropTypes.string.isRequired,
    dueDate: PropTypes.string,
  }).isRequired,
  presentAlert: PropTypes.func.isRequired,
};

export default CaseDetails;
