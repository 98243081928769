import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import { convertToDateMMddyyyy } from 'utilities/format';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const DataGridCell = ({ id, width, children, center = false, rowHeight }) => (
  <TableCell
    id={id}
    style={{
      width: `${width}px`,
      justifyContent: !center ? 'left' : 'center',
      display: 'flex',
      alignItems: 'center',
      paddingTop: '0px',
      paddingBottom: '0px',
      overflow: 'hidden',
      height: `${rowHeight}px`,
    }}
  >
    {children}
  </TableCell>
);

// eslint-disable-next-line react/prop-types
const ActionCell = ({ id, width, device, rowHeight }) => (
  <DataGridCell id={id} width={width} center rowHeight={rowHeight}>
    <Link
      to={{
        // eslint-disable-next-line react/prop-types
        pathname: `/account-management/${device.accountId}`,
      }}
      style={{ textDecoration: 'none', color: 'black' }}
    >
      <IconButton
        color="inherit"
        aria-label="person"
        size="small"
        id="AccountSearch-btn-openAccountMgmt"
      >
        <PersonIcon />
      </IconButton>
    </Link>
  </DataGridCell>
);

const DateCell = ({ id, width, children, rowHeight }) => (
  <DataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? convertToDateMMddyyyy(children) : '-'}
  </DataGridCell>
);

const CELL_TYPE = {
  action: ActionCell,
  shipDt: DateCell,
};

export default function DeviceSearchCard(props) {
  const { device, index } = props;

  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, [props.renderedColumns]);

  return (
    <TableRow
      id={`DeviceSearch-tableRow-device-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`DeviceSearch-tableCell-device-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            device={device}
            index={index}
            rowHeight={props.rowHeight}
          >
            {device[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

DeviceSearchCard.propTypes = {
  device: PropTypes.shape({
    accountNumber: PropTypes.string.isRequired,
    accountId: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    trackingNumber: PropTypes.string.isRequired,
    acctStatusDesc: PropTypes.string.isRequired,
    tagStatusDesc: PropTypes.string.isRequired,
    requestMode: PropTypes.string.isRequired,
    productDesc: PropTypes.string.isRequired,
    vehicleModel: PropTypes.string.isRequired,
    dayTimePhone: PropTypes.string.isRequired,
    salesOrderStatusDesc: PropTypes.string.isRequired,
    shipDt: PropTypes.string.isRequired,
    vin: PropTypes.string.isRequired,
    plateNumber: PropTypes.string.isRequired,
    tagSerialNumber: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  renderedColumns: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

DataGridCell.defaultProps = {
  center: false,
};

DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

ActionCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
};
