import { useState, useEffect } from 'react';
import axios from 'utilities/authAxios';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { vehicleDetailsAtom } from 'utilities/State';
import { amountValidation } from 'utilities/PaymentValidation';

export default function PostAccountFeeDialogForm(props) {
  const [feeTypes, setFeeTypes] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [feeOrgParams, setFeeOrgParams] = useState({});
  const { accountId } = useParams();
  const [amountError, setAmountError] = useState({ helperText: '', hasError: false });
  const [disableBtn, setDisableBtn] = useState(false);
  const [vehicleDetailState, setVehicleDetailState] = useState({
    vehicleCapAmount: 0.0,
    vehicleAccruedFeeAmount: 0.0,
  });

  const accountVehicleDetails = useRecoilValue(vehicleDetailsAtom);
  console.log(accountVehicleDetails);
  const [state, setState] = useState({
    finTransCodeId: null,
    finTransCodeKey: '',
    finTransTypeId: null,
    vehicleId: null,
    comment: '',
    amount: 0,
    accountId,
    plateId: null,
  });

  const validateAmount = (value) => {
    let amountValidationResult = '';
    amountValidationResult = amountValidation(value, vehicleDetailState.vehicleCapAmount, true);
    if (amountValidationResult !== '') {
      setAmountError({ helperText: amountValidationResult, hasError: true });
      setDisableBtn(true);
    } else {
      setAmountError({ helperText: amountValidationResult, hasError: false });
      setDisableBtn(false);
    }
  };

  const getActiveVehicleDetail = (value) => {
    return accountVehicleDetails.find((x) => x.vehicleId === value && x.isActive === true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let activeDetail;
    if (name === 'vehicleId') {
      activeDetail = getActiveVehicleDetail(value);
      setVehicleDetailState({
        vehicleCapAmount: (activeDetail.capFee - activeDetail.accruedFeeAmount).toFixed(2),
        vehicleAccruedFeeAmount: activeDetail.accruedFeeAmount.toFixed(2),
      });
    }

    if (name === 'amount') validateAmount(e.target.value);

    const getAmount = () => {
      if (value === 3) {
        if (feeOrgParams.photoLateFee === undefined) return 0;
        return feeOrgParams.photoLateFee;
      }
      if (value === 4) {
        if (feeOrgParams.deviceFee === undefined) return 0;
        return feeOrgParams.deviceFee;
      }
      return 0;
    };
    setState({
      ...state,
      amount: name === 'finTransCodeId' ? getAmount() : state.amount,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setLoading(true);
    const postFeeObj = {
      accountId: state.accountId,
      plateId: state.vehicleId,
      finTransCodeId: state.finTransCodeId,
      finTransTypeId: state.finTransTypeId,
      amount: state.amount,
      comments: state.comment,
      vehicleDetailId: 0,
    };

    const endpoint =
      state.finTransCodeId === 5 ? 'Financial/PostCapFee' : 'Financial/PostFinancial';

    axios
      .post(`${process.env.REACT_APP_API_URL}/${endpoint}`, postFeeObj)
      .then((res) => {
        // PostFinancial only returns 0 or non-0. non-0 is posted successfully
        if (res.data > 0) {
          props.setLoading(false);
          props.reloadAccountInfo();
          props.handleClose();
          // present alert in accountInfoHeader
          props.presentParentAlert('success', 'Fee was posted successfully');
        } else {
          // present alert in dialog
          props.setLoading(false);
          props.presentAlert('error', 'Fee was not posted');
        }
      })
      .catch((err) => {
        // PostCapFee endpoint might return objectResult:

        props.setLoading(false);
        if (err.response?.status !== 500) {
          props.presentAlert('error', `${err.response?.data}`);
        } else {
          props.presentAlert('error', 'Fee was not posted');
        }
      });
  };

  const feeTypesMenuItems = feeTypes.map((feeType) => {
    return (
      <MenuItem
        key={feeType.finTransCodeId}
        value={feeType.finTransCodeId}
        id={`PostAccountFee-select-feeTypeValue-${feeType.finTransCodeDesc.replaceAll(' ', '')}`}
      >
        {feeType.finTransCodeDesc}
      </MenuItem>
    );
  });

  const vehicleMenuItems = vehicles.map((vehicle) => {
    return (
      <MenuItem
        key={vehicle.vehicleId}
        value={vehicle.vehicleId}
        id={`PostAccountFee-select-vehiclePlateValue-${vehicle.plateNumber.replace(' ', '')}`}
      >
        {vehicle.plateNumber}
      </MenuItem>
    );
  });

  const getFeeTypes = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Financial/GetFinTransCodes/${'Fee'}`)
      .then((res) => {
        setFeeTypes(res.data);
        setState({
          ...state,
          finTransTypeId: res.data[0].finTransTypeId,
        });
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.setLoading(false);
        props.presentAlert('error', 'Server could not be reached. Please refresh the page.');
      });
  };

  const getVehicles = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Vehicle/GetVehicles/${accountId}`)
      .then((res) => {
        setVehicles(res.data);
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.setLoading(false);
        props.presentAlert('error', 'Server could not be reached. Please refresh the page.');
      });
  };

  const getFeeOrgParams = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Financial/GetAccountFees`)
      .then((res) => {
        setFeeOrgParams(res.data);
        props.setLoading(false);
      })
      .catch((err) => {
        props.setLoading(false);
        props.presentAlert(
          'error',
          err.response?.data || 'Server could not be reached. Please refresh the page.'
        );
      });
  };

  useEffect(() => {
    getFeeOrgParams();
    getFeeTypes();
    getVehicles();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                required
                fullWidth
                size="small"
                label="Fee Type"
                id="PostAccountFee-select-feeType"
                value={state.finTransCodeId}
                name="finTransCodeId"
                onChange={handleChange}
              >
                {feeTypesMenuItems}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                required
                fullWidth
                size="small"
                label="Vehicle"
                id="PostAccountFee-select-vehiclePlate"
                value={state.vehicleId}
                name="vehicleId"
                onChange={handleChange}
              >
                {vehicleMenuItems}
              </TextField>
              {state.finTransCodeId === 5 ? (
                <TextField
                  required
                  disabled
                  fullWidth
                  size="small"
                  label="Vehicle AccruedFee Amount"
                  variant="outlined"
                  id="PostAccountFee-textField-amount"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  style={amountError.hasError ? { marginTop: '32px' } : { marginTop: '10px' }}
                  value={vehicleDetailState.vehicleAccruedFeeAmount}
                />
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                disabled={state.finTransCodeId === 3 || state.finTransCodeId === 4}
                fullWidth
                label="Amount"
                size="small"
                variant="outlined"
                id="PostAccountFee-textField-amount"
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                helperText={amountError.helperText}
                error={amountError.hasError}
                value={state.amount}
                name="amount"
                onChange={handleChange}
              />
              {state.finTransCodeId === 5 ? (
                <TextField
                  required
                  disabled
                  fullWidth
                  size="small"
                  label="Vehicle Remaining CAP"
                  variant="outlined"
                  id="PostAccountFee-textField-amount"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  style={{ marginTop: '10px' }}
                  value={vehicleDetailState.vehicleCapAmount}
                />
              ) : null}
            </Grid>
            {/* TODO: Comment is not saved in database. Future development to add in Note */}
            <Grid item xs={12}>
              <TextField
                multiline
                fullWidth
                label="Comment"
                variant="outlined"
                minRows={10}
                placeholder="Enter Comment..."
                id="PostAccountFee-textField-comment"
                value={state.comment}
                name="comment"
                onChange={handleChange}
                onKeyDown={(e) => e.stopPropagation()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="PostAccountFee-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={state.amount === 0 || disableBtn}
            variant="contained"
            size="small"
            id="PostAccountFee-btn-post"
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

PostAccountFeeDialogForm.propTypes = {
  presentParentAlert: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  reloadAccountInfo: PropTypes.func.isRequired,
};
