import DataGridHeader from 'components/Custom/DataGridHeader';

const COLUMNS = [
  {
    field: 'correspondenceLogId',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="correspondenceLogId" heading="Id" />,
    sortable: false,
  },
  {
    field: 'eventCode',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="eventCode" heading="Event Code" />,
    sortable: false,
  },
  {
    field: 'eventDesc',
    flex: 3,
    renderHeader: () => <DataGridHeader columnId="eventDesc" heading="Event Description" />,
    sortable: false,
  },
  {
    field: 'functionality',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="function" heading="Function" />,
  },
  {
    field: 'notificationDt',
    flex: 1.5,
    renderHeader: () => (
      <DataGridHeader columnId="notificationDt" heading="Original Notification Date" />
    ),
  },
  {
    field: 'notificationStatusDesc',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="notificationStatusDesc" heading="Status" />,
    sortable: false,
  },
  {
    field: 'lastUpdBy',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="lastUpdBy" heading="Last Updated By" />,
    sortable: false,
  },
  {
    field: 'action',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="action" heading="Action" />,
    sortable: false,
  },
];

export default COLUMNS;
