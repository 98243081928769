import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

export default function UpdateContactDialogForm(props) {
  const [state, setState] = useState({
    contactId: props.contactObj.contactId,
    firstName: props.contactObj.firstName,
    lastName: props.contactObj.lastName,
    email: props.contactObj.email,
    primaryPhone: props.contactObj.primaryPhone,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // copy object inside state and assign to updatedObj
    const updatedObj = {
      ...state,
      firstName: state.firstName
        .trim()
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' '),
      lastName: state.lastName
        .trim()
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' '),
      primaryPhone: state.primaryPhone.trim(),
      email: state.email.trim(),
    };
    props.updateContact(updatedObj);
    props.handleClose();
  };

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="First Name"
                id="UpdateContact-textField-firstName"
                name="firstName"
                value={state.firstName}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="Last Name"
                id="UpdateContact-textField-lastName"
                name="lastName"
                value={state.lastName}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="Primary Phone"
                id="UpdateContact-textField-primaryPhone"
                name="primaryPhone"
                value={state.primaryPhone}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                size="small"
                label="Email"
                id="UpdateContact-textField-email"
                name="email"
                value={state.email}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="UpdateContact-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            id="UpdateContact-btn-updateContact"
            type="submit"
          >
            Update
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

UpdateContactDialogForm.propTypes = {
  contactObj: PropTypes.shape({
    contactId: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    primaryPhone: PropTypes.string.isRequired,
    isPrimary: PropTypes.bool.isRequired,
    ivrPin: PropTypes.string.isRequired,
  }).isRequired,
  updateContact: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
