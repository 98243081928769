/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';

export default function DateRangePicker(props) {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={props.startDate}
              onChange={(newValue) => {
                props.handleDateChange('start', newValue);
              }}
              renderInput={(startProps) => (
                <TextField
                  {...startProps}
                  required
                  id={`${props.location}-datePicker-textField-startDate`}
                  size="small"
                  helperText={props.helperText}
                  error={props.textFieldErrorState}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={props.endDate}
              onChange={(newValue) => {
                props.handleDateChange('end', newValue);
              }}
              renderInput={(endProps) => (
                <TextField
                  {...endProps}
                  required
                  size="small"
                  id={`${props.location}-datePicker-textField-endDate`}
                  error={props.textFieldErrorState}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
    </Grid>
  );
}

DateRangePicker.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  textFieldErrorState: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
  handleDateChange: PropTypes.func.isRequired,
};
