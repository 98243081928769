import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormDialog from 'components/Custom/FormDialog';
import EditIcon from '@mui/icons-material/Edit';
import { convertToDateMMddyyyy } from 'utilities/format';
import { useRecoilValue } from 'recoil';
import { creditAdjustmentRight } from 'utilities/userRole';
import UpdateCardDialogForm from './UpdateCardDialogForm';

export default function PaymentMethodCard(props) {
  const { paymentCards, index, getCreditCardTypeDesc, getCreditCardStatusDesc } = props;
  const hasCreditAdjustmentRight = useRecoilValue(creditAdjustmentRight);
  return (
    <TableRow id={`PaymentMethod-tableRow-card-${index}`}>
      <TableCell id={`PaymentMethod-tableCell-card-${index}-cardTypeId`}>
        {getCreditCardTypeDesc(paymentCards.cardTypeId)}
      </TableCell>
      <TableCell id={`PaymentMethod-tableCell-card-${index}-cardNum`}>
        {paymentCards.cardNum.replace(/\d(?=\d{4})/g, '*')}
      </TableCell>
      <TableCell id={`PaymentMethod-tableCell-card-${index}-nameOnCard`}>
        {paymentCards.nameOnCard}
      </TableCell>
      <TableCell id={`PaymentMethod-tableCell-card-${index}-expMonth`}>
        {paymentCards.expMonth}
      </TableCell>
      <TableCell id={`PaymentMethod-tableCell-card-${index}-expYear`}>
        {paymentCards.expYear}
      </TableCell>
      <TableCell id={`PaymentMethod-tableCell-card-${index}-cardStatusId`}>
        {getCreditCardStatusDesc(paymentCards.cardStatusId)}
      </TableCell>
      <TableCell id={`PaymentMethod-tableCell-card-${index}-priority`}>
        {paymentCards.priority}
      </TableCell>
      <TableCell id={`PaymentMethod-tableCell-card-${index}-created`}>
        {convertToDateMMddyyyy(paymentCards.created)}
      </TableCell>
      <TableCell
        padding="none"
        align="center"
        id={`Addresses-tableCell-address-${index}-updateAddress`}
      >
        {hasCreditAdjustmentRight && (
          <FormDialog formId="UpdateCard" buttonIcon={<EditIcon />} dialogTitle="Update Card">
            <UpdateCardDialogForm
              paymentCards={paymentCards}
              updateCard={props.updateCard}
              creditCardTypes={props.creditCardTypes}
            />
          </FormDialog>
        )}
      </TableCell>
    </TableRow>
  );
}

PaymentMethodCard.propTypes = {
  paymentCards: PropTypes.shape({
    cardTypeId: PropTypes.string.isRequired,
    cardNum: PropTypes.string.isRequired,
    nameOnCard: PropTypes.string.isRequired,
    expMonth: PropTypes.string.isRequired,
    expYear: PropTypes.string.isRequired,
    cardStatusId: PropTypes.string.isRequired,
    priority: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
  }).isRequired,
  updateCard: PropTypes.func.isRequired,
  creditCardTypes: PropTypes.objectOf(PropTypes.string).isRequired,
  index: PropTypes.number.isRequired,
  getCreditCardTypeDesc: PropTypes.func.isRequired,
  getCreditCardStatusDesc: PropTypes.func.isRequired,
};
