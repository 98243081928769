import { useMsal } from '@azure/msal-react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import FinancialProcessingMenu from 'components/Account/FinancialProcessing/FinancialProcessingMenu';
import Box from '@mui/material/Box';
import { useRecoilValue } from 'recoil';
import {
  accountSearchRight,
  creditAdjustmentRight,
  processPaymentRight,
  refundRight,
} from 'utilities/userRole';
import GlobalSearch from 'components/Account/AccountSearch/GlobalSearchCard';

export default function Header({ account }) {
  const { instance } = useMsal();
  const signOut = () => {
    localStorage.removeItem('roles');
    instance.logoutRedirect({ account });
  };

  const hasAccountSearchRight = useRecoilValue(accountSearchRight);
  const hasProcessPaymentRight = useRecoilValue(processPaymentRight);
  const hasrefundRight = useRecoilValue(refundRight);
  const hascreditAdjustmentRight = useRecoilValue(creditAdjustmentRight);

  return (
    <Box sx={{ pb: '56px' }}>
      <AppBar
        position="absolute"
        sx={{
          bgcolor: 'black',
        }}
      >
        <Toolbar variant="dense">
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h6" color="inherit" component="div">
              RUC AMS
            </Typography>
            {hasAccountSearchRight && (
              <Typography
                variant="h6"
                color="inherit"
                component="div"
                id="Header-link-AccountSearch"
              >
                <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
                  Account Search
                </Link>
              </Typography>
            )}

            {(hasProcessPaymentRight || hasrefundRight || hascreditAdjustmentRight) && (
              <FinancialProcessingMenu />
            )}
            <Typography
              variant="h6"
              color="inherit"
              component="div"
              id="Header-link-CaseManagement"
            >
              <Link to="/case-management" style={{ textDecoration: 'none', color: 'white' }}>
                Case Management
              </Link>
            </Typography>
            <GlobalSearch />
            <Typography
              variant="h6"
              color="inherit"
              component="div"
              id="Header-link-SignOut"
              sx={{ position: 'absolute', right: '2vh' }}
            >
              {account?.username ? account?.username : ''}
              {account !== null ? (
                <Button
                  variant="outlined"
                  onClick={signOut}
                  sx={{ color: 'white', marginLeft: '2vh' }}
                >
                  Sign Out
                </Button>
              ) : (
                ''
              )}
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

Header.propTypes = {
  account: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }).isRequired,
};
