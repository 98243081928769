import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { convertToDateMMddyyyy } from 'utilities/format';
import axios from 'utilities/authAxios';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';

export default function DeviceHeartbeat(props) {
  const [errorState, setErrorState] = useState({
    httpStatusCode: 0,
    errorType: null,
    errorCode: null,
  });
  const { heartbeat } = props;
  const isHealthy = heartbeat?.mroHealth === 0;
  const message = `Health Status: ${
    heartbeat?.mroHealth ?? '-'
  }\nLast Trip: ${convertToDateMMddyyyy(heartbeat?.lastTripTransmissionDate, false)}`;

  useEffect(() => {
    if (!isHealthy) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Device/GetSmartcarLatestErrorByVehicleId?vehicleId=${props.device.tagSerialNumber}`
        )
        .then((response) => {
          if (response != null && response !== undefined) {
            setErrorState({
              httpStatusCode: response.data.httpStatusCode,
              errorType: response.data.errorType,
              errorCode: response.data.errorCode,
            });
          }
        });
    }
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={7} id="DeviceHeartbeat-text-lastCommunicationDate">
        {convertToDateMMddyyyy(heartbeat?.lastCommunicationDate, false)}
      </Grid>
      <Grid item xs={2}>
        <Tooltip
          placement="right"
          title={<div style={{ whiteSpace: 'pre-line', fontSize: '1rem' }}>{message}</div>}
        >
          {isHealthy ? (
            <CheckIcon
              sx={{
                color: 'darkgreen',
              }}
              id="DeviceHeartbeat-icon-healthy"
            />
          ) : (
            <ClearIcon
              sx={{
                color: 'darkred',
              }}
              id="DeviceHeartbeat-icon-unhealthy"
            />
          )}
        </Tooltip>
      </Grid>
      {!isHealthy && props.device.productId === 3 && (
        <Grid item xs={12} id="DeviceHeartbeat-text-lastCommunicationDate">
          {errorState.httpStatusCode === undefined
            ? 'No error recorded'
            : `${errorState.httpStatusCode} - ${
                errorState?.httpStatusCode === 401 ? 'Authentication' : errorState.errorCode
              }`}
        </Grid>
      )}
    </Grid>
  );
}

DeviceHeartbeat.propTypes = {
  heartbeat: PropTypes.shape({
    mroId: PropTypes.string,
    mroHealth: PropTypes.number,
    lastTripTransmissionDate: PropTypes.string,
    lastCommunicationDate: PropTypes.number,
  }),
  device: PropTypes.shape({
    tagSerialNumber: PropTypes.string,
    productId: PropTypes.number.isRequired,
  }).isRequired,
};

DeviceHeartbeat.defaultProps = {
  heartbeat: null,
};
