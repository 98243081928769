import { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import { convertToDateMMddyyyy } from 'utilities/format';
import { useRecoilValue } from 'recoil';
import { accountStatusDescsAtom } from 'utilities/State';
import CustomTextDataGridCell from 'components/Custom/CustomTextDataGridCell';

const DataGridCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {id.includes('finAmount') ? `$${children?.toFixed(2)}` : children}
  </CustomTextDataGridCell>
);

const DateCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? convertToDateMMddyyyy(children) : '-'}
  </CustomTextDataGridCell>
);

const AccountStatusCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {useRecoilValue(accountStatusDescsAtom)[children ? children.toString() : '-'] ?? children}
  </CustomTextDataGridCell>
);

const CELL_TYPE = {
  finDate: DateCell,
  accountStatus: AccountStatusCell,
};

export default function RefundSearchCard(props) {
  const { refund, index } = props;
  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, [props.renderedColumns]);

  return (
    <TableRow
      id={`RefundSearch-tableRow-refund-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`RefundSearch-tableCell-refund-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            refund={refund}
            index={index}
            presentParentAlert={props.presentParentAlert}
            rowHeight={props.rowHeight}
          >
            {refund[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

RefundSearchCard.propTypes = {
  refund: PropTypes.shape({
    fjNo: PropTypes.number.isRequired,
    finDate: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    accountStatus: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    finAmount: PropTypes.number.isRequired,
    accountEffect: PropTypes.string.isRequired,
    pnRef: PropTypes.string.isRequired,
    reversalFjNo: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  renderedColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowHeight: PropTypes.number.isRequired,
  presentParentAlert: PropTypes.func.isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

AccountStatusCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
