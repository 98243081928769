import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { convertToDateTime } from 'utilities/format';
import axios from 'utilities/authAxios';
import DescriptionDialog from 'components/Custom/DescriptionDialog';
import CustomTextDataGridCell from 'components/Custom/CustomTextDataGridCell';

const DataGridCell = ({ id, width, children, center, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} center={center} rowHeight={rowHeight}>
    {children}
  </CustomTextDataGridCell>
);

// eslint-disable-next-line react/prop-types
const AttachmentCell = ({ id, width, note, downloadAttachment, rowHeight }) => (
  <DataGridCell id={id} width={width} center rowHeight={rowHeight}>
    {
      // eslint-disable-next-line react/prop-types
      note.fileName && (
        <IconButton
          color="inherit"
          aria-label="attachment"
          size="small"
          id="Notes-btn-attachment"
          onClick={downloadAttachment}
        >
          <DownloadForOfflineIcon />
        </IconButton>
      )
    }
  </DataGridCell>
);

const DateCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? convertToDateTime(children) : '-'}
  </CustomTextDataGridCell>
);

const CommentCell = ({ id, width, children, rowHeight, open, setOpen }) => (
  <CustomTextDataGridCell
    id={id}
    width={width}
    rowHeight={rowHeight}
    large
    customButton={
      <DescriptionDialog
        description={children}
        open={open}
        setOpen={setOpen}
        dialogTitle="Comment"
      />
    }
  >
    {children}
  </CustomTextDataGridCell>
);

const NoteCallReasonCell = ({ id, width, note, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {note.callReasonCode ? note.callReasonCode : note.title}
  </CustomTextDataGridCell>
);

const NoteTypeCell = ({ id, width, children, getNoteTypeDesc, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? getNoteTypeDesc(children) : '-'}
  </CustomTextDataGridCell>
);

const NoteSourceTypeCell = ({ id, width, children, getNoteSourceTypeDesc, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? getNoteSourceTypeDesc(children) : '-'}
  </CustomTextDataGridCell>
);

// Keys must match NotesColumns.jsx field names.
const CELL_TYPE = {
  attachment: AttachmentCell,
  created: DateCell, // dateCreated? check last 4 columns? css, pass as props?
  noteType: NoteTypeCell,
  sourceType: NoteSourceTypeCell,
  noteDesc: CommentCell,
  callReasonCode: NoteCallReasonCell,
};

export default function NotesCard(props) {
  const { note, index, getNoteTypeDesc, getNoteSourceTypeDesc } = props;

  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, [props.renderedColumns]);

  const downloadAttachment = () => {
    props.dismissAlert();
    axios
      .get(`${process.env.REACT_APP_API_URL}/Note/GetDocument/${note.fileName}`)
      .then((res) => {
        if (res.data !== null) {
          const url = res.data;
          window.open(url, '_blank', 'noreferrer');
        }
      })
      .catch((err) => {
        console.log(err);
        props.presentAlert('error', 'Attachment not found');
      });
  };

  const [open, setOpen] = useState(false);
  const handleDoubleClick = () => {
    setOpen(!open);
  };
  return (
    <TableRow
      onDoubleClick={handleDoubleClick}
      id={`Notes-tableRow-note-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`Notes-tableCell-note-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            note={note}
            index={index}
            downloadAttachment={downloadAttachment}
            getNoteTypeDesc={getNoteTypeDesc}
            getNoteSourceTypeDesc={getNoteSourceTypeDesc}
            rowHeight={props.rowHeight}
            open={open}
            setOpen={setOpen}
          >
            {note[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

NotesCard.propTypes = {
  note: PropTypes.shape({
    created: PropTypes.string.isRequired,
    noteId: PropTypes.number.isRequired,
    csrid: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    attachmentId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    noteDesc: PropTypes.string.isRequired,
    noteType: PropTypes.number.isRequired,
    fileName: PropTypes.string.isRequired,
    callReasonCode: PropTypes.string.isRequired,
    sourceType: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  getNoteTypeDesc: PropTypes.func.isRequired,
  getNoteSourceTypeDesc: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
  renderedColumns: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

DataGridCell.defaultProps = {
  center: false,
};

DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

AttachmentCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
};

NoteTypeCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  getNoteTypeDesc: PropTypes.func.isRequired,
};

CommentCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

NoteSourceTypeCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  getNoteSourceTypeDesc: PropTypes.func.isRequired,
};

NoteCallReasonCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  note: PropTypes.shape({
    created: PropTypes.string.isRequired,
    noteId: PropTypes.number.isRequired,
    csrid: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    attachmentId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    noteDesc: PropTypes.string.isRequired,
    noteType: PropTypes.number.isRequired,
    noteSourceType: PropTypes.number.isRequired,
    fileName: PropTypes.string.isRequired,
    callReasonCode: PropTypes.string.isRequired,
  }).isRequired,
};
