import Alert from 'components/Custom/Alert';
import { useRecoilValue } from 'recoil';
import { processPaymentRight, redirectTo } from 'utilities/userRole';
import PaymentSearchGrid from './PaymentSearchGrid';

function PaymentSearchPage() {
  const hasProcessPaymentRight = useRecoilValue(processPaymentRight);
  if (!hasProcessPaymentRight) redirectTo('/');
  return (
    <Alert childrenId="Payments" customStyle={{ width: '40%', mb: '16px' }}>
      <PaymentSearchGrid />
    </Alert>
  );
}

export default PaymentSearchPage;
